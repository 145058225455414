import { useState } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/system";
import { Drawer } from "@mui/material";

import { BaseCard } from "../../components/BaseCard";
import { BasicInformation } from "./components/BasicInformation";
import { ContactInformation } from "./components/ContactInformation";
import { Password } from "./components/Password";
import { SiteSettings } from "./components/SiteSettings/SiteSettings";
import { ConfirmPhoneSidebar } from "./components/ConfirmPhoneSidebar";
import { PasswordSidebar } from "./components/PasswordSidebar";

import { confirmVerifyProfile } from "./data/actions";

import styles from "./styles/Profile.scss";
import { BaseButton } from "../../components/BaseButton";
import { useNavigate } from "react-router-dom";
import { LayoutWithLeftMenu } from "../../layouts/leftMenu/LayoutWithLeftMenu";
import { useSelector } from "react-redux";
import { selectLocale } from "../../data/selectors";

const StyledDrawer = styled(Drawer)`
  z-index: 9999999999;
`;

const StyledSidebarWrapper = styled("div")`
  padding: 30px 40px;
  width: 334px;
  height: 100%;
`;

export const AppProfile = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const [rightPanelOpened, setRightPanelOpened] = useState(false);
  const [sidebarType, setSidebarType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseSidebar = (): void => {
    setRightPanelOpened(false);
  };

  const onConfirmVerify = (value: string): void => {
    dispatch(confirmVerifyProfile({ type: "phone", confirmation_code: value }));
    handleCloseSidebar();
  };

  const handleOpenSidebar = (type: string): void => {
    setRightPanelOpened(true);
    setSidebarType(type);
  };

  return (
    <LayoutWithLeftMenu title="Personal information">
      <BaseCard padding="24px">
        <BasicInformation />

        <ContactInformation
          className={styles.card}
          onOpenSidebar={(value) => handleOpenSidebar(value)}
          onClose={handleCloseSidebar}
        />

        <Password className={styles.card} onOpenSidebar={handleOpenSidebar} />

        <SiteSettings className={styles.card} />
      </BaseCard>

      <BaseButton
        className={styles.newShipmentBtn}
        onClick={() => navigate(locale !== "en" ? `/${locale}` : "/")}
      >
        New shipment
      </BaseButton>

      <StyledDrawer
        anchor="right"
        open={rightPanelOpened}
        sx={{ width: 334 }}
        onClose={handleCloseSidebar}
      >
        <StyledSidebarWrapper>
          {sidebarType === "password" ? (
            <PasswordSidebar onClose={handleCloseSidebar} />
          ) : (
            <ConfirmPhoneSidebar
              onClose={handleCloseSidebar}
              onConfirm={onConfirmVerify}
            />
          )}
        </StyledSidebarWrapper>
      </StyledDrawer>
    </LayoutWithLeftMenu>
  );
};
