import { BaseButton } from "../BaseButton";
import styles from "./BtnSecondary.scss";

interface BaseButtonProps {
  onClick(): void;
}

// export const BtnSecondary = ({ onClick }: BaseButtonProps) => {
export const BtnSecondary = (props: any) => {
  return (
    <BaseButton className={styles.root} secondaryBtn onClick={props.onClick}>
      Close
    </BaseButton>
  );
};
