import React from "react";
import compact from "lodash.compact";
import { BaseCard } from "../../../../components/BaseCard";

import { useNavigate } from "react-router";
import { Name } from "../../../../components/BaseIcon";
import { getCurrencyIcon } from "../../../../services/getCurrencyIcon";
import { getProviderImage } from "../../../../services/getRateImage";
import { parcelTypes } from "../../../home/data/constants";
import {
  StyledAdditionalInfoWrapper,
  StyledAdditionalText,
  StyledAdditionalTextBlock,
  StyledEditIcon,
  StyledFedExImg,
  StyledParcelInfo,
  StyledPriceSum,
  StyledPriceText,
  StyledPriceWrapper,
  StyledTitle,
} from "./styles/RightPanel.styled";

// type IProps = {
//   totalSum: string
//   img: string
// }

const RightPanel: React.FC<any> = (props) => {
  // const navigate = useNavigate()
  // const goToMainPage = () => navigate(`/?shipment_id=${props.shipmentId}`)

  // console.log("=props.shipment=",props.shipment);

  // const addressFrom = compact([props.shipment?.address_from?.city_name, props.shipment?.address_from?.state, props.shipment?.address_from?.country]).join(', ')
  // const addressTo = compact([props.shipment?.address_to?.city_name, props.shipment?.address_to?.state, props.shipment?.address_to?.country]).join(', ')

  return (
    <BaseCard padding="24px" position="relative">
      <StyledTitle>Your proposal</StyledTitle>

      {/* <StyledEditIcon name={Name.Pencil} onClick={goToMainPage} isButton /> */}

      {/* <StyledParcelInfo>
        From <strong>{addressFrom}</strong> to <strong>{addressTo}</strong>
      </StyledParcelInfo> */}

      <StyledParcelInfo>
        {props.shipment?.parcels?.length}{" "}
        {
          parcelTypes[
            props.shipment?.is_customs_declarable
              ? props.shipment?.is_pallet
                ? 2
                : 1
              : 0
          ].label
        }
        {props.shipment?.parcels?.length > 1 ? "s" : ""}
      </StyledParcelInfo>

      <StyledPriceWrapper>
        <StyledPriceText>Price starting from </StyledPriceText>
        <StyledPriceSum>
          {getCurrencyIcon(props.shipment?.chosen_rate?.currency)}{" "}
          {props.shipment?.chosen_rate?.amount}
        </StyledPriceSum>
      </StyledPriceWrapper>

      <StyledAdditionalInfoWrapper>
        <StyledAdditionalTextBlock>
          <StyledAdditionalText>Selected delivery company</StyledAdditionalText>
          {/* <StyledAdditionalText>
            Arrival within 3 business days
          </StyledAdditionalText> */}
        </StyledAdditionalTextBlock>

        <StyledFedExImg
          src={getProviderImage(
            props.shipment?.chosen_rate?.provider?.image_url
          )}
          alt=""
        />
      </StyledAdditionalInfoWrapper>
    </BaseCard>
  );
};

export default RightPanel;
