import API from "../../../api";
import { getErrorMessage } from "../../../services/getErrorMessage";
import { Notify } from "../../../services/notifications";
import { EXPANDED_SHIPMENT_CARD_LS } from "../data/constants";
import { cookies } from "../../../services/cookies";

export const getCustomItems = async (shipmentId: any) => {
  try {
    const response = await API.get(`/customs/items/${shipmentId}`);

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

export const setCustomsItems = async (
  shipmentID: string,
  customsItems: any,
) => {
  try {
    const res = await API.post(`/shipments/${shipmentID}/customs-items`, {
      items: customsItems,
    });

    if (res.status === 400) {
      throw res.data.error;
    } else if (res.status !== 200) {
      throw new Error("Something went wrong!");
    }

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
    throw new Error("Something went wrong!");
  }
};

export const updateParcelsShipment = async (
  shipmentID: string,
  payload: any,
) => {
  try {
    const res = await API.post(`/shipments/${shipmentID}/parcels`, payload);

    if (res.status === 400) {
      throw res.data.error;
    } else if (res.status !== 200) {
      throw new Error("Something went wrong!");
    }

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
    throw new Error("Something went wrong!");
  }
};

export const createCustomInvoice = async ({ shipmentId, formValues }: any) => {
  try {
    const response = await API.post(
      `/shipments/${shipmentId}/custom-invoice`,
      formValues,
    );

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

export const removeCustomInvoice = async (shipmentId: string) => {
  try {
    const response = await API.delete(
      `/shipments/${shipmentId}/custom-invoice`,
    );

    return response.data.data || true;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

class CheckoutRequests {
  static saveAddressForm = async ({
    shipmentId,
    addressForm,
    skipValidation,
  }: any) => {
    try {
      const response = await API.post(`/shipments/${shipmentId}/addresses`, {
        ...addressForm,
        skip_validation: skipValidation,
      });

      return response;
    } catch (error: any) {
      Notify({ message: getErrorMessage(error), type: "error" });
      // throw new Error('')
    }
  };

  static savePickupDetailsForm = async ({ shipmentId, formValues }: any) => {
    const response = await API.post(
      `/shipments/${shipmentId}/pickup-details`,
      formValues,
    );

    return response;
  };

  static sendServices = async ({ shipmentId, serviceData }: any) => {
    const response = await API.post(`/shipments/${shipmentId}/services`, {
      services: serviceData,
    });

    return response;
  };

  static makeTransaction = async ({ shipment_id }: any) => {
    const state = {
      platform:
        process.env.REACT_APP_IS_MOBILE_APP === "true" ? "mobile" : "browser",
    };

    const body: any = {
      shipment_id,
      payment_gateway: "tranzila",
      state: JSON.stringify(state),
    };

    let headers;

    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
      // console.log(cookies.get('_ga'));
      // console.log(cookies.get('_ga_QKBHYSRCLB'));

      const cookieObject = {
        shipment_id,
        client_id: cookies.get("_ga"),
        session_id: cookies.get("_ga_QKBHYSRCLB"),
      };

      const encodedCookie = btoa(JSON.stringify(cookieObject));
      // console.log(encodedCookie);

      headers = { "Google-Analytics": encodedCookie };
    }

    const response = await API.post("/transactions", body, {
      headers,
    });

    localStorage.removeItem(EXPANDED_SHIPMENT_CARD_LS);

    return response;
  };
}

export default CheckoutRequests;
