import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setRtl, setLocale } from "../../data/actions";
import { selectLocale } from "../../data/selectors";

import styles from "./localization.scss";
import { BaseNewSelect } from "../BaseNewSelect";
import { BaseIcon, Name } from "../BaseIcon";
import {
  getStore,
  setStore,
} from "../../integrationWithVue/handleBrowserStore";

export const languageOptions = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "he",
    // label: 'Hebrew'
    label: "עברית",
  },
  {
    value: "tr",
    label: "Türkçe",
  },
];

export const Localization = ({ className, onCloseMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locale = useSelector(selectLocale);

  const [languageValue, setLanguageValue] = useState({});

  useEffect(() => {
    setLanguageValue(languageOptions.find((item) => item.value === locale));
  }, [locale]);

  useEffect(() => {
    let _locale = getStore("locale-re");

    if (!_locale) {
      _locale = location.pathname.startsWith("/he")
        ? "he"
        : location.pathname.startsWith("/tr")
          ? "tr"
          : "en";
    } else if (!languageOptions.find((item) => item.value === _locale)) {
      _locale = "en";
    } else {
      _locale = location.pathname.startsWith("/he")
        ? "he"
        : location.pathname.startsWith("/tr")
          ? "tr"
          : "en";
    }

    setLanguageValue(languageOptions.find((item) => item.value === _locale));
    // console.log("==", languageValue);
  }, []);

  const onChangeLanguage = (newLocale) => {
    // console.log("=location=", location, newLocale.value);

    if (newLocale.value === languageValue.value) return;

    let pathname = location.pathname;

    if (newLocale.value !== "en") {
      if (pathname.startsWith("/he")) {
        pathname = pathname.replace("/he", "");
      } else if (pathname.startsWith("/tr")) {
        pathname = pathname.replace("/tr", "");
      }
      // console.log("=newLocale.value !== 'en'=", pathname);
      pathname = `/${newLocale.value}` + pathname;
      // console.log("=newLocale.value !== 'en'=", pathname);
    } else {
      if (pathname.startsWith("/he")) {
        pathname = pathname.replace("/he", "");
      } else if (pathname.startsWith("/tr")) {
        pathname = pathname.replace("/tr", "");
      }
    }

    setStore(newLocale.value, "locale-re");

    console.log("=Localization setLocale=", locale);
    dispatch(setRtl(newLocale.value === "he"));
    dispatch(setLocale(newLocale.value));

    navigate(pathname);
    setLanguageValue(newLocale);

    onCloseMenu && onCloseMenu();
  };

  return (
    <div className={`${styles.language} ${className}`}>
      <BaseIcon name={Name.Earth} size={20} />

      <BaseNewSelect
        className={styles.languageSelect}
        options={languageOptions}
        value={languageValue}
        withoutHover
        onChange={(value) => {
          // console.log('=onChange=', value);
          onChangeLanguage(value);
        }}
      />
    </div>
  );
};
