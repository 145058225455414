import { geocodeByAddress } from "react-places-autocomplete";
import compact from "lodash.compact";

export const geocodeByAddressHandler = async (address: any) => {
  // console.log("=geocodeByAddressHandler=", address);

  const fullAddress = compact([
    address.street_1,
    address.city_name,
    address.state,
    address.country,
  ]).join(", ");
  // console.log("=fullAddress=", fullAddress);

  try {
    const result: any = await geocodeByAddress(fullAddress);
    // console.log("=result=", result);

    // console.log(
    //   "=result=",
    //   result[0].geometry,
    //   result[0].geometry.location.lat(),
    //   result[0].geometry.location.lng()
    // );

    const location = result?.[0]?.geometry?.location;

    if (!location) {
      return {
        lat: "",
        lng: "",
      };
    }

    const lat = location.lat();
    const lng = location.lng();

    return {
      lat: lat ? String(lat) : "",
      lng: lng ? String(lng) : "",
    };
  } catch (err) {
    console.error(err);

    return {
      lat: "",
      lng: "",
    };
  }
};
