import * as React from "react";

export const LeftArrow: React.FC = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10 16.667 1.175-1.175-4.65-4.659h10.141V9.167H6.525l4.65-4.659L10 3.333 3.333 10 10 16.667Z"
      fill="#284492"
    />
  </svg>
);
