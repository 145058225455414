import { parcelTypes } from "../../../../data/constants";

export const submitToGoogleAnalyticsOnChoosingCarrier = (
  shipment: any,
  rate: any,
) => {
  const outgoingData = {
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_category: !shipment.is_customs_declarable
            ? "Letters"
            : shipment.is_pallet
              ? "Pallets"
              : "Parcels",
          item_id: `${rate.provider.name}_${rate.service_level.name}`
            .toLocaleLowerCase()
            .replace(/ /g, "_"),
          item_name: rate.service_level.name,
          index: 1,
          item_brand: rate.provider.name,
          item_list_name:
            `Shipping from ${shipment.address_from.city_name} to ${shipment.address_to.city_name}` +
            ", " +
            shipment?.parcels?.length +
            " " +
            parcelTypes[
              shipment?.is_customs_declarable
                ? shipment?.is_pallet
                  ? 2
                  : 1
                : 0
            ].label +
            (shipment?.parcels?.length > 1 ? "s" : "") +
            ", " +
            (
              shipment?.parcels?.reduce((totalWeigh: number, item: any) => {
                totalWeigh += item.weight;
                return totalWeigh;
              }, 0) || 0
            ).toFixed(2) +
            " " +
            (shipment?.unit_of_measurement === "metric" ? "kg" : "lb"),
          item_variant: rate.service_level.name,
          price: rate.amount,
        },
      ],
    },
  };
  // console.log(outgoingData);

  dataLayer.push(outgoingData);
};
