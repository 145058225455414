import * as React from "react";

export const Phone: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      stroke="#284492"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21 16.477v2.71a1.803 1.803 0 0 1-1.23 1.718c-.24.08-.492.11-.744.088a17.933 17.933 0 0 1-7.81-2.773 17.63 17.63 0 0 1-5.43-5.42 17.853 17.853 0 0 1-2.779-7.83A1.803 1.803 0 0 1 4.08 3.153c.23-.101.478-.154.73-.154h2.714a1.812 1.812 0 0 1 1.81 1.554c.115.867.328 1.718.634 2.538a1.803 1.803 0 0 1-.407 1.906l-1.15 1.147a14.466 14.466 0 0 0 5.43 5.42l1.15-1.148a1.811 1.811 0 0 1 1.91-.406c.82.305 1.674.518 2.543.632a1.81 1.81 0 0 1 1.556 1.834Z"
    />
  </svg>
);
