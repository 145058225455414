import { styled } from "@mui/system";

import brandColors from "../../css/brandColors.scss";

const StyledRoot = styled("div")(
  () => `
  margin: 24px 0;
  width: 100%
`,
);

const StyledLine = styled("div")(
  () => `
  width: 100%;
  height: 1px;
  background: ${brandColors.lightestBlue};
`,
);

const BaseDividerLine = () => {
  return (
    <StyledRoot>
      <StyledLine />
    </StyledRoot>
  );
};

export default BaseDividerLine;
