import { useEffect, useState } from "react";

import { BaseButton } from "../../../../../../../components/BaseButton";
import {
  Accordion,
  AccordionCard,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryText,
  StyledAccordionPreviewText,
} from "../../../../AccordionItems/Accordion";
import { getPickupDetailsLabel } from "../PreviewCardLabel";
import { ContactForm } from "../../../../ContactForm/ContactForm";
import { FormProvider, useForm } from "react-hook-form";
import { getDefaultFormValues } from "./services/getDefaultFormValues";
import { validationSchema } from "./services/validation";
import { useDispatch } from "react-redux";
import {
  saveAddressForm,
  savePickupDetailsForm,
  setAddressFromFormErrors,
  updateShipment,
  setHasSkippedValidationToAddressFrom,
} from "../../../../../data/actions";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../../../../../../data/selectors";
import { setFormErrors } from "../../../../../../../services/formErrors/setFormErrors";
import { DevTool } from "@hookform/devtools";
import { BaseCheckbox } from "../../../../../../../components/BaseCheckbox";
import { StyledActionFooter } from "../styles/AccordionItems.styled";
import {
  selectAddressFromFormErrors,
  selectHasSkippedValidationFromAddressFrom,
  selectPostalCodeFromAddressFrom,
} from "../../../../../data/selectors";

import styles from "./PickupDetails.scss";
import formStyles from "../../../Form.scss";
import { getTransformedAddressFormValesToSubmit } from "../../../../../services/getTransformedAddressFormValesToSubmit";
import { ShipmentFormNames } from "../../../../../data/constants";
import { createAddressToBook } from "../../../../../../../api/commonRequests";
import { submitToGoogleAnalyticsOnSubmitStep } from "../../../submitToGoogleAnalytics.FormCheckout";
import { countriesWithStates } from "../../../../../../../constants";
// import nextStepInCheckoutEventInfobip from "../../../../../../../plugins/infobip/nextStepInCheckoutEventInfobip";
// import getShortShipmentIdInfobip from "../../../../../../../plugins/infobip/getShortShipmentIdInfobip";
import { geocodeByAddressHandler } from "../../../../../../../services/geocodeByAddressHandler";

const PickupDetails = (props: any) => {
  const [isSaveAddress, setIsSaveAddress] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: getDefaultFormValues(props.shipment),
    mode: "onChange",
    resolver: validationSchema,
  });

  // console.log("=methods", methods.getValues("address_from"));

  const disabledNextBtn = (): boolean => {
    if (
      methods.watch("address_from.first_name") &&
      methods.watch("address_from.last_name") &&
      methods.getValues("address_from.city_name") &&
      methods.getValues("address_from.street_1") &&
      methods.watch("address_from.postal_code") &&
      // methods.watch('address_from.street_2') &&
      methods.watch("address_from.phone") &&
      methods.getValues("address_from.email") &&
      (methods.watch("address_from.is_company")
        ? methods.watch("address_from.company_name") &&
          methods.watch("address_from.company_ein")
        : methods.watch("address_from.passport"))
    ) {
      // console.log("=staep 2=", countriesWithStates.includes(methods.getValues('address_from.country_code')));
      if (
        countriesWithStates.includes(
          methods.getValues("address_from.country_code")
        )
      ) {
        return methods.getValues("address_from.state");
      }

      return true;
    }

    return false;
  };

  const dispatch = useDispatch();
  const formErrors = useSelector(selectAddressFromFormErrors);
  const loading = useSelector(selectIsLoading);
  const postalCodeValidation = useSelector(
    selectHasSkippedValidationFromAddressFrom
  );
  const postalCode = useSelector(selectPostalCodeFromAddressFrom);
  const [
    showPostalCodeValidationCheckbox,
    setShowPostalCodeValidationCheckbox,
  ] = useState(postalCodeValidation);

  const postalCodeError = formErrors?.find(
    (el: any) => el.fieldName === "address_from.postal_code"
  );

  useEffect(() => {
    setFormErrors({
      formErrors,
      methods,
      formName: ShipmentFormNames.AddressFrom,
    });

    if (postalCodeError) {
      setShowPostalCodeValidationCheckbox(true);
    }
  }, [formErrors]);

  useEffect(() => {
    postalCode !== methods.watch("address_from.postal_code") &&
      dispatch(setHasSkippedValidationToAddressFrom(false));
  }, [methods.watch("address_from.postal_code")]);

  useEffect(() => {
    if (postalCodeValidation) {
      methods.clearErrors("address_from.postal_code");
    } else {
      setFormErrors({
        formErrors,
        methods,
        formName: ShipmentFormNames.AddressFrom,
      });
    }
  }, [postalCodeValidation]);

  const saveAddressFormFn = () =>
    new Promise((resolve, reject) => {
      setShowPostalCodeValidationCheckbox(postalCodeValidation);

      dispatch(
        saveAddressForm({
          shipmentId: props.shipmentId,
          addressForm: getTransformedAddressFormValesToSubmit({
            methods,
            formName: ShipmentFormNames.AddressFrom,
          }),
          skipValidation: postalCodeValidation,
          setFormErrorAction: setAddressFromFormErrors,
          resolve,
          reject,
        })
      );
    });

  const savePickupDetailsFormFn = () =>
    new Promise((resolve, reject) => {
      dispatch(
        savePickupDetailsForm({
          shipmentId: props.shipmentId,
          formValues: {
            instructions: methods.watch("address_from.instructions"),
          },
          resolve,
          reject,
        })
      );
    });

  const onSubmit = async (formValues: any) => {
    // console.log("=onSubmit=", formValues);

    const coordinates: any = await geocodeByAddressHandler(
      formValues["address_from"]
    );
    // console.log("=coordinates=", coordinates);

    formValues["address_from"].lat = coordinates.lat;
    formValues["address_from"].lng = coordinates.lng;

    // console.log("=formValues=", formValues);

    let requests = [saveAddressFormFn()];

    isSaveAddress &&
      requests.push(createAddressToBook(formValues["address_from"]));

    if (methods.watch("address_from.instructions")) {
      requests.push(savePickupDetailsFormFn());
    }

    Promise.all(requests).then(() => {
      dispatch(
        updateShipment({
          ...formValues,
          // billing_address: methods.watch('address_from')
        })
      );

      setIsSaveAddress(false);

      process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
        submitToGoogleAnalyticsOnSubmitStep({
          shipment: props.shipment,
          isCompany: formValues.address_from.is_company,
          event: "begin_checkout",
          var_step: "Pick up address",
        });

      props.onNextStep();
      // process.env.REACT_APP_INFOBIP &&
      //   nextStepInCheckoutEventInfobip(
      //     "pickup_address",
      //     2,
      //     getShortShipmentIdInfobip(props.shipment.object_id)
      //   );
    });
  };

  const handleSelectAddressBook = (address: any) => {
    // console.log("=handleSelectAddressBook=", address);

    methods.setValue("address_from.first_name", address.first_name);
    methods.setValue("address_from.last_name", address.last_name);
    methods.setValue("address_from.phone", address.phone);
    methods.setValue("address_from.email", address.email);
    methods.setValue("address_from.passport", address.passport);
    methods.setValue("address_from.company_name", address.company_name);
    methods.setValue("address_from.company_ein", address.company_ein);
    methods.setValue("address_from.state", address.state);
    methods.setValue("address_from.city_name", address.city_name);
    methods.setValue("address_from.street_1", address.street_1);
    methods.setValue("address_from.street_2", address.street_2);
    methods.setValue("address_from.postal_code", address.postal_code);
    methods.setValue("address_from.lat", address.lat);
    methods.setValue("address_from.lng", address.lng);

    methods.setValue(
      "address_from.is_company",
      !!methods.getValues("address_from.company_name")
    );
  };

  const formIsActive = props.expanded === props.name;

  // console.log("=methods.watch()=", methods.watch()['address_from']);

  return (
    <AccordionCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={formIsActive}
            onChange={props.onChangeAccordion(props.name)}
            disabled={props.disabled}
          >
            <AccordionSummary disabled={props.disabled} expanded={formIsActive}>
              <AccordionSummaryText>
                Pick up address
                {methods.watch("address_from.street_1") ||
                methods.watch("address_from.city_name") ||
                methods.watch("address_from.state") ||
                methods.watch("address_from.postal_code") ||
                methods.watch("address_from.country")
                  ? ":"
                  : ""}
                <StyledAccordionPreviewText expanded={formIsActive}>
                  {getPickupDetailsLabel(methods.watch("address_from"))}
                </StyledAccordionPreviewText>
              </AccordionSummaryText>
            </AccordionSummary>

            <AccordionDetails>
              <ContactForm
                formValues={methods.watch()}
                methods={methods}
                formIsActive={formIsActive}
                // prefixToSearchString={props.shipment.address_from?.city_name + (props.shipment.address_from?.state ? `, ${props.shipment.address_from.state}` : '')}
                change={(fieldName: any, value: any) => {
                  // console.log("change==", fieldName, value);

                  methods.setValue(fieldName, value);
                }}
                isCompanyChecked={methods.watch("address_from.is_company")}
                formName="address_from"
                isAddressFromType
                withEmail
                countryLocked
                onSelectAddressBook={handleSelectAddressBook}
              />

              <StyledActionFooter>
                <div>
                  <BaseCheckbox
                    style={{ marginRight: "0" }}
                    checked={isSaveAddress}
                    onChange={(e: any) => {
                      setIsSaveAddress(e.target.checked);
                    }}
                  />

                  <span>
                    Save this address in the{" "}
                    <a
                      style={{
                        color: "#284492",
                        fontWeight: "600",
                        textDecoration: "none",
                      }}
                      href="/address-book"
                    >
                      address book
                    </a>
                  </span>
                </div>

                {showPostalCodeValidationCheckbox && (
                  <BaseCheckbox
                    className={formStyles.formCheckboxWrapper}
                    checked={postalCodeValidation}
                    onChange={(e: any) =>
                      dispatch(
                        setHasSkippedValidationToAddressFrom(
                          !postalCodeValidation
                        )
                      )
                    }
                    label="The postal code of this address cannot be validated, but I know it is correct. Please use this address to call the courier."
                  />
                )}

                <BaseButton
                  className={styles.submitBtn}
                  loading={loading}
                  type="submit"
                  // disabled={!disabledNextBtn()}
                >
                  NEXT STEP
                </BaseButton>
              </StyledActionFooter>
            </AccordionDetails>
          </Accordion>

          {/* <DevTool control={methods.control} /> */}
        </form>
      </FormProvider>
    </AccordionCard>
  );
};

export default PickupDetails;
