import { cookies } from "../services/cookies";

// console.log("=cordova in globalThis=", process.env.REACT_APP_IS_MOBILE_APP === 'true');

export const setStore = (value: string, key = "v2") => {
  // console.log("=setStore key=", key, value);
  // console.log("=setStore=", value);

  // "cordova" in globalThis
  process.env.REACT_APP_IS_MOBILE_APP === "true"
    ? localStorage.setItem(key, value)
    : cookies.set(key, value, {
        path: "/",
      });
};

export const getStore = (key = "v2") => {
  // console.log("=getStore key=", key, localStorage.getItem(key), cookies.get(key));
  // console.log("=getStore localStorage=", localStorage.getItem(key));
  // console.log("=getStore cookies=", cookies.get(key));

  // return "cordova" in globalThis
  return process.env.REACT_APP_IS_MOBILE_APP === "true"
    ? localStorage.getItem(key)
    : cookies.get(key);
};

export const removeStore = (key = "v2") => {
  // console.log("=removeStore key=", key, localStorage.getItem(key), cookies.get(key));
  // console.log("=removeStore localStorage=", localStorage.getItem(key));
  // console.log("=removeStore cookies=", cookies.get(key));

  // "cordova" in globalThis
  process.env.REACT_APP_IS_MOBILE_APP === "true"
    ? localStorage.removeItem(key)
    : cookies.remove(key, {
        path: "/",
      });
};
