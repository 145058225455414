import MetricSystemBtnsStyles from "./MetricSystemBtns.scss";
// import { BaseIcon, Name } from '../../../../../components/BaseIcon';

interface MetricSystemBtnProps extends MetricSystemBtnsProps {
  label: string;
  value: boolean;
}

const MetricSystemBtn = ({
  label,
  value,
  selected,
  onClick,
}: MetricSystemBtnProps) => {
  let classes = MetricSystemBtnsStyles.MetricSystemBtnsBtn;

  selected === value &&
    (classes += ` ${MetricSystemBtnsStyles.MetricSystemBtnsBtnActive}`);

  return (
    <button className={classes} type="button" onClick={() => onClick(value)}>
      {/* {
        selected === value &&
          <BaseIcon
            className={MetricSystemBtnsStyles.MetricSystemBtnsBtnIcon}
            name={Name.CheckMarkElongated} size={18}
          />
      } */}

      <span className={MetricSystemBtnsStyles.MetricSystemBtnsBtnLabel}>
        {label}
      </span>
    </button>
  );
};

interface MetricSystemBtnsProps {
  selected: boolean;
  onClick(value: boolean): void;
}

export const MetricSystemBtns = ({
  selected,
  onClick,
}: MetricSystemBtnsProps) => {
  return (
    <div className={MetricSystemBtnsStyles.MetricSystemBtns}>
      <MetricSystemBtn
        label="מטרי"
        value={false}
        selected={selected}
        onClick={(value) => selected !== false && onClick(value)}
      />

      <div className={MetricSystemBtnsStyles.MetricSystemBtnsSeparator}></div>

      <MetricSystemBtn
        label="אינץ׳"
        value={true}
        selected={selected}
        onClick={(value) => selected !== true && onClick(value)}
      />
    </div>
  );
};
