import * as React from "react";

export const Instagram: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 2.25C4.60051 2.25 2.25 4.60051 2.25 7.5L2.25 16.5C2.25 19.3995 4.6005 21.75 7.5 21.75L16.5 21.75C19.3995 21.75 21.75 19.3995 21.75 16.5L21.75 7.5C21.75 4.6005 19.3995 2.25 16.5 2.25L7.5 2.25ZM7.5 3.75L16.5 3.75C18.5711 3.75 20.25 5.42893 20.25 7.5L20.25 16.5C20.25 18.5711 18.5711 20.25 16.5 20.25L7.5 20.25C5.42893 20.25 3.75 18.5711 3.75 16.5L3.75 7.5C3.75 5.42893 5.42893 3.75 7.5 3.75ZM17.0625 5.9375C16.5102 5.9375 16.0625 6.38522 16.0625 6.9375C16.0625 7.48978 16.5102 7.9375 17.0625 7.9375C17.6148 7.9375 18.0625 7.48978 18.0625 6.9375C18.0625 6.38522 17.6148 5.9375 17.0625 5.9375ZM12 7.875C9.72179 7.875 7.875 9.72179 7.875 12C7.875 14.2782 9.72179 16.125 12 16.125C14.2782 16.125 16.125 14.2782 16.125 12C16.125 9.72179 14.2782 7.875 12 7.875ZM12 9.375C13.4498 9.375 14.625 10.5502 14.625 12C14.625 13.4498 13.4498 14.625 12 14.625C10.5502 14.625 9.375 13.4498 9.375 12C9.375 10.5502 10.5502 9.375 12 9.375Z"
      fill="#7f90b1"
    />
  </svg>
);
