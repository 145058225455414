import { addToStringViaCharacter } from "../../../../services/addToStringViaCharacter";

export const submitToGoogleAnalyticsGetQuote = (
  formData: any,
  isDomesticShipping: boolean,
) => {
  const generateAddress = (location: any) => {
    let addressLabel = addToStringViaCharacter("", location.city_name);
    addressLabel = addToStringViaCharacter(addressLabel, location.state);
    addressLabel = addToStringViaCharacter(addressLabel, location.country);

    return addressLabel;
  };

  const outgoingData = {
    event: "form_submitted",
    ship_type: isDomesticShipping ? "Domestic" : "International",
    box_type: formData.parcelType === "package" ? "Parcels" : "Letters",
    box_count: formData.packages.length,
    sender_country: isDomesticShipping ? formData.locationFrom.country : "",
    ship_from: generateAddress(formData.locationFrom),
    ship_to: generateAddress(formData.locationTo),
    measure: formData.unit_of_measurement ? "Inch" : "Metric",
    item_category: !formData.is_customs_declarable
      ? "Letters"
      : formData.is_pallet
        ? "Pallets"
        : "Parcels",
  };
  // console.log(outgoingData);

  dataLayer.push(outgoingData);
};
