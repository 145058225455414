import { styled } from "@mui/system";

type StyledCardTitleProps = {
  marginBottom?: string | undefined;
};

export const StyledCardTitle = styled("div")<StyledCardTitleProps>(
  ({ marginBottom = "24px" }) => `
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #284492;
    margin-bottom: ${marginBottom};
  `,
);

export const StyledPriceListWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledPriceLabel = styled("div")`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #a8b6d2;
`;

export const StyledPrice = styled("div")`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #284492;
`;

export const StyledDividerLine = styled("div")`
  border-bottom: 1px solid #e5e9f3;
  width: 100%;
  padding-top: 8px;
`;

export const StyledTotalPriceWrapper = styled("div")`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #284492;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
`;

export const StyledSwitchRow: any = styled("div")(
  ({ checked }: any) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${checked ? "#284492" : "#A8B6D2"};
`,
);

export const StyledSwitchTitleBlock = styled("div")`
  display: flex;
  align-items: center;
`;

export const StyledSwitchTitle = styled("div")`
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

export const StyledSwitchBlock = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const StyledSwitchPrice = styled("div")``;
