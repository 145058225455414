import { styled } from "@mui/system";

import { BaseIcon } from "../../../../BaseIcon";

import brandColors from "../../../../../css/brandColors.scss";

const headerHeight = "72px";

type StyledMobileMenuProps = {
  isOpen?: boolean;
};

export const StyledMobileMenu = styled("div")<StyledMobileMenuProps>(
  ({ isOpen }) => `
    position: absolute;
    bottom: 0;
    top: ${headerHeight};
    width: 100%;
    min-height: calc(100vh);
    background: ${brandColors.whiteColor};
    transition: all 0.5s ease-in;
    z-index: 11111;
    opacity: ${isOpen ? "1" : "0"};
    visibility: hidden;
    display: ${isOpen ? "block" : "none"};
    left: ${isOpen ? "0" : "auto"};
    visibility: ${isOpen ? "visible" : "hidden"};
    overflow-y: auto;
`,
);

export const StyledAvatarWrapper = styled("div")(
  () => `
 background: #F7F8FC;
 padding-top: 16px;
 padding-bottom: 16px;
`,
);

type AvatarProps = {
  isAuth: boolean;
};

export const StyledAvatar = styled("div")<AvatarProps>(
  ({ isAuth }) => `
 background: ${isAuth ? "none" : brandColors.mainBlue};
 border-radius: 50%;
 width: 40px;
 height: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-shrink: 0;
`,
);

export const StyledAvatarIcon = styled("img")<AvatarProps>(
  ({ isAuth }) => `
 transform: rotate(${isAuth ? "0" : "-45deg"}) scale(-1, 1);
 width: 42px;
`,
);

export const StyledNameAndProfile = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
`;

export const StyledAvatarText = styled("div")(
  () => `
 font-weight: 600;
 font-size: 14px;
 line-height: 19px;
 color: ${brandColors.mainBlue};
`,
);

export const StyledProfileBtn = styled("div")`
  font-weight: 400;
  font-size: 12px;
  color: #4ab6bd;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledMenuList = styled("ul")(
  () => `
`,
);

export const StyledMenuListLine = styled("div")(
  () => `
  margin-top: 13px;
  border-bottom: 1px solid ${brandColors.lightestBlue};
`,
);

export const StyledMenuItem = styled("li")`
  color: ${brandColors.mainBlue};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledMenuItemIcon = styled(BaseIcon)(
  () => `
  // margin-right: 24px;
`,
);

export const StyledMenuItemText = styled("div")(
  () => `
 font-size: 14px;
 line-height: 130%;
 color: ${brandColors.mainBlue};
`,
);

export const StyledFooterLink = styled("div")(
  () => `
  font-size: 14px;
  line-height: 150%;
  color: ${brandColors.lightBlueColor};
  margin-top: 20px;
`,
);
