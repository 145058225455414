import { useDispatch, useSelector } from "react-redux";
import { selectRtl } from "../../data/selectors";
import commonStyles from "../../css/common.scss";
import styles from "./MenuLayoutWithLeftMenu.scss";
import { selectFullName } from "../../data/selectors";
import { signOut } from "../../scenes/auth/data/actions";
import { goToVue } from "../../integrationWithVue/goToVue";

interface MenuLayoutWithLeftMenuProps {
  className: string;
}

export const MenuLayoutWithLeftMenu = ({
  className,
}: MenuLayoutWithLeftMenuProps) => {
  const dispatch = useDispatch();
  const fullName = useSelector(selectFullName);
  const rtl = useSelector(selectRtl);

  const itemsMenu = [
    {
      label: rtl ? "פּרוֹפִיל" : "Profile",
      to: "/profile",
    },
    {
      label: rtl ? "המשלוחים שלי" : "My shipments",
      to: "/shipments",
    },
    {
      label: rtl ? "פנקס הכתובות" : "Address book",
      to: "/address-book",
    },
    {
      label: rtl ? "חיוב" : "Billing",
      to: "/billing/payment-methods",
    },
  ];

  const handleSingOut = () => {
    dispatch(signOut());
  };

  return (
    <div
      className={`${commonStyles.commonCardThinBottom} ${styles.MenuLayoutWithLeftMenu} ${className}`}
    >
      <div className={styles.MenuLayoutWithLeftMenuFullName}>
        {`${fullName} 👋`}
      </div>

      <nav className={styles.MenuLayoutWithLeftMenuNav}>
        {/* {itemsMenu.map((item) => (
          <a className={styles.MenuLayoutWithLeftMenuNavLink} key={item.label} href={item.to}>
            {item.label}
          </a>
        ))} */}

        <a className={styles.MenuLayoutWithLeftMenuNavLink} href="/profile">
          {/* {rtl ? 'פּרוֹפִיל' : 'Profile'} */}
          Profile
        </a>

        <div
          className={styles.MenuLayoutWithLeftMenuNavLink}
          onClick={(event) => goToVue("/my-shipments", event)}
        >
          {/* {rtl ? 'המשלוחים שלי' : 'My shipments'} */}
          My shipments
        </div>

        <a
          className={styles.MenuLayoutWithLeftMenuNavLink}
          href="/address-book"
        >
          {/* {rtl ? 'פנקס הכתובות' : 'Address book'} */}
          Address book
        </a>

        <div
          className={styles.MenuLayoutWithLeftMenuNavLink}
          onClick={(event) => goToVue("/billing/payment-methods", event)}
        >
          Billing
        </div>

        <div
          className={`${styles.MenuLayoutWithLeftMenuNavLink} ${styles.MenuLayoutWithLeftMenuNavSingOut}`}
          onClick={handleSingOut}
        >
          {/* {rtl ? 'התנתק' : 'Sign out'} */}
          Sign out
        </div>
      </nav>
    </div>
  );
};
