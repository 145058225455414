import { AddNewAddressBtn } from "./AddNewAddressBtn.AppAddressBook";
import styles from "./EmptyListAddressBook.scss";

export const EmptyListAddressBook = () => {
  return (
    <div className={styles.EmptyListAddressBook}>
      <div className={styles.EmptyListAddressBookText}>
        You don't have any saved addresses yet.
      </div>

      <AddNewAddressBtn />
    </div>
  );
};
