import { useState, useEffect } from "react";
import {
  isToday,
  formatISO,
  parseISO,
  getHours,
  format,
  isAfter,
  addMilliseconds,
} from "date-fns";
import {
  utcToZonedTime,
  format as formatTZ,
  zonedTimeToUtc,
} from "date-fns-tz";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RadioGroup } from "@mui/material";

import { enUS } from "date-fns/locale";

import {
  Accordion,
  AccordionCard,
  AccordionDetails,
  AccordionSummary,
  StyledAccordionPreviewText,
} from "../../../../AccordionItems/Accordion";
import { BaseButton } from "../../../../../../../components/BaseButton";
import { getPickupDateLabel } from "../../AccordionItems/PreviewCardLabel";

import styles from "./styles/PickupDateCard.scss";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getDefaultFormValues } from "./services/getDefaultFormValues";
import {
  PickupDateContent,
  StyledBaseRadio,
  StyledCalendar,
  StyledRightColumn,
} from "./styles/PickupDateCard.styled";
import { getTimeOption } from "../../../../../services/getTimeOption";
import { useDispatch } from "react-redux";
import { savePickupDetailsForm } from "../../../../../data/actions";
import { getShipment } from "../../../../../../../data/actions";
import { useSelector } from "react-redux";
import { selectShipmentCalendar } from "../../../../../data/selectors";
import { selectIsLoading } from "../../../../../../../data/selectors";
import { DevTool } from "@hookform/devtools";
import { ShipmentAPI } from "../../../../../../../api";
import { updateShipment } from "../../../../../data/actions";
import { submitToGoogleAnalyticsOnSubmitStep } from "../../../submitToGoogleAnalytics.FormCheckout";
// import nextStepInCheckoutEventInfobip from "../../../../../../../plugins/infobip/nextStepInCheckoutEventInfobip";
// import getShortShipmentIdInfobip from "../../../../../../../plugins/infobip/getShortShipmentIdInfobip";

let isShipmentUpdated = false;
const monthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const PickupDateCard = (props: any) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const calendar = useSelector(selectShipmentCalendar);
  const [isMessagePickupToday, setIsMessagePickupToday] = useState(false);
  const [dateSlot, setTimeSlots] = useState({
    date: null,
    timeslots: [],
  });

  const methods = useForm({
    defaultValues: getDefaultFormValues(props.shipment?.pickup_details),
  });

  const cutOffTimeToday =
    props.shipment?.pickup_details?.calendar?.[0]?.cut_off_time?.slice(0, 5);
  // console.log("=pickup_details=", methods.getValues('pickup_details.ready_time'),
  // new Date(methods.watch('pickup_details.ready_time')),
  // parseISO(methods.watch('pickup_details.ready_time')),
  // );
  // console.log("=cutOffTimeToday=", cutOffTimeToday, isToday(new Date(methods.watch('pickup_details.ready_time'))));

  useEffect(() => {
    if (formIsActive) {
      if (!isShipmentUpdated) {
        isShipmentUpdated = true;
        dispatch(getShipment(props?.shipmentId));
      }
    } else {
      isShipmentUpdated = false;
    }
  }, [props.expanded]);

  useEffect(() => {
    const subscription = methods.watch((values: any, { name, type }: any) => {
      if (name === "time") {
        updateDateWithTime(values.time);
      }
    });

    return () => subscription.unsubscribe();
  }, [methods.watch]);

  useEffect(() => {
    const dateSlot = calendar?.find(
      (el: any) =>
        format(new Date(el.date), "dd.MM.yyyy") ===
        format(
          new Date(methods.watch("pickup_details.ready_time")),
          "dd.MM.yyyy"
        )
    );

    setTimeSlots(
      dateSlot || {
        date: null,
        timeslots: [],
      }
    );

    cutOffTimeToday &&
      setIsMessagePickupToday(
        isToday(new Date(methods.watch("pickup_details.ready_time")))
      );
  }, [methods.watch("pickup_details.ready_time")]);

  const handleChangeTime = (e: any, onChange: any): void => {
    const time = getTimeOption(dateSlot.timeslots, e.target.value);

    onChange(time);
  };

  const updateTime = ({
    fieldName,
    time,
  }: {
    fieldName: string;
    time: any;
  }) => {
    const hours = time?.[fieldName].split(":")[0];
    const minutes = time?.[fieldName].split(":")[1];

    const updatedValue = new Date(
      new Date(
        new Date(
          new Date(methods.watch("pickup_details.ready_time")).setHours(hours)
        ).setMinutes(minutes)
      ).setSeconds(0)
    );

    return updatedValue;
  };

  const updateDateWithTime = (time: any): void => {
    methods.setValue(
      "pickup_details.ready_time",
      updateTime({ fieldName: "ready_time", time })
    );
    methods.setValue(
      "pickup_details.close_time",
      updateTime({ fieldName: "close_time", time })
    );
  };

  const handleChangeDate = (date: Date, onChange: any): void => {
    // console.log("=handleChangeDate=", date, methods.getValues());
    // console.log("=handleChangeDate 2=", date?.getFullYear(), date?.getMonth() + 1, date?.getDate(), props.shipment?.pickup_details?.calendar);

    if (!date) return;

    const addZero = (number: number) => {
      return number > 9 ? number : `0${number}`;
    };

    const _date = {
      timezone_offset:
        props.shipment?.pickup_details?.calendar?.[0]?.timezone_offset ||
        "00:00",
      date: `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`,
    };

    cutOffTimeToday && innerIsToday(_date) && setIsMessagePickupToday(true);

    const time = methods.watch("time");

    const hours = time?.ready_time.split(":")[0];

    const result = new Date(new Date(date).setHours(hours || 0));

    onChange(result);
  };

  // const dateNow = Date.now();
  // const currentHour: number = getHours(dateNow)

  const shouldDisableDate = (value: number | Date): boolean => {
    const availableDates = calendar?.find(
      (el: any) =>
        format(new Date(el.date), "dd.MM.yyyy") ===
        format(new Date(value), "dd.MM.yyyy")
    );

    return !availableDates;

    // const getProviderValidation = (value: number | Date): boolean => {
    //   switch (providerId) {
    //     case ProviderId.DHL:
    //       return (
    //         (props.locationFromCountryCode === 'IL'
    //           ? isFriday(value) || isSaturday(value)
    //           : isSaturday(value) || isSunday(value)) ||
    //         value > addDays(Date.now(), 9)
    //       )
    //     case ProviderId.FEDEX:
    //       return value > addDays(Date.now(), 2)
    //     default:
    //       return false
    //   }
    // }

    // const isValid: boolean = !!(
    //   (isPast(value) && !isToday(value)) ||
    //   getProviderValidation(value)
    // )

    // return isValid
  };

  const isNexStepBtnDisabled = () => {
    // console.log("=isNexStepBtnDisabled=", methods.getValues('pickup_details.close_time'));

    return !methods.getValues("pickup_details.close_time");
  };
  // const isNexStepBtnDisabled: boolean =
  //   (isToday(methods.watch('pickup_details.ready_time'))
  //     ? methods.watch('time.ready_time')?.slice(0, 2) <= currentHour
  //     : false) || !methods.watch('time')

  const savePickupDetailsFormFn = () =>
    new Promise((resolve, reject) => {
      const form = methods.getValues();
      const readyTime = form.pickup_details.ready_time;

      const dateReadyTime = format(new Date(readyTime), "yyyy-MM-dd");
      const selectedDay = calendar.find((dayIteration: any) => {
        return dayIteration.date === dateReadyTime;
      });

      const selectedTimeslot = selectedDay.timeslots.find(
        (timeIteration: any) => {
          return (
            timeIteration.ready_time === format(new Date(readyTime), "HH:mm:ss")
          );
        }
      );

      const closeTime = formatISO(
        new Date(`${dateReadyTime} ${selectedTimeslot.close_time}`)
      );

      dispatch(
        savePickupDetailsForm({
          shipmentId: props.shipmentId,
          formValues: {
            ready_time: formatISO(
              new Date(methods.watch("pickup_details.ready_time"))
            ),
            close_time: closeTime,
          },
          resolve,
          reject,
        })
      );
    });

  const onSubmit = async (formValues: any): Promise<void> => {
    await savePickupDetailsFormFn();

    const shipmentAdditionalPayments =
      await ShipmentAPI.getShipmentAdditionalPayments(props.shipment.object_id);

    dispatch(
      updateShipment({
        additional_payments: shipmentAdditionalPayments,
        ...formValues,
      })
    );

    process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
      submitToGoogleAnalyticsOnSubmitStep({
        shipment: props.shipment,
        event: "begin_checkout",
        var_step: "Pick up date and time",
      });

    props.onNextStep();
    // process.env.REACT_APP_INFOBIP &&
    //   nextStepInCheckoutEventInfobip(
    //     "pickup_date",
    //     6,
    //     getShortShipmentIdInfobip(props.shipment.object_id)
    //   );
  };

  const innerIsToday = (dateSlot: any) => {
    // console.log("=innerIsToday=", dateSlot, dateSlot.timezone_offset, dateSlot.date);
    // const timezoneOffsetArray = dateSlot.timezone_offset.split(":")
    // const _datePickUp = new Date( new Date().getTime() + timezoneOffsetArray[0] * 3_600 * 1_000 + timezoneOffsetArray[1] * 3_600 * 24 * 1_000)
    const _datePickUp = new Date();
    const _dateSlot = new Date(
      `${dateSlot.date}T${localTimeOfAddressFrom}${props.shipment.address_from.timezone_offset}`
    );
    // console.log("=_datePickUp, _dateSlot=", _datePickUp, _dateSlot);
    // console.log("===", format(_datePickUp, 'yyyy-MM-dd'), format(_dateSlot, 'yyyy-MM-dd'));

    return (
      format(_datePickUp, "yyyy-MM-dd") === format(_dateSlot, "yyyy-MM-dd")
    );
  };

  const shouldDisableTimeOption = (time: any, dateSlot: any): boolean => {
    // console.log("=shouldDisableTimeOption [time, dateSlot, localTimeOfAddressFrom, localTimeFromWhereToPickup]=", time, dateSlot, localTimeOfAddressFrom, localTimeFromWhereToPickup);

    if (innerIsToday(dateSlot) && dateSlot.cut_off_time) {
      const _localDateAndTimeOfAddressFrom = Date.parse(
        `${dateSlot.date}T${localTimeOfAddressFrom}${props.shipment.address_from.timezone_offset}`
      );
      const _cut_off_time = Date.parse(
        `${dateSlot.date}T${dateSlot.cut_off_time}${props.shipment.address_from.timezone_offset}`
      );

      // console.log("=_localDateAndTimeOfAddressFrom >= _cut_off_time=", _localDateAndTimeOfAddressFrom >= _cut_off_time, `${dateSlot.date}T${localTimeOfAddressFrom}${props.shipment.address_from.timezone_offset}`, `${dateSlot.date}T${dateSlot.cut_off_time}${props.shipment.address_from.timezone_offset}`);

      if (_localDateAndTimeOfAddressFrom >= _cut_off_time) return true;
    }

    const dateParse = Date.parse(
      `${dateSlot.date}T${time}${props.shipment.address_from.timezone_offset}`
    );
    const dateNow = Date.now() + dateSlot.ready_time_gap_minutes * 60 * 1000;

    // console.log("=dateNow >= dateParse=", dateNow >= dateParse, `${dateSlot.date}T${time}${props.shipment.address_from.timezone_offset}`);
    return dateNow >= dateParse;

    // === Тест с учётом летнего и зимнего времени СТАРТ ===
    // const timeZone = props.shipment.address_from.timezone_offset
    // // const timeZone = 'Europe/Paris'
    // // const timeZone = '+01:00'

    // const pattern = 'yyyy-MM-dd HH:mm (z)'
    // let userDate
    // let pickupDate

    // userDate = utcToZonedTime(new Date(), timeZone)
    // pickupDate = formatTZ(new Date(`${format(new Date(methods.watch('pickup_details.ready_time')), 'yyyy-MM-dd')}T${time}`), pattern, { timeZone })

    // pickupDate = new Date(pickupDate)

    // const result = isAfter(userDate, pickupDate);

    // return result
    // === Тест с учётом летнего и зимнего времени КОНЕЦ ===

    // === Первоначально от Михи СТАРТ ===
    // const date = methods.watch('pickup_details.ready_time')

    // return isToday(date) ? time.slice(0, 2) <= currentHour : false
    // === Первоначально от Михи КОНЕЦ ===
  };

  const getTimeSlotLabel = (time: any): string => {
    // console.log("=getTimeSlotLabel=", time);

    return `${time.ready_time.slice(0, 5)} - ${time.close_time.slice(0, 5)}`;
  };

  let localTimeFromWhereToPickup: any = "";
  let localTimeOfAddressFrom: any = "";

  if (props.shipment?.address_from?.timezone_offset) {
    const addressFromTimezoneOffsetSplit =
      props.shipment.address_from?.timezone_offset.split(":");

    localTimeFromWhereToPickup = new Date(
      addMilliseconds(
        new Date(),
        +addressFromTimezoneOffsetSplit[0] * 60 * 60 * 1_000 +
          +addressFromTimezoneOffsetSplit[1] * 60 * 1_000
      )
    );
    // console.log("=localTimeFromWhereToPickup=", localTimeFromWhereToPickup);

    let localMinutesOfAddressFrom: string = String(
      localTimeFromWhereToPickup.getUTCMinutes()
    );
    localMinutesOfAddressFrom.length === 1 &&
      (localMinutesOfAddressFrom = `0${localMinutesOfAddressFrom}`);

    let localHoursOfAddressFrom: string = String(
      localTimeFromWhereToPickup.getUTCHours()
    );
    localHoursOfAddressFrom.length === 1 &&
      (localHoursOfAddressFrom = `0${localHoursOfAddressFrom}`);

    localTimeOfAddressFrom = `${localHoursOfAddressFrom}:${localMinutesOfAddressFrom}`;
  }

  const formIsActive = props.expanded === props.name;

  return (
    <AccordionCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={formIsActive}
            onChange={props.onChangeAccordion(props.name)}
            disabled={props.disabled}
          >
            <AccordionSummary disabled={props.disabled} expanded={formIsActive}>
              <span>
                Pick up date & time
                {methods.watch("pickup_details.ready_time") ||
                methods.watch("pickup_details.time")
                  ? ":"
                  : ""}
                {!isNexStepBtnDisabled() && (
                  <StyledAccordionPreviewText expanded={formIsActive}>
                    {getPickupDateLabel(
                      methods.watch("pickup_details.ready_time")
                    )}
                    {" - " +
                      format(
                        new Date(methods.watch("pickup_details.close_time")),
                        "HH:mm"
                      )}
                  </StyledAccordionPreviewText>
                )}
              </span>
            </AccordionSummary>

            <AccordionDetails>
              <PickupDateContent>
                <Controller
                  control={methods.control}
                  name="pickup_details.ready_time"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enUS}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <StyledCalendar
                          date={value ? new Date(value) : value}
                          onChange={(_value: any) => {
                            // console.log("==", _value, methods, methods.getValues());

                            handleChangeDate(_value, onChange);
                          }}
                          shouldDisableDate={shouldDisableDate}
                        />

                        {localTimeFromWhereToPickup && (
                          <div
                            className={
                              styles["local-time-from-where-to-pick-up"]
                            }
                          >
                            Current time at pick up location:&ensp;
                            {
                              monthsShort[
                                localTimeFromWhereToPickup.getUTCMonth()
                              ]
                            }
                            &ensp;{localTimeFromWhereToPickup.getUTCDate()}
                            ,&ensp;
                            <span
                              className={
                                styles["local-time-from-where-to-pick-up__time"]
                              }
                            >
                              {localTimeOfAddressFrom}
                            </span>
                            {/* <span className={styles['local-time-from-where-to-pick-up__gmt']}>
                              {`(GMT${props.shipment.address_from.timezone_offset})`}
                            </span> */}
                          </div>
                        )}
                      </div>
                    </LocalizationProvider>
                  )}
                />

                <StyledRightColumn>
                  <Controller
                    control={methods.control}
                    name="time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <div>
                        {isMessagePickupToday && (
                          <div className={styles.MessagePickupToday}>
                            To schedule a pickup today,
                            <br />
                            order by{" "}
                            <span className={styles.MessagePickupTodayTime}>
                              {cutOffTimeToday}
                            </span>
                            &nbsp;(time at pick up location)
                          </div>
                        )}

                        <RadioGroup
                          className={styles.radioGroup}
                          name="time"
                          onChange={(value: any) =>
                            handleChangeTime(value, onChange)
                          }
                          value={value?.ready_time}
                        >
                          {dateSlot.timeslots.map((time: any) => (
                            <StyledBaseRadio
                              name="time"
                              label={getTimeSlotLabel(time)}
                              value={time.ready_time}
                              disabled={shouldDisableTimeOption(
                                time.ready_time,
                                dateSlot
                              )}
                              checked={time.ready_time === value?.ready_time}
                              key={time.ready_time}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    )}
                  />

                  <BaseButton
                    // onClick={handleNextStep}
                    className={styles.submitBtn}
                    loading={loading}
                    type="submit"
                    disabled={isNexStepBtnDisabled()}
                  >
                    NEXT STEP
                  </BaseButton>
                </StyledRightColumn>
              </PickupDateContent>
            </AccordionDetails>
          </Accordion>
        </form>
        <DevTool control={methods.control} />
      </FormProvider>
    </AccordionCard>
  );
};

export default PickupDateCard;
