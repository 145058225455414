import { useSelector, useDispatch } from "react-redux";
import { CardWrapper } from "../CardWrapper";
import { InfoRow } from "../InfoRow";
import { BaseNewSelect } from "../../../../components/BaseNewSelect";
import { Name } from "../../../../components/BaseIcon";
import { selectSystemCurrency } from "../../../../data/selectors";
import { updateProfile } from "../../data/actions";
import brandColors from "../../../../css/brandColors.scss";

type Props = {
  className?: string;
};

export const SiteSettings: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const systemCurrency = useSelector(selectSystemCurrency);

  const currencyOptions = [
    {
      label: "USD - United States dollar",
      value: "USD",
      leftIcon: Name.USD,
      // leftIconColor: '#FF5C5C'
    },
    {
      label: "EUR - Euro",
      value: "EUR",
      leftIcon: Name.EUR,
      // leftIconColor: '#FF5C5C'
    },
    {
      label: "ILS - Israeli new shekel",
      value: "ILS",
      leftIcon: Name.ILS,
      // leftIconColor: '#FF5C5C'
    },
  ];

  const handleChangeCurrency = (selectedCurrency: any) => {
    dispatch(
      updateProfile({
        system_currency: selectedCurrency.value,
      }),
    );
  };

  return (
    <CardWrapper className={props.className} title="Site settings">
      <InfoRow
        label="System currency"
        spinnerOnLoading
        inputField={
          <BaseNewSelect
            style={{ color: brandColors.mainBlue }}
            options={currencyOptions}
            value={
              currencyOptions.find((currency) => {
                return currency.value === systemCurrency;
              }) || ""
            }
            onChange={handleChangeCurrency}
            padding="0"
            withoutLabelSelecting
            withoutHover
            renderSelectedComponentWithIcon
          />
        }
      />
    </CardWrapper>
  );
};
