import { TheHeader } from "../../components/TheHeader";
import { TheFooter } from "../../components/TheFooter";
import { Outlet } from "react-router-dom";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";
import TheHeaderExportVariables from "../../components/TheHeader/TheHeaderExportVariables.scss";

const HeaderAndFooter = (props: any) => {
  const { innerWidth } = useIsResponsive();
  // console.log("=isMobileDevice=", isMobileDevice);

  return (
    <>
      <TheHeader />

      <Outlet />

      {/* {(!props.withoutFooterInMobile || !isMobileDevice) && <TheFooter />} */}
      {+innerWidth >=
        +TheHeaderExportVariables.mediaMinWidthMenuHeaderNumber && (
        <TheFooter />
      )}
    </>
  );
};

export default HeaderAndFooter;
