import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FIELD_IS_REQUIRED } from "../../../../../../../../../constants";
import {
  getMaxValidationMessage,
  getMinValidationMessage,
} from "../../../../../../../../../services/validation";
import {
  ENTER_VALID_EMAIL,
  ENG_AND_HEBREW_AND_NUMS_REG_EXP,
  FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
} from "../../../../../../../../../data/constants";

export const sss = {
  first_name: yup
    .string()
    .nullable()
    .required(FIELD_IS_REQUIRED)
    .max(35, getMaxValidationMessage(35)),
  // .test( // Закомментировано, так как иначе нельзя отправить форму с пробел в имени
  //   'check letters',
  //   FIELD_MUST_CONTAIN_ONLY_LETTERS,
  //   (value: any): boolean => !value.search(ENG_AND_HEBREW_REG_EXP)
  // ),
  last_name: yup
    .string()
    .nullable()
    .required(FIELD_IS_REQUIRED)
    .max(35, getMaxValidationMessage(35)),
  // .test( // Закомментировано, так как иначе нельзя отправить форму с пробел в имени
  //   'check letters',
  //   FIELD_MUST_CONTAIN_ONLY_LETTERS,
  //   (value: any): boolean => !value.search(ENG_AND_HEBREW_REG_EXP)
  // ),
  phone: yup.string().nullable().required(FIELD_IS_REQUIRED),

  passport: yup.mixed().when("is_company", {
    is: (val: any) => {
      // console.log("=passport=", val);
      return val;
    },
    then: yup.string().nullable().notRequired(),
    otherwise: yup
      .string()
      .nullable()
      .required(FIELD_IS_REQUIRED)
      .max(50, getMaxValidationMessage(50)),
  }),

  // if (val !== undefined) {
  //   if (val?.length) {
  //     return yup
  //       .string()
  //       .required(FIELD_IS_REQUIRED)
  //       .max(50, getMaxValidationMessage(50))
  //       // .test(
  //       //   'check letters and numbers',
  //       //   FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
  //       //   (value: any, formValues: any): boolean =>
  //       //     !value.search(ENG_AND_HEBREW_AND_NUMS_REG_EXP)
  //       // )
  //   } else {
  //     return yup.mixed().notRequired()
  //   }
  // }),

  company_name: yup.mixed().when("is_company", {
    is: (val: any) => {
      // console.log("=company_name=", val);
      return val;
    },
    then: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .min(3, getMinValidationMessage(3))
      .max(35, getMaxValidationMessage(35)),
    otherwise: yup.string().nullable().notRequired(),
  }),

  // company_name: yup.string().when('company_name', (val, schema) => {
  //   console.log("=company_name=", val, schema);
  //   // if (val !== undefined) {
  //   if (val?.length) {
  //     return yup
  //       .string()
  //       .required(FIELD_IS_REQUIRED)
  //       .min(3, getMinValidationMessage(3))
  //       .max(35, getMaxValidationMessage(35))
  //       .test(
  //         'check letters and numbers',
  //         FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
  //         (value: any, formValues: any): boolean =>
  //           !value.search(/^[- 0-9a-z\u0590-\u05fe]+$/i)
  //       )
  //   } else {
  //     return yup.mixed().notRequired()
  //   }
  // }),

  company_ein: yup.mixed().when("is_company", {
    is: (val: any) => {
      // console.log("=company_ein=", val);
      return val;
    },
    then: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .max(50, getMaxValidationMessage(50)),
    otherwise: yup.string().nullable().notRequired(),
  }),

  // company_ein: yup.string().when('company_ein', (val, schema) => {
  //   if (val?.length) {
  //     return yup
  //       .string()
  //       .required(FIELD_IS_REQUIRED)
  //       .max(50, getMaxValidationMessage(50))
  //       // .test(
  //       //   'check letters and numbers',
  //       //   FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
  //       //   (value: any, formValues: any): boolean =>
  //       //     !value.search(ENG_AND_HEBREW_AND_NUMS_REG_EXP)
  //       // )
  //   } else {
  //     return yup.mixed().notRequired()
  //   }
  // }),

  city_name: yup
    .string()
    .required(FIELD_IS_REQUIRED)
    .max(30, getMaxValidationMessage(30)),
  street_1: yup
    .string()
    .nullable()
    .required(FIELD_IS_REQUIRED)
    .min(3, getMinValidationMessage(3))
    .max(35, getMaxValidationMessage(35)),
  street_2: yup
    .string()
    .nullable()
    // .required(FIELD_IS_REQUIRED)
    // .min(1, getMinValidationMessage(1))
    .max(35, getMaxValidationMessage(35)),

  state: yup.mixed().when("isCountryHasState", {
    is: (val: any) => {
      // console.log("=isCountryHasState=", val);
      return val;
    },
    then: yup.string().nullable().required(FIELD_IS_REQUIRED),
    otherwise: yup.string().nullable().notRequired(),
  }),
  postal_code: yup
    .string()
    .required(FIELD_IS_REQUIRED)
    .max(12, getMaxValidationMessage(12)),
  // .test(
  //   'check letters and numbers',
  //   FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
  //   (value: any, formValues: any): boolean =>
  //     !value.search(ENG_AND_HEBREW_AND_NUMS_REG_EXP)
  // )
  email: yup
    .string()
    .nullable()
    .required(FIELD_IS_REQUIRED)
    .email(ENTER_VALID_EMAIL),
  // .email(),
  instructions: yup
    .string()
    .nullable()
    .max(80, "Maximum instruction length 80 characters"),
};

export const validationSchema = yupResolver(
  yup.object().shape({
    address_from: yup.object().shape({ ...sss }, [
      ["company_ein", "company_ein"],
      ["passport", "passport"],
      ["company_name", "company_name"],
    ]),
  }),
);
