import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { authReducer } from "../scenes/auth/data/reducers";
import { checkoutReducer } from "../scenes/checkout/data/reducers";
import { homeReducer } from "../scenes/home/data/reducers";
import { updateUser } from "../scenes/profile/data/actions";
import { profileReducer } from "../scenes/profile/data/reducers";
import { convertFormErrors } from "../services/formErrors/convertFormErrors";
import * as actions from "./actions";

import { REDUCER_NAME } from "./constants";

import { getStore } from "../integrationWithVue/handleBrowserStore";

const initialState = {
  user: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    photo_url: null,
    is_email_verified: false,
    is_phone_verified: false,
  },
  rtl: getStore("locale-re") === "he",
  locale: getStore("locale-re") || "en",
  loading: false,
  couponLoading: false,
  formErrors: [],
  coupon_code: null,
  unitSystem: localStorage.getItem("unitSystem") || "false",
  isDomesticShipping: false,
};

// const rootReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case 'SET_USER':
//       const user = Object.assign({ ...action.payload, isAuth: true })

//       return { ...state, user }
//     case 'SIGN_IN':
//       return { ...state, user: { ...state.user } }
//     case SHOW_LOADER:
//       return { ...state, loading: true }
//     case HIDE_LOADER:
//       return { ...state, loading: false }
//     case 'auth/SIGN_OUT':
//       return { ...state, user: initialState }
//     case 'CHANGE_UNIT_SYSTEM':
//       return { ...state, unitSystem: action.payload }
//     default:
//       return state
//   }
// }

// const setInfoToIntercom = (user: any) => {
//   if (!('Intercom' in globalThis)) return

//   globalThis.Intercom("boot", {
//     api_base: "https://api-iam.intercom.io",
//     app_id: "nh0pdpn1",
//     user_id: user.id,
//     name: `${user.first_name} ${user.last_name}`,
//     email: user.email,
//     phone: user.phone,
//     user_hash: user.options.intercom_hash,
//     // created_at: Date.now()
//   });

//   globalThis.Intercom("update");
// }

const reducer = {
  CHANGE_UNIT_SYSTEM: (state: any, { payload }: any) => {
    return {
      ...state,
      unitSystem: payload,
    };
  },
  "auth/SIGN_OUT": (state: any) => {
    return {
      ...state,
      user: initialState,
    };
  },
  [actions.showLoader.toString()]: (state: any) => {
    return {
      ...state,
      loading: true,
    };
  },
  [actions.setCouponLoading.toString()]: (
    state: any,
    { payload }: { payload: boolean },
  ) => {
    return {
      ...state,
      couponLoading: payload,
    };
  },
  [actions.setFormErrors.toString()]: (state: any, { payload }: any) => {
    return {
      ...state,
      formErrors: convertFormErrors(payload.details),
    };
  },
  [actions.clearFormErrors.toString()]: (state: any, { payload }: any) => {
    return {
      ...state,
      formErrors: null,
    };
  },
  [actions.hideLoader.toString()]: (state: any) => {
    return {
      ...state,
      loading: false,
    };
  },
  [actions.changeUnitSystem.toString()]: (state: any, { payload }: any) => {
    return {
      ...state,
      unitSystem: payload,
    };
  },
  [actions.setUser.toString()]: (state: any, { payload }: any) => {
    const user = Object.assign({ ...payload });

    return { ...state, user };
  },
  [updateUser.toString()]: (state: any, { payload }: any) => {
    const user = { ...state.user, ...payload };

    return {
      ...state,
      user,
    };
  },
  [actions.setDomesticShipping.toString()]: (state: any, { payload }: any) => {
    return {
      ...state,
      isDomesticShipping: payload,
    };
  },
  [actions.setRtl.toString()]: (state: any, { payload }: any) => {
    // console.log("=setRtl=", payload);

    return {
      ...state,
      rtl: payload,
    };
  },
  [actions.setLocale.toString()]: (state: any, { payload }: any) => {
    // console.log("=setLocale=", payload);

    return {
      ...state,
      locale: payload,
    };
  },
};

export const rootReducer = {
  [REDUCER_NAME]: handleActions(reducer, initialState),
};

export const reducers = combineReducers({
  ...checkoutReducer,
  ...rootReducer,
  ...authReducer,
  ...profileReducer,
  home: homeReducer,
});
