export const getDefaultFormValues = (shipment: any) => {
  return {
    customs_items: shipment?.customs_declaration?.items?.length
      ? shipment?.customs_declaration?.items?.map((item: any) => {
          return {
            object_id: item.object_id,
            description: item.description,
            item_weight: item.item_weight,
            quantity: item.quantity,
            item_value: item.item_value,
            origin_country_code: item.origin_country_code || "",
            hs_code: item.hs_code || {
              value: "",
            },
          };
        })
      : [
          {
            id: Date.now(),
            quantity: null,
            description: "",
            item_weight: null,
            item_value: null,
            // origin_country_code: shipment?.address_from?.country_code,
            origin_country_code: "",
            hs_code: {
              value: "",
            },
          },
        ],
  };
};
