import * as React from "react";

export const Exit: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      fill="#A8B6D2"
      d="m20.784 12.534-3.937 3.938a.778.778 0 0 1-.535.215.75.75 0 0 1-.525-1.284l2.654-2.653H9.75a.75.75 0 1 1 0-1.5h8.69l-2.653-2.653a.753.753 0 0 1 1.06-1.069l3.937 3.938a.76.76 0 0 1 0 1.068ZM9.75 19.5H4.5v-15h5.25a.75.75 0 1 0 0-1.5H4.5A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h5.25a.75.75 0 1 0 0-1.5Z"
    />
  </svg>
);
