import React, { useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { BaseButton } from "../../../../../../../components/BaseButton";
import { BaseIcon, Name } from "../../../../../../../components/BaseIcon";
import { Notify } from "../../../../../../../services/notifications";
import { createCustomInvoice, removeCustomInvoice } from "../../../../../api";
import { getBase64FromFile } from "../../../../../services/getBase64FromFile";
import {
  StyledRoot,
  StyledSelectedText,
  StyledText,
} from "./FileUploader.styled";
import brandColors from "../../../../../../../css/brandColors.scss";

const FileUploader = (props: any) => {
  const { fileName, shipmentId, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const handleRemoveFile = async () => {
    setLoadingFile(true);

    const res = await removeCustomInvoice(shipmentId);

    if (res) {
      onChange(null);
      inputRef.current && (inputRef.current.value = "");
    }

    setLoadingFile(false);
  };

  const handleAddInvoice = async (event: any) => {
    // console.log("=handleAddInvoice=", event);
    setLoadingFile(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const content = await getBase64FromFile(files[0]);

      const fileSizeInKb = files[0].size / 1000;
      const maxSizeInKb = 2048;

      if (fileSizeInKb < maxSizeInKb) {
        const res = await createCustomInvoice({
          shipmentId,
          formValues: {
            // format: files[0].type,
            format: files[0].name.split(".").pop().toUpperCase(),
            file_name: files[0].name,
            content,
          },
        });

        onChange(res);
      } else {
        Notify({
          message: `Selected file should not be more than ${maxSizeInKb}kb`,
          type: "error",
        });
      }
    }

    setLoadingFile(false);
  };

  return (
    <StyledRoot isFileSelected={!!fileName} {...props}>
      <BaseIcon name={Name.PDF} color="#7F90B1" size={48} />

      {fileName ? (
        <StyledSelectedText>{fileName}</StyledSelectedText>
      ) : (
        <StyledText>
          If you have a commercial invoice, drag file here or click to browse
          (PDF only)
        </StyledText>
      )}

      {loadingFile ? (
        <CircularProgress
          color="primary"
          style={{ marginLeft: "10px", flexShrink: "0" }}
          size="24px"
        />
      ) : (
        <>
          {fileName && (
            <BaseButton
              style={{
                zIndex: "1000",
                textDecoration: "underline",
                paddingLeft: "24px",
                paddingRight: "8px",
              }}
              onClick={handleRemoveFile}
              clear
            >
              Delete
            </BaseButton>
          )}

          <input
            id="uploadCaptureInputFile"
            type="file"
            ref={inputRef}
            onChange={handleAddInvoice}
            accept="application/pdf"
            style={{
              opacity: "0",
              position: "absolute",
              width: "100%",
              height: "100%",
              cursor: "pointer",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
        </>
      )}
    </StyledRoot>
  );
};

export default FileUploader;
