export enum UnitSystem {
  METRIC = "false",
  IMPERIAL = "true",
}

type Props = {
  value?: number;
  unitSystem: UnitSystem;
  returnOnlyNumber?: boolean;
  returnOnlyUnit?: boolean;
  isMobile?: boolean;
  rtl?: boolean;
};

export const getConvertedWeightValue = ({
  value,
  unitSystem,
  returnOnlyNumber,
  returnOnlyUnit,
  rtl,
}: Props): any => {
  // console.log("=getConvertedWeightValue=", rtl);
  const kgByLbRatio = 2.20462;
  const kg = rtl ? 'ק"ג' : "kg";
  const lb = rtl ? "פאונד" : "lb";

  let convertedValue = value || "-";

  if (
    unitSystem?.toString() === UnitSystem.METRIC ||
    unitSystem?.toString() === "metric"
  ) {
    if (returnOnlyNumber) {
      return convertedValue;
    }

    if (returnOnlyUnit) {
      return kg;
    }

    return value + ` ${kg}`;
  }

  value && (convertedValue = Math.round(value * kgByLbRatio));

  if (returnOnlyNumber) {
    return convertedValue;
  }

  if (returnOnlyUnit) {
    return lb;
  }

  return convertedValue + ` ${lb}`;
};

// export const getConvertedWeightValue2 = ({
//   value,
//   unitSystem,
//   returnOnlyNumber,
//   returnOnlyUnit
// }: Props): any => {
//   // console.log("=getConvertedWeightValue=",
//   //   value,
//   //   unitSystem,
//   //   returnOnlyNumber,
//   //   returnOnlyUnit
//   // );

//   const kgByLbRatio = 2.20462

//   let convertedValue = value || '-'

//   if (
//     unitSystem?.toString() === UnitSystem.METRIC ||
//     unitSystem?.toString() === 'metric'
//   ) {
//     value && (convertedValue = (value / kgByLbRatio).toFixed(2))

//     if (returnOnlyNumber) {
//       return convertedValue
//     }

//     if (returnOnlyUnit) {
//       return 'kg'
//     }

//     return value + ' kg'
//   }

//   value && (convertedValue = (value * kgByLbRatio).toFixed(2))

//   if (returnOnlyNumber) {
//     return convertedValue
//   }

//   if (returnOnlyUnit) {
//     return 'lb'
//   }

//   return convertedValue + ' lb'
// }

export const getConvertedSizeValue = ({
  value,
  unitSystem,
  returnOnlyNumber,
  returnOnlyUnit,
  isMobile = false,
  rtl,
}: Props): any => {
  // console.log("=getConvertedSizeValue=", rtl);
  const cmByInchRatio = 0.393701;
  const cm = rtl ? 'ס"מ' : "cm";
  let convertedValue = value || "-";

  if (
    unitSystem.toString() === UnitSystem.METRIC ||
    unitSystem.toString() === "metric"
  ) {
    if (returnOnlyNumber) {
      return convertedValue;
    }

    if (returnOnlyUnit) {
      return cm;
    }

    return convertedValue + ` ${cm}`;
  }

  value && (convertedValue = Math.round(value * cmByInchRatio));

  const dimension = isMobile ? "in" : rtl ? "אינץ׳" : "inch";

  if (returnOnlyNumber) {
    return convertedValue;
  }

  if (returnOnlyUnit) {
    return dimension;
  }

  return convertedValue + ` ${dimension}`;
};

// export const getConvertedSizeValue2 = ({
//   value,
//   unitSystem,
//   returnOnlyNumber,
//   returnOnlyUnit
// }: Props): any => {
//   const cmByInchRatio = 0.393701

//   let convertedValue = value || '-'

//   if (
//     unitSystem.toString() === UnitSystem.METRIC ||
//     unitSystem.toString() === 'metric'
//   ) {
//     value && (convertedValue = (value / cmByInchRatio).toFixed(2))

//     if (returnOnlyNumber) {
//       return convertedValue
//     }

//     if (returnOnlyUnit) {
//       return 'cm'
//     }

//     return convertedValue + ' cm'
//   }

//   value && (convertedValue = (value * cmByInchRatio).toFixed(2))

//   if (returnOnlyNumber) {
//     return convertedValue
//   }

//   if (returnOnlyUnit) {
//     return 'inch'
//   }

//   return convertedValue + ' inch'
// }
