import { useState } from "react";
import { HF } from "../../../../../../../components";
import { BaseIcon } from "../../../../../../../components/BaseIcon";
import { CountrySelectV2 } from "../../../../../../../components/CountrySelectV2";
import { Name } from "../../../../../../../components/BaseIcon";
import { getConvertedWeightValue } from "../../../../../../../services/convertUnitSystemValues";
import { MAX_WEIGHT_VALUE } from "../../../../../../home/data/constants";
import { CommodityCodeSearchModal } from "./CommodityCodeSearchModal";
import { WeightTooltip } from "./WeightTooltip";
import { HSCodeTooltip } from "./HSCodeTooltip";
import { AmountTooltip } from "./AmountTooltip";
import styles from "./PackageItem.scss";
import { StyledRoot, StyledUnit } from "./styles/PackageItem.styled";
import { useSelector } from "react-redux";
import { selectShipmentCurrency } from "../../../../../data/selectors";

export const PackageItem = (props: any) => {
  const currency = useSelector(selectShipmentCurrency);

  const handleValueAmountBlur = (e: any): number | void => {
    const maxValue = 9_999_999;

    if (e.target.value <= 0) {
      props.change({
        fieldName: `${props.arrayName}.${props.index}.item_value`,
        value: "",
      });
    } else {
      if (e.target.value > maxValue) {
        props.change({
          fieldName: `${props.arrayName}.${props.index}.item_value`,
          value: maxValue,
        });
      }
    }
  };

  const handleWeightBlur = (e: any): void => {
    const maxValue = getConvertedWeightValue({
      value: MAX_WEIGHT_VALUE,
      unitSystem: props.unitSystem,
      returnOnlyNumber: true,
    });

    if (e.target.value <= 0.1) {
      props.change({
        fieldName: `${props.arrayName}.${props.index}.item_weight`,
        value: 0.1,
      });
    } else {
      if (e.target.value > maxValue) {
        props.change({
          fieldName: `${props.arrayName}.${props.index}.item_weight`,
          value: maxValue,
        });
      }
    }
  };

  const [isCommodityCodeSearchModal, setIsCommodityCodeSearchModal] =
    useState(false);
  // const [isCommodityCodeSearchModal, setIsCommodityCodeSearchModal] = useState(true)

  const handleChangeDescription = (event: any) => {
    // console.log("=handleChangeDescription=", event);
    // console.log("=handleChangeDescription=", event.target.value);

    props.change({
      fieldName: `${props.arrayName}.${props.index}.description`,
      value: event.target.value,
    });

    props.change({
      fieldName: `${props.arrayName}.${props.index}.hs_code.selected`,
      value: {},
    });

    props.change({
      fieldName: `${props.arrayName}.${props.index}.hs_code.category_id`,
      value: "",
    });

    props.change({
      fieldName: `${props.arrayName}.${props.index}.hs_code.id`,
      value: "",
    });
  };

  const handleCloseCommodityCodeSearchModal = (payload: any) => {
    // console.log("=handleCloseCommodityCodeSearchModal=", payload);

    setIsCommodityCodeSearchModal(false);

    for (let key in payload) {
      props.change({
        fieldName: `${props.arrayName}.${props.index}.${key}`,
        value: payload[key],
      });
    }
  };

  return (
    <StyledRoot>
      <div className={styles.StyledSelectWrapper}>
        <div>
          <HF.Input
            className={styles.priorityWidth}
            label="Detailed item description"
            name={`${props.arrayName}.${props.index}.description`}
            onChange={handleChangeDescription}
          />

          <HF.Input
            name={`${props.arrayName}.${props.index}.quantity`}
            className={`${styles.moneyInput} ${styles.Quantity}`}
            label="Quantity"
            // onBlur={handleWeightBlur}
            type="number"
            inputMode="decimal"
            // withoutErrors
          />

          <div
            style={{ display: "flex", alignItems: "center" }}
            className={styles.inputItem}
          >
            <HF.Input
              name={`${props.arrayName}.${props.index}.item_weight`}
              className={styles.moneyInput}
              label="Item weight"
              // onBlur={handleWeightBlur}
              type="number"
              inputMode="decimal"
              step="0.001"
              error={props.getWeightItemErrorMessageInPackageDetails(
                props.watch(`${props.arrayName}.${props.index}.item_weight`),
              )}
              suffix={
                <StyledUnit>
                  {getConvertedWeightValue({
                    unitSystem: props.unitSystem,
                    returnOnlyUnit: true,
                  })}
                </StyledUnit>
              }
              // withoutErrors
            />

            <WeightTooltip />
          </div>
        </div>

        <div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className={styles.field25}
          >
            <HF.Input
              style={{ marginBottom: "20px" }}
              name={`${props.arrayName}.${props.index}.hs_code.value`}
              value={props.methods.watch(
                `${props.arrayName}.${props.index}.hs_code.value`,
              )}
              value2={props.methods.watch(
                `${props.arrayName}.${props.index}.hs_code.value`,
              )}
              label="HS code"
              suffix={<BaseIcon name={Name.Search} isButton />}
              withoutErrors
              onSuffixClick={() => setIsCommodityCodeSearchModal(true)}
            />

            <HSCodeTooltip />
          </div>

          {isCommodityCodeSearchModal && (
            <CommodityCodeSearchModal
              packageItem={props.watch(`${props.arrayName}.${props.index}`)}
              countryCode={props.shipment.address_to.country_code}
              open={isCommodityCodeSearchModal}
              onClose={handleCloseCommodityCodeSearchModal}
            />
          )}

          <CountrySelectV2
            style={{ marginBottom: "20px" }}
            className={`${styles.CountrySelect} ${styles.field25}`}
            name="origin_country_code"
            countryCode={props.package.origin_country_code || ""}
            label="Country of manufacture"
            disableClearable
            onChange={props.onSelectedCountry}
          />

          <div
            style={{ display: "flex", alignItems: "center" }}
            className={styles.inputItem}
          >
            <HF.Input
              name={`${props.arrayName}.${props.index}.item_value`}
              className={styles.moneyInput}
              label="Item value"
              onBlur={handleValueAmountBlur}
              type="number"
              inputMode="decimal"
              suffix={<StyledUnit>{currency}</StyledUnit>}
              // withoutErrors
            />

            <AmountTooltip />
          </div>
        </div>
      </div>

      {props.listOptions.length > 1 && (
        <div className={styles.wrapperRemoveBtn}>
          <button className={styles.removeBtn} onClick={props.onRemove}>
            <BaseIcon name={Name.CloseInCircle} isButton />
          </button>
        </div>
      )}
    </StyledRoot>
  );
};
