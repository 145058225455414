export const getDisabledSubmitBtn = ({
  addressFromInputError,
  addressToInputError,
  methods,
}: any): boolean => {
  return (
    !addressFromInputError &&
    !addressToInputError &&
    methods.watch("packages").every((itemPackage: any) => {
      return (
        itemPackage.height &&
        itemPackage.length &&
        itemPackage.weight &&
        itemPackage.width
      );
    }) &&
    methods.watch("locationFrom.country_code") &&
    methods.watch("locationTo.country_code") &&
    methods.watch("locationFrom.city_name") &&
    methods.watch("locationTo.city_name")
  );
};
