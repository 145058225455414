import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

import brandColors from "../../../../../css/brandColors.scss";

export const StyledRoot = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const StyledLinesBlock = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 22px;
  margin-bottom: 16px;
`;

export const StyledSignUpWithText = styled("div")`
  font-size: 14px;
  line-height: 130%;
  color: ${brandColors.lightBlueColor};
  padding-left: 18px;
  padding-right: 18px;
  white-space: nowrap;
`;

export const StyledLine = styled("div")`
  border-top: 1px solid ${brandColors.lightBlueColor};
  width: 100%;
  display: block;
`;

export const StyledSocialLinkBlock = styled("div")`
  display: flex;
  justify-content: center;
`;

export const StyledSocialLink = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${brandColors.lightBlueColor};
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  &:not(:last-child) {
    margin-right: 24px;
  }

  & > * {
    width: 18px !important;
    height: 18px !important;
  }
`;

export const StyledSpinner = styled(CircularProgress)`
  color: black;
  position: absolute;
`;
