import * as React from "react";

export const Pencil: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.44 2.534A3.523 3.523 0 0 1 18.951 1.5c.909 0 1.825.347 2.512 1.034a3.565 3.565 0 0 1 0 5.025L8.027 20.781l-.152.145L2.569 22.7a1 1 0 0 1-1.266-1.264l1.769-5.312 13.367-13.59Zm3.768 1.256a1.76 1.76 0 0 0-1.256-.52 1.754 1.754 0 0 0-1.25.513L16.315 5.2c.48.195.993.5 1.493 1a4.474 4.474 0 0 1 .999 1.485l1.402-1.381a1.764 1.764 0 0 0 0-2.513Zm-5.066 2.908c.326.028.805.153 1.401.75.604.604.73 1.083.764 1.409.018.215-.005.27-.021.308l-.007.018-9.182 9.03a4.542 4.542 0 0 0-.958-1.36 4.464 4.464 0 0 0-1.36-.952l9.036-9.175.008-.004c.039-.02.084-.043.319-.024ZM5.89 18.116c-.61-.611-1.055-.722-1.333-.743-.016 0-.016.002-.017.003l-.01-.003-.57 1.7a4.984 4.984 0 0 1 .972.965l1.694-.562c-.004-.015-.004-.016-.003-.019.001-.003.003-.007.003-.023-.021-.27-.132-.715-.736-1.319Z"
    />
  </svg>
);
