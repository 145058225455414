import * as React from "react";

export const LinkedIn: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5ZM8.21875 7.28125C7.48575 7.28125 7 7.743 7 8.375C7 8.995 7.4685 9.5 8.1875 9.5C8.9355 9.5 9.40625 8.994 9.40625 8.375C9.39225 7.743 8.95275 7.28125 8.21875 7.28125ZM14.5312 10.2188C13.3922 10.2187 12.8788 10.8412 12.5938 11.2812L12.5938 10.375L10.4688 10.375C10.4977 10.98 10.4688 16.8125 10.4688 16.8125L12.5938 16.8125L12.5938 13.2188C12.5938 13.0268 12.6002 12.8568 12.6562 12.7188C12.8113 12.3327 13.1892 11.9062 13.7812 11.9062C14.5572 11.9063 14.8438 12.509 14.8438 13.375L14.8438 16.8125L17 16.8125L17 13.125C17 11.145 15.9413 10.2188 14.5312 10.2188ZM7.125 10.375L7.125 16.8125L9.28125 16.8125L9.28125 10.375L7.125 10.375Z"
      fill="#7f90b1"
      fill-rule="nonzero"
      opacity="1"
      stroke="none"
    />
  </svg>
);
