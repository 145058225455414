import { useState, useEffect } from "react";
import { BaseModal } from "../../../../components/BaseModal";
import styles from "./NotificationPrices.scss";

export const NotificationPrices = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [contentModal, setContentModal] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const pathBase =
          process.env.REACT_APP_IS_LOCAL_DEVELOPMENT === "true"
            ? process.env.PUBLIC_URL
            : process.env.REACT_APP_EMAIL_SENDER_ENDPOINT;
        // console.log("=fetch pathBase=", pathBase);

        const res = await fetch(pathBase + "/notice-regarding-israel.json", {
          // ! Обязательно на стороне сервера убрать CORS. Это сделано в файле .htaccess
          cache: "no-cache",
        });
        // console.log("=fetch res=", res);

        const data = await res.json();

        // console.log('=fetch data JSON=', data, data?.il_notification?.is_active === true)

        data?.il_notification?.is_active === true &&
          data?.il_notification?.text &&
          setContentModal(data.il_notification.text);
      } catch (error) {
        console.log("=Request failed=", error);
      }
    })();
  }, []);

  const onModalShown = () => {
    globalThis?.UserWay?.iconVisibilityOff();

    setIsModalShown(true);
  };

  const onModalHide = () => {
    globalThis?.UserWay?.iconVisibilityOn();

    setIsModalShown(false);
  };

  return contentModal ? (
    <div className={styles.NotificationPrices}>
      <div className={styles.top}>
        <div className={styles.column} />

        <svg
          className={styles.icon}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12ZM12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
            fill="#FFAB1C"
          />
        </svg>

        <div className={styles.text}>
          Special notice regarding shipments to and from Israel&nbsp;
          <span className={styles.btn} onClick={onModalShown}>
            (click for more info)
          </span>
        </div>
      </div>

      {/* <ul style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px', marginTop: '24px', marginBottom: '12px', lineHeight: '1.3', gap: '8px' }}>
          <div style={{  }}>
            Israel couriers working area limitation:
          </div>

          <li style={{ marginLeft: '12px' }}>
            <span style={{ fontWeight: '600' }}>
              South of Israel&nbsp;
            </span>
              – no service to the surroundings of Gaza strip
          </li>

          <li style={{ marginLeft: '12px' }}>
            <span style={{ fontWeight: '600' }}>
              North of Israel&nbsp;
            </span>
              – service as normal with minimal disruptions, in accordance with ongoing instructions from the Home Front Command
          </li>

          <li style={{ marginLeft: '12px' }}>
            <span style={{ fontWeight: '600' }}>
              Palestinian Authority&nbsp;
            </span>
            – no service
          </li>

          <li style={{ marginLeft: '12px' }}>
            <span style={{ fontWeight: '600' }}>
              Rest of Israel&nbsp;
            </span>
            – service as normal
          </li>
        </ul> */}

      <BaseModal
        className={styles.modal}
        open={isModalShown}
        onClose={onModalHide}
      >
        <div dangerouslySetInnerHTML={{ __html: contentModal }}></div>

        {/* <ul style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px', marginBottom: '12px', lineHeight: '1.3', gap: '8px' }}>
            <div style={{  }}>
              In light of the current and evolving situation in Israel, couriers in Israel are currently working in limited areas and with reduced staff.
            </div>

            <div style={{ marginTop: '12px' }}>
              Specific limitations (last updated: Oct 26, 2023):
            </div>

            <li style={{ marginLeft: '12px' }}>
              <span style={{ fontWeight: '600' }}>
                South of Israel&nbsp;
              </span>
                – no service to the surroundings of Gaza strip
            </li>

            <li style={{ marginLeft: '12px' }}>
              <span style={{ fontWeight: '600' }}>
                North of Israel&nbsp;
              </span>
                – service as normal with minimal disruptions, in accordance with ongoing instructions from the Home Front Command
            </li>

            <li style={{ marginLeft: '12px' }}>
              <span style={{ fontWeight: '600' }}>
                Palestinian Authority&nbsp;
              </span>
              – no service
            </li>

            <li style={{ marginLeft: '12px' }}>
              <span style={{ fontWeight: '600' }}>
                Rest of Israel&nbsp;
              </span>
              – service as normal
            </li>

            <div style={{ marginTop: '12px' }}>
              Please be advised that due to the situation there may be delays in both pickup and drop-off times, which could extend beyond the estimated dates displayed on our platform. We understand that this might be inconvenient for you, and we sincerely apologize for any disruption.
            </div>

            <div style={{  }}>
              Thank you for choosing Shipper Global for your shipping needs.
            </div>
          </ul> */}
      </BaseModal>
    </div>
  ) : (
    <></>
  );
};
