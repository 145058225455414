import { useState } from "react";
import { BaseModal } from "../../components/BaseModal";
import { BaseButton } from "../../components/BaseButton";
import styles from "./ModalDeleteCardAddressBook.scss";
import { deleteAddress } from "./_apiRequests.AppAddressBook";

interface ModalDeleteCardAddressBookProps {
  addressID: string;
  open: boolean;
  onClose(): void;
  afterDeletingAddress(id: string): void;
}

export const ModalDeleteCardAddressBook = ({
  addressID,
  open,
  onClose,
  afterDeletingAddress,
}: ModalDeleteCardAddressBookProps) => {
  const [loadingDeleteAddress, setLoadingDeleteAddress] = useState(false);

  const onClickDelete = async () => {
    setLoadingDeleteAddress(true);

    try {
      await deleteAddress(addressID);

      afterDeletingAddress(addressID);

      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDeleteAddress(false);
    }
  };

  return (
    <BaseModal
      className={styles.ModalDeleteCardAddressBook}
      open={open}
      onClose={onClose}
    >
      <div className={styles.ModalDeleteCardAddressBookContent}>
        <h6 className={styles.ModalDeleteCardAddressBookContentTitle}>
          Confirm address deletion
        </h6>

        <p className={styles.ModalDeleteCardAddressBookContentMessage}>
          Are you sure you want to delete this address? This action cannot be
          undone, and all associated data will be lost. Please confirm your
          choice.
        </p>
      </div>

      <div className={styles.ModalDeleteCardAddressBookFooter}>
        <button
          className={styles.ModalDeleteCardAddressBookFooterBtn}
          onClick={onClose}
        >
          Cancel
        </button>

        <BaseButton
          className={`${styles.ModalDeleteCardAddressBookFooterBtn} ${styles.ModalDeleteCardAddressBookFooterConfirmBtn}`}
          loading={loadingDeleteAddress}
          clear
          onClick={onClickDelete}
        >
          Confirm
        </BaseButton>
      </div>
    </BaseModal>
  );
};
