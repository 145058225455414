import brand1 from "../assets/home-page/brands/aol.svg";
import brand2 from "../assets/home-page/brands/Clip_path_group.svg";
import brand3 from "../assets/home-page/brands/Frame.svg";
import brand4 from "../assets/home-page/brands/GOLD_BOND.svg";
import brand5 from "../assets/home-page/brands/Group_631929.svg";
import brand6 from "../assets/home-page/brands/Group_631935.svg";
import brand7 from "../assets/home-page/brands/Group.svg";
import brand8 from "../assets/home-page/brands/Group0.svg";
import brand9 from "../assets/home-page/brands/unity.png";
import brand10 from "../assets/home-page/brands/logo_hyp_color.svg";
import brand11 from "../assets/home-page/brands/nec.svg";
import brand12 from "../assets/home-page/brands/Subtract.svg";
import brand13 from "../assets/home-page/brands/Group-12.svg";
import styles from "./BrandPlates.scss";
import { useIsResponsive } from "../services/breakpoint/useIsResponsive";

export const BrandPlates = () => {
  const { isMobile } = useIsResponsive();

  const brands = [
    {
      src: brand10,
      styles: {
        padding: isMobile ? "7px 10px" : "10px 13px",
      },
    },
    {
      src: brand4,
      styles: {
        padding: isMobile ? "10px 10px" : "12px 13px",
      },
    },
    {
      src: brand8,
      styles: {
        padding: isMobile ? "7px 13px" : "9px 18px",
      },
    },
    {
      src: brand12,
      styles: {
        padding: isMobile ? "7px 10px" : "10px 13px",
      },
    },
    {
      src: brand5,
      styles: {
        padding: isMobile ? "6px 10px" : "7px 13px",
      },
    },
    {
      src: brand11,
      styles: {
        padding: isMobile ? "10px 10px" : "14px 13px",
      },
    },
    {
      src: brand7,
      styles: {
        padding: isMobile ? "6px 10px" : "7px 13px",
      },
    },
    {
      src: brand6,
      styles: {
        padding: isMobile ? "5px 10px" : "6px 13px",
      },
    },
    {
      src: brand1,
      styles: {
        padding: isMobile ? "10px 10px" : "13px 13px",
      },
    },
    {
      src: brand3,
      styles: {
        padding: isMobile ? "9px 10px" : "12px 13px",
      },
    },
    {
      src: brand9,
      styles: {
        padding: isMobile ? "5px 10px" : "6px 13px",
      },
      classes: styles.unity,
    },
    {
      src: brand2,
      styles: {
        padding: isMobile ? "6px 10px" : "8px 13px",
      },
    },
    {
      src: brand13,
      styles: {
        padding: isMobile ? "3px 10px" : "6px 13px",
      },
    },
  ];

  return (
    <div className={styles.root}>
      {brands.map((item) => (
        // <div>
        <div
          style={item.styles}
          className={styles.card + (item.classes ? ` ${item.classes}` : "")}
        >
          <img src={item.src} />
        </div>
        // </div>
      ))}
    </div>
  );
};
