import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import type { Location } from "@remix-run/router";
import { useDispatch, useSelector } from "react-redux";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SIGN_IN_TYPE, SIGN_UP_TYPE } from "./data/constants";
import { selectIsLoading } from "../../data/selectors";
import { selectIsAuth } from "./data/selectors";
import { signIn, signUp } from "./data/actions";
import redirectAfterSuccessAuth from "./services/redirectAfterSuccessAuth";

import { Name } from "../../components/BaseIcon";

import { SignUpForm } from "./components/SignUpForm";
import { InfoBlock } from "./components/InfoBlock";
import { SignInForm } from "./components/SignInForm";
import { FormWrapper } from "./components/FormWrapper";
import { ResetPasswordTabContent } from "./components/ResetPasswordTab";

import {
  StyledRoot,
  StyledContainer,
  StyledMobileBrandArrowLeft,
  StyledMobileBrandArrowRight,
} from "./styles/Auth.styled";

import styles from "./styles/Auth.scss";
import SwipeableViews from "react-swipeable-views";

import { selectRtl, selectLocale } from "../../data/selectors";
import { languageOptions } from "../../components/localization/Localization";
import { setRtl, setLocale } from "../../data/actions";
import getUrlAndLocation from "../../integrationWithVue/getUrlAndLocation";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {children}
    </div>
  );
}

const Auth: React.FC = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const rtl = useSelector(selectRtl);
  const locale = useSelector(selectLocale);
  // console.log("=rtl=", rtl);

  const dispatch = useDispatch();

  // const { _location, url } = getUrlAndLocation

  // const localeLocalStorage = localStorage.getItem('locale-re') || ''
  // const localeBrowser = navigator.language
  // // console.log("=localeBrowser, localeLocalStorage=", localeBrowser, localeLocalStorage);
  // let _locale: string

  //   if (_location.pathname.startsWith('/he')) {
  //   _locale = 'he'
  //   } else if (_location.pathname.startsWith('/tr')) {
  //   _locale = 'tr'
  //   } else if (languageOptions.find((item:any) => item.value === localeLocalStorage)) {
  //   _locale = localeLocalStorage
  // } else if(languageOptions.find((item:any) => item.value === localeBrowser)) {
  //   _locale = localeBrowser
  // } else {
  //   _locale = 'en'
  // }

  // console.log("=Auth setLocale=", _locale);
  // dispatch(setRtl(_locale === 'he'))
  // dispatch(setLocale(_locale))

  const [searchParams, setSearchParams] = useSearchParams();
  const [authType, setAuthType] = useState(
    searchParams.get("type") || SIGN_IN_TYPE,
  );
  const location: Location = useLocation();
  const loading = useSelector(selectIsLoading);
  const isAuth = useSelector(selectIsAuth);
  const from = searchParams.get("from");

  // console.log("=from=", from);

  useEffect(() => {
    if (isAuth) {
      redirectAfterSuccessAuth(location, from, rtl, locale);
    }
  }, [isAuth, location, from]);

  useEffect(() => {
    if (!location?.state) {
      if (from) {
        setSearchParams({ type: authType, from });
      } else {
        setSearchParams({ type: authType });
      }
    }
  }, [authType, location?.state]);

  const handleSubmit = async (formValues: any, type: string): Promise<void> => {
    if (type === SIGN_UP_TYPE) {
      dispatch(signUp(formValues));
    } else {
      dispatch(signIn(formValues));
    }
  };

  const handleChangeAuthType = (value: string) => {
    setAuthType(value);
  };

  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeIndex = (index: number): void => {
    setCurrentTab(index);
  };

  return (
    <StyledRoot
      className={`${styles.root} ${
        authType === SIGN_UP_TYPE ? styles.signUp : styles.signIn
      }`}
    >
      <StyledContainer>
        <TransitionGroup className={styles.transitionContainer}>
          {authType === SIGN_UP_TYPE ? (
            <CSSTransition
              timeout={800}
              classNames={{
                enterActive: styles.AuthTransitionEnterActive,
                enterDone: styles.AuthTransitionEnterDone,
                exitActive: styles.AuthTransitionExit,
                exitDone: styles.AuthTransitionExitActive,
              }}
            >
              <FormWrapper title="Sign up" className={styles.formWrapper}>
                <SignUpForm
                  onSubmit={(values: any) => handleSubmit(values, SIGN_UP_TYPE)}
                  loading={loading}
                  onChangeAuthType={(value: string) =>
                    handleChangeAuthType(value)
                  }
                />
              </FormWrapper>
            </CSSTransition>
          ) : (
            <CSSTransition
              timeout={800}
              classNames={{
                enterActive: styles.AuthTransitionEnterActive,
                enterDone: styles.AuthTransitionEnterDone,
                exitActive: styles.AuthTransitionExit,
                exitDone: styles.AuthTransitionExitActive,
              }}
            >
              <FormWrapper
                title={currentTab === 0 ? "Sign in" : "Forgot password?"}
                className={styles.formWrapper}
              >
                <SwipeableViews
                  axis={"x-reverse"}
                  index={currentTab}
                  onChangeIndex={handleChangeIndex}
                  style={{ width: "100%" }}
                  disabled
                >
                  <TabPanel value={currentTab} index={0}>
                    <SignInForm
                      onSubmit={(values: any) =>
                        handleSubmit(values, SIGN_IN_TYPE)
                      }
                      loading={loading}
                      onChangeAuthType={(value: string) =>
                        handleChangeAuthType(value)
                      }
                      onChangeTab={(value: number) => setCurrentTab(value)}
                    />
                  </TabPanel>
                  <TabPanel
                    // className={styles.tab}
                    value={currentTab}
                    index={1}
                    // dir={theme.direction}
                  >
                    <ResetPasswordTabContent onBack={() => setCurrentTab(0)} />
                  </TabPanel>
                </SwipeableViews>
              </FormWrapper>
            </CSSTransition>
          )}
        </TransitionGroup>

        <TransitionGroup className={styles.transitionContainer}>
          {authType === SIGN_UP_TYPE ? (
            <CSSTransition
              timeout={800}
              classNames={{
                enterActive: styles.AuthTransitionEnterActive,
                enterDone: styles.AuthTransitionEnterDone,
                exitActive: styles.AuthTransitionExit,
                exitDone: styles.AuthTransitionExitActive,
              }}
            >
              <InfoBlock
                className={styles.infoBlock}
                isSignUp={authType === SIGN_UP_TYPE}
                onChangeAuthType={() => handleChangeAuthType(SIGN_IN_TYPE)}
              />
            </CSSTransition>
          ) : (
            <CSSTransition
              timeout={800}
              classNames={{
                enterActive: styles.AuthTransitionEnterActive,
                enterDone: styles.AuthTransitionEnterDone,
                exitActive: styles.AuthTransitionExit,
                exitDone: styles.AuthTransitionExitActive,
              }}
            >
              <InfoBlock
                className={styles.infoBlock}
                isSignUp={authType === SIGN_UP_TYPE}
                onChangeAuthType={() => handleChangeAuthType(SIGN_UP_TYPE)}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </StyledContainer>

      <StyledMobileBrandArrowLeft name={Name.BrandBox} />
      <StyledMobileBrandArrowRight name={Name.BrandBox} />
    </StyledRoot>
  );
};

export default Auth;
