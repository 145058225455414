import * as React from "react";

export const Location: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M12 2.833A7.167 7.167 0 0 0 4.833 10c0 2.67 1.81 5.468 3.79 7.696A30.516 30.516 0 0 0 12 20.936a30.538 30.538 0 0 0 3.377-3.24c1.981-2.228 3.79-5.025 3.79-7.696A7.167 7.167 0 0 0 12 2.833ZM12 22l-.5.666h-.001l-.002-.001-.005-.005-.02-.015a29.265 29.265 0 0 1-1.27-1.05 32.18 32.18 0 0 1-2.825-2.791c-2.019-2.272-4.21-5.475-4.21-8.804a8.833 8.833 0 0 1 17.666 0c0 3.33-2.19 6.532-4.21 8.804A32.2 32.2 0 0 1 12.6 22.59a8.866 8.866 0 0 1-.072.055l-.02.015-.005.005H12.5v.001L12 22Zm0 0 .5.667a.833.833 0 0 1-1 0L12 22Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M12 7.833a2.167 2.167 0 1 0 0 4.334 2.167 2.167 0 0 0 0-4.334ZM8.167 10a3.833 3.833 0 1 1 7.666 0 3.833 3.833 0 0 1-7.666 0Z"
      clipRule="evenodd"
    />
  </svg>
);
