import { Radio, FormControlLabel } from "@mui/material";
import { styled } from "@mui/system";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiRadio-root": {
    color: "#E5E9F3",
  },
  "& .MuiRadio-root.Mui-checked": {
    color: "#284492",
  },
  fontSize: "14px",
  lineHeight: "130%",
  // color: '#284492'
}));

const BaseRadio = (props: any) => {
  return <StyledFormControlLabel control={<Radio />} {...props} />;
};

export default BaseRadio;
