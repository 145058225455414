import { parcelTypesHe, EnvelopeSizes } from "../../../data/constants";
import { defaultLetters } from "../../../data/constants";

export const generateParcelFieldParameters = (parcelType: string) => {
  // console.log("=generateParcelFieldParameters=", parcelType);

  let fieldParams: any;

  if (parcelType === parcelTypesHe[0].value) {
    fieldParams = {
      id: Date.now(),
      ...defaultLetters,
      envelopeSize: EnvelopeSizes._default,
    };
  } else {
    fieldParams = {
      id: Date.now(),
      weight: "",
      height: "",
      length: "",
      width: "",
      envelopeSize: EnvelopeSizes.custom,
    };
  }

  // console.log("=generateParcelFieldParameters RETURN=", fieldParams);
  return fieldParams;
};
