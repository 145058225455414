import { setDomesticShipping } from "../../data/actions";

export const handlerReceivedShipment = (shipment: any, dispatch: any): any => {
  if (!shipment?.address_from) return shipment;
  if (!shipment?.address_to) return shipment;

  shipment.address_from?.city_name &&
    shipment.address_from?.country &&
    (shipment.address_from.description = `${shipment.address_from.city_name}, ${shipment.address_from.country}`);

  shipment.address_to?.city_name &&
    shipment.address_to?.country &&
    (shipment.address_to.description = `${shipment.address_to.city_name}, ${shipment.address_to.country}`);

  // Если Domestic Shipping, то
  if (
    shipment.address_from?.country_code &&
    shipment.address_to?.country_code &&
    shipment.address_from?.country_code === shipment.address_to?.country_code
  ) {
    dispatch(setDomesticShipping(true));

    const getDescription = (address: any): any => {
      const addNameToString = (string: string, name: any): string => {
        string.length && (string += ", ");
        string += name;

        return string;
      };

      let _description = "";

      address?.street_1 &&
        (_description = addNameToString(_description, address.street_1));
      address?.city_name &&
        (_description = addNameToString(_description, address.city_name));
      address?.state &&
        (_description = addNameToString(_description, address.state));
      address?.country &&
        (_description = addNameToString(_description, address.country));

      return _description;
    };

    shipment.address_from.description = getDescription(shipment.address_from);
    shipment.address_to.description = getDescription(shipment.address_to);
  }

  return shipment;
};
