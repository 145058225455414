import { Controller, useFormContext } from "react-hook-form";

import { BasePhoneInput } from "../../BasePhoneInput";

const HFPhoneInput = (props: any) => {
  const {
    control,
    getFieldState,
    formState: { errors },
  }: any = useFormContext();

  const errorsObj = getFieldState(props.name)?.error?.types;

  let convertedErrors: any;

  if (errorsObj) {
    convertedErrors = Object.keys(errorsObj).map((key) => errorsObj[key]);
  }

  return (
    <Controller
      control={control}
      name={props.name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <BasePhoneInput
          name={props.name}
          value={value}
          onChange={onChange}
          {...props}
          error={
            convertedErrors ||
            getFieldState(props.name)?.error?.message ||
            props.error ||
            error
          }
        />
      )}
    />
  );
};

export default HFPhoneInput;
