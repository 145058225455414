import { Grid } from "@mui/material";
import { useCallback, useState } from "react";
import MobileDemonstrationImage from "../../../../assets/images/mobile-demonstration.png";
import BrandArrowIcon from "../../../../assets/icons/brand-arrow-blue.svg";
import { HF } from "../../../../components";
import { BaseButton } from "../../../../components/BaseButton";

import styles from "./Form.scss";
import {
  StyledTitleWrapper,
  StyledTitle,
  StyledTitleIcon,
  StyledContentWrapper,
} from "./FormContactPage.styled";
import { FormProvider, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import API from "../../../../api";
import { validationSchema } from "./services/validation";
import { getErrorMessage } from "../../../../services/getErrorMessage";
import { Notify } from "../../../../services/notifications";

const Form = () => {
  const methods = useForm({
    mode: "onSubmit",
    resolver: validationSchema,
  });

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = useCallback(async () => {
    if (process.env.REACT_APP_IS_MOBILE_APP === "false" && !executeRecaptcha)
      return;

    setIsLoadingSubmit(true);

    let recaptchaToken = "fd64efe83df80c7f7b15a3976849a2e2"; // Сделано на время, пока не будет реализована КАПЧА в мобильном приложении

    try {
      if (process.env.REACT_APP_IS_MOBILE_APP === "false") {
        recaptchaToken = executeRecaptcha
          ? await executeRecaptcha("ShipperContactForm")
          : "";
      }
    } catch (error) {
      console.log("=executeRecaptcha ERROR=", error);
      Notify({ message: getErrorMessage(error), type: "error" });
    }

    if (!recaptchaToken) return;

    const content = Object.entries(methods.getValues())
      .filter(([key, value]) => value)
      .map(([key, value]) => [key, key === "phone" ? `${value}` : value])
      .map(([key, value]) => `${key} - ${value}`)
      .join(" </br> ");

    try {
      const res = await API.post(
        `${process.env.REACT_APP_EMAIL_SENDER_ENDPOINT}/emailsender.php`,
        {
          subject: "Shipper contact form",
          "g-recaptcha-response": recaptchaToken,
          content,
        },
      );
      if (res.data.success) {
        Notify({ message: "Message sent!", type: "success" });

        // TODO: Возможно есть более простой способ сбросить форму без передачи значений по-умолчанию
        // TODO: сброс телефона к +1 тоже нужно пересмотреть
        methods.reset({
          full_name: "",
          phone: "+1",
          email: "",
          company: "",
          text: "",
        });
      } else {
        Notify({ message: res.data.message, type: "error" });
      }
    } catch (error: any) {
      Notify({ message: getErrorMessage(error), type: "error" });
    }

    setIsLoadingSubmit(false);
  }, [executeRecaptcha]);

  return (
    <FormProvider {...methods}>
      <Grid container columnSpacing={{ xs: 2 }}>
        <Grid item xs={12} md={6}>
          <StyledTitleWrapper>
            <StyledTitleIcon src={BrandArrowIcon} />
            <StyledTitle>CONTACT US</StyledTitle>
          </StyledTitleWrapper>

          <StyledContentWrapper onSubmit={methods.handleSubmit(onSubmit)}>
            <HF.Input
              className={styles.input}
              name="full_name"
              label="Full Name"
              placeholder="Enter your name"
            />
            <HF.PhoneInput
              className={styles.input}
              name="phone"
              label="Phone number"
              placeholder="Enter your phone number"
            />
            <HF.Input
              className={styles.input}
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email"
            />
            <HF.Input
              className={styles.input}
              name="company"
              label="Company"
              placeholder="Enter you company"
            />
            <HF.Input
              className={styles.input}
              name="text"
              label="Text"
              placeholder="Enter a brief description of the request"
            />

            <BaseButton
              className={styles.submitBtn}
              loading={isLoadingSubmit}
              type="submit"
            >
              Connect with us
            </BaseButton>
          </StyledContentWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <img src={MobileDemonstrationImage} alt="" />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default Form;
