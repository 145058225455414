import * as React from "react";

import usePagination from "@mui/material/usePagination";
import { Box } from "@mui/material";

import { Name } from "../BaseIcon";
import {
  StyledActiveListItem,
  StyledLeftArrow,
  StyledList,
  StyledListItem,
  StyledRightArrow,
} from "./styles/BasePagination.styled";

type Props = {
  onChange(index: number | null): void;
  count: number;
  currentPage: number;
};

const BasePagination: React.FC<Props> = ({ onChange, count, currentPage }) => {
  const { items } = usePagination({
    count,
    page: currentPage,
  });

  const isLeftArrowBtnDisabled = currentPage - 1 === 0;
  const isRightArrowBtnDisabled = currentPage === count;

  return (
    <nav>
      <StyledList>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <StyledActiveListItem selected={selected} {...item}>
                {page}
              </StyledActiveListItem>
            );
          } else {
            children = (
              <Box {...item}>
                {type === "previous" ? (
                  <StyledLeftArrow
                    onClick={() => onChange(currentPage - 1)}
                    disabled={isLeftArrowBtnDisabled}
                    name={Name.LeftSmallArrow}
                    isButton
                  />
                ) : (
                  <StyledRightArrow
                    onClick={() => onChange(currentPage + 1)}
                    disabled={isRightArrowBtnDisabled}
                    name={Name.LeftSmallArrow}
                    isButton
                  />
                )}
              </Box>
            );
          }

          return (
            <StyledListItem onClick={() => onChange(page)} key={index}>
              {children}
            </StyledListItem>
          );
        })}
      </StyledList>
    </nav>
  );
};

export default BasePagination;
