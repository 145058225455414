import { Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { BaseCard } from "../../components/BaseCard";
import { BaseIcon, Name } from "../../components/BaseIcon";
import { BasePage } from "../../components/BasePage";
import { Link } from "react-scroll";

import styles from "./TextPage.scss";

const StyledSidebarTitle = styled("div")(
  () => `
   font-weight: 600;
   font-size: 16px;
   line-height: 22px;
   color: #284492;
   margin-left: 10px;
 `,
);

const StyledSection = styled("section")(
  () => `
  margin-bottom: 32px;
 `,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #284492;
  margin-bottom: 16px;
 `,
);

const StyledSectionTitle = styled("div")(
  () => `
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #284492;
  margin-bottom: 16px;
 `,
);

const StyledContent = styled("div")(
  () => `
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #19306F;
  opacity: 0.7;
 `,
);

const StyledSidebarWrapper = styled(Link)(
  () => `
  display: flex;
  align-items: center;
  text-decoration: none;

  &:not(:last-child) {
   margin-bottom: 24px;
  }
 `,
);

const TextPage = (props: any) => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");

  return (
    <BasePage paddingTop="96px">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7} lg={8}>
            <StyledTitle>{props.title}</StyledTitle>

            {props.sections.map((section: any) => (
              <StyledSection id={section.id} key={section.id}>
                <StyledSectionTitle>{section.title}</StyledSectionTitle>
                <StyledContent
                  dangerouslySetInnerHTML={{ __html: section.content }}
                />
              </StyledSection>
            ))}
          </Grid>

          <Grid item xs={0} md={5} lg={4} className={styles.rightPanelWrap}>
            <BaseCard className={styles.rightPanel} withShadow padding="40px">
              {props.sections.map((section: any) => (
                <>
                  {!!section.title && (
                    <StyledSidebarWrapper
                      activeClass={styles.active}
                      to={section.id}
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-96}
                      key={section.id}
                    >
                      <BaseIcon
                        className={styles.titleIcon}
                        name={Name.WhiteRhombusInRedCircle}
                        size={20}
                      />

                      <StyledSidebarTitle>{section.title}</StyledSidebarTitle>
                    </StyledSidebarWrapper>
                  )}
                </>
              ))}
            </BaseCard>
          </Grid>
        </Grid>
      </Container>
    </BasePage>
  );
};

export default TextPage;
