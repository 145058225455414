import React, { useState } from "react";

import { BaseCard } from "../../../../../../../../../../components/BaseCard";
import { BaseButton } from "../../../../../../../../../../components/BaseButton";
import { StyledCardTitle } from "../../styles/RightPanels.styled";
import {
  StyledCoupon,
  StyledCouponWrapper,
  StyledHeaderPromoCode,
  StyledSpinner,
} from "./styles/PromoCode.styled";

import rightPanelStyles from "../../RightPanels.scss";
import { BaseInput } from "../../../../../../../../../../components/BaseInput";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCoupon,
  sendCoupon,
} from "../../../../../../../../../../data/actions";
import { selectCouponLoading } from "../../../../../../../../../../data/selectors";

import styles from "./styles/PromoCode.scss";
import {
  BaseIcon,
  Name,
} from "../../../../../../../../../../components/BaseIcon";

type Props = {
  shipmentId: string;
  coupon_code?: string | null;
  onChange(isLoading: boolean): void;
};

const PromoCode: React.FC<Props> = (props) => {
  const [isAddingCouponCode, setIsAddingCouponCode] = useState(
    !!props.coupon_code,
  );
  const [couponError, setCouponError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector(selectCouponLoading);

  const handleHideCouponInput = (): void => {
    setIsAddingCouponCode(!isAddingCouponCode);
    setCouponCode("");
  };

  const handleDeleteCoupon = (): void => {
    props.onChange(true);

    new Promise((resolve, reject) => {
      dispatch(
        deleteCoupon({
          shipmentId: props.shipmentId,
          resolve,
        }),
      );
    }).finally(() => {
      props.onChange(false);
    });
  };

  const handleSendCoupon = (): void => {
    props.onChange(true);

    new Promise((resolve, reject) => {
      dispatch(
        sendCoupon({
          shipmentId: props.shipmentId,
          code: couponCode,
          resolve,
          reject,
        }),
      );
    })
      .then(() => {
        setCouponError("");
      })
      .catch((error) => {
        setCouponError(error.message);
      })
      .finally(() => {
        props.onChange(false);
      });
  };

  return (
    <BaseCard className={rightPanelStyles.summaryCard} padding="24px">
      <StyledHeaderPromoCode>
        <StyledCardTitle marginBottom={props.coupon_code ? undefined : "0px"}>
          Promo code:
        </StyledCardTitle>

        {!props.coupon_code && (
          <BaseButton
            className={styles.addBtn}
            label={isAddingCouponCode ? "Close" : "Add"}
            clear
            onClick={handleHideCouponInput}
          />
        )}
      </StyledHeaderPromoCode>

      {props.coupon_code ? (
        <StyledCouponWrapper>
          <StyledCoupon>{props.coupon_code}</StyledCoupon>

          {loading ? (
            <StyledSpinner />
          ) : (
            <BaseIcon onClick={handleDeleteCoupon} name={Name.Trash} isButton />
          )}
        </StyledCouponWrapper>
      ) : (
        isAddingCouponCode && (
          <>
            <BaseInput
              className={styles.couponInput}
              name="couponCode"
              value={couponCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCouponCode(e.target.value)
              }
              error={couponError}
              placeholder="Promo code"
            />

            <BaseButton
              onClick={handleSendCoupon}
              label="Apply"
              loading={loading}
              disabled={!couponCode}
              rightIcon={<></>}
              size="sm"
              fullWidth
            />
          </>
        )
      )}
    </BaseCard>
  );
};

export default PromoCode;
