import { useEffect, useState, useRef } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DevTool } from "@hookform/devtools";

import { BaseModal } from "../../../../components/BaseModal";
import {
  GetBestPricePopup,
  checkTurkeyAndIsrael,
} from "../../../../components/GetBestPricePopup/GetBestPricePopup";
import { handleAllowedGetBestPricePopup } from "../../../../components/GetBestPricePopup/handleAllowedGetBestPricePopup";
import { ErrorModalContent } from "./components/ErrorModalContent";

import { useIsResponsive } from "../../../../services/breakpoint/useIsResponsive";
import { clearCheckoutLocalStorageState } from "../../../checkout/services/clearCheckoutLocalStorageState";
import { validationSchema } from "./services/validation";
import {
  getDefaultFormValuesHome,
  generateDomesticCountryCode,
  generateLocationFrom,
} from "./services/getDefaultFormValuesHome";
import { getConvertedFormValues } from "../../../home/components/Form/services/getConvertedFormValues";
import { ShipmentAPI } from "../../../../api";
import { generateParcelFieldParameters } from "./services/generateParcelFieldParameters";
// import parcelEventInfobip from "../../../../plugins/infobip/parcelEventInfobip";

import { COUNTRIES_DO_NOT_HAVE_TO_MATCH } from "../../data/constants";

import { MobileForm } from "./components/MobileForm";
import { DesktopForm } from "./components/DesktopForm";
import { StyledForm } from "./components/Form.styled";

import "./styles/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUnitSystem } from "../../../../data/selectors";
import { changeUnitSystem } from "../../../../data/actions";
import { selectIsDomesticShipping } from "../../../../data/selectors";
import { selectUser } from "../../../../data/selectors";
import { blockedCountriesCodes } from "../../../../constants";
import { submitToGoogleAnalyticsGetQuote } from "./submitToGoogleAnalytics.Home";
// import getQuoteEventInfobip from "../../../../plugins/infobip/getQuoteEventInfobip";

export const maximumNumberOfItemsInParcel = 50;

let isTurkeyAndIsrael = false;
let isAllowedGetBestPricePopup = false;

const Form = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useIsResponsive();
  const user = useSelector(selectUser);
  const defaultValues = getDefaultFormValuesHome(props.shipment, user);
  const unitSystem = useSelector(selectUnitSystem);
  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: validationSchema({ unitSystem }),
  });

  const mobileFormRef = useRef<any>();
  const [isShowGetBestPricePopup, setIsShowGetBestPricePopup] = useState(false);
  const [isGoToTabZeroMobile, setIsGoToTabZeroMobile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressFromInputError, setAddressFromInputError] = useState("");
  const [addressToInputError, setAddressToInputError] = useState("");
  const dispatch = useDispatch();

  const handleCloseErrorModal = (): void => {
    // console.log("=mobileFormRef=", mobileFormRef.current, isGoToTabZeroMobile);
    isMobile && isGoToTabZeroMobile && mobileFormRef.current.setCurrentTab(0);

    setShowErrorModal(false);
  };

  const onSubmit = async (): Promise<void> => {
    // process.env.REACT_APP_INFOBIP && getQuoteEventInfobip(null);

    if (isAllowedGetBestPricePopup && isTurkeyAndIsrael) {
      setIsShowGetBestPricePopup(true);
      return;
    }

    if (isDomesticShipping) {
      methods.getValues("locationFrom.country_code") ||
        methods.setValue(
          "locationFrom.country_code",
          methods.getValues("domesticCountryCode")
        );
      methods.getValues("locationTo.country_code") ||
        methods.setValue(
          "locationTo.country_code",
          methods.getValues("domesticCountryCode")
        );
    }

    const isAddressFromContainsUnsupportedCountry =
      blockedCountriesCodes.includes(
        methods.getValues("locationFrom.country_code").toUpperCase()
      );
    const isAddressToContainsUnsupportedCountry =
      blockedCountriesCodes.includes(
        methods.getValues("locationTo.country_code").toUpperCase()
      );

    if (
      isAddressFromContainsUnsupportedCountry ||
      isAddressToContainsUnsupportedCountry
    ) {
      setShowErrorModal(true);
      return;
    }

    setLoading(true);

    clearCheckoutLocalStorageState();

    try {
      const response = await ShipmentAPI.createShipment(
        getConvertedFormValues(methods)
      );

      // console.log("=response=", response);

      if ("error" in response) {
        let _errorMessage;

        if (response.error?.details) {
          _errorMessage = Object.keys(response.error.details)
            .map((path) => {
              // console.log("=path=", path, path.endsWith('city_name'));
              path.endsWith("city_name") && setIsGoToTabZeroMobile(true);

              if (!path.startsWith("parcels."))
                return response.error.details[path].join("<br />");

              // ! Если ошибка в поле габаритов или веса, то в сообщении ошибки заменяем путь к полю (пример: "parcels.0.length") на понятный пользователю
              const _pathArray = path.split(".");
              _pathArray[1] = ` item ${+_pathArray[1] + 1} `;

              const newPath = _pathArray.join("");

              return response.error.details[path]
                .join("<br />")
                .replace(new RegExp(path, "g"), newPath);
            })
            .join("<br />");
        } else if (response.error?.message) {
          _errorMessage = response.error.message;
        }

        // console.log("=_errorMessage=", _errorMessage);

        setErrorMessage(_errorMessage);
        setShowErrorModal(true);
      } else {
        process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
          submitToGoogleAnalyticsGetQuote(
            methods.getValues(),
            isDomesticShipping
          );

        navigate(`/shipments/${response.object_id}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const checkCountryCodeWhenSelectingInAddressField = (
    addressDirection: string
  ): boolean => {
    // console.log("==checkCountryCodeWhenSelectingInAddressField", methods.watch(`${addressDirection}.city_name`), methods.watch(`${addressDirection}.country_code`), methods.watch(`${addressDirection}.fullAddress`));
    const _check =
      (!methods.watch(`${addressDirection}.city_name`) ||
        !methods.watch(`${addressDirection}.country_code`)) &&
      methods.watch(`${addressDirection}.fullAddress`);
    // console.log("==checkCountryCodeWhenSelectingInAddressField _check=", _check);

    if (_check) {
      const _getErrorMessage = isDomesticShipping
        ? "No country identified. Please choose a different address."
        : "No country identified. Please choose a different city.";

      addressDirection === "locationFrom"
        ? setAddressFromInputError(_getErrorMessage)
        : setAddressToInputError(_getErrorMessage);
      return true;
    }

    return false;
  };

  useEffect(() => {
    isTurkeyAndIsrael = checkTurkeyAndIsrael(methods.getValues());

    if (checkCountryCodeWhenSelectingInAddressField("locationFrom")) return;

    if (
      methods.watch("locationFrom.country_code") &&
      !isDomesticShipping &&
      methods.watch("locationFrom.country_code") ===
        methods.watch("locationTo.country_code")
    ) {
      setAddressFromInputError(COUNTRIES_DO_NOT_HAVE_TO_MATCH);
    } else {
      setAddressFromInputError("");
    }

    if (
      methods.watch("locationFrom.country_code") &&
      (methods.watch("locationFrom.country_code") === "US" ||
        methods.watch("locationFrom.country_code") === "AU" ||
        methods.watch("locationFrom.country_code") === "CA") &&
      !methods.watch("locationFrom.state")
    ) {
      setAddressFromInputError("This country is not supported");
    }
  }, [methods.watch("locationFrom"), isDomesticShipping]);

  useEffect(() => {
    // console.log("=useEffect=", methods.watch(`locationTo`), methods.watch('locationTo.country_code'));
    isTurkeyAndIsrael = checkTurkeyAndIsrael(methods.getValues());

    if (checkCountryCodeWhenSelectingInAddressField("locationTo")) return;

    // console.log("=useEffect ELSE=");

    if (
      methods.watch("locationTo.country_code") &&
      !isDomesticShipping &&
      methods.watch("locationFrom.country_code") ===
        methods.watch("locationTo.country_code")
    ) {
      setAddressToInputError(COUNTRIES_DO_NOT_HAVE_TO_MATCH);
    } else {
      setAddressToInputError("");
    }

    if (
      methods.watch("locationTo.country_code") &&
      (methods.watch("locationTo.country_code") === "US" ||
        methods.watch("locationTo.country_code") === "AU" ||
        methods.watch("locationTo.country_code") === "CA") &&
      !methods.watch("locationTo.state")
    ) {
      setAddressFromInputError("This country is not supported");
    }
  }, [methods.watch("locationTo"), isDomesticShipping]);

  useEffect(() => {
    dispatch(changeUnitSystem(methods.watch("unit_of_measurement")));
  }, [methods.watch("unit_of_measurement")]);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "packages",
  });

  const handleAddCount = (): void => {
    const packages = methods.getValues("packages");

    if (packages.length > maximumNumberOfItemsInParcel) return;

    const lastPackage = packages.at(-1);

    const par = {
      ...generateParcelFieldParameters(methods.getValues("parcelType")),
      height: lastPackage.height,
      length: lastPackage.length,
      weight: lastPackage.weight,
      width: lastPackage.width,
    };

    // console.log("=handleAddCount=", par, lastPackage);

    append({ id: Date.now(), ...par });

    // process.env.REACT_APP_INFOBIP && parcelEventInfobip(fields.length + 1);
  };

  const handleRemovePackage = (index: number): void => {
    // console.log("=handleRemovePackage=", index);
    remove(index);

    // process.env.REACT_APP_INFOBIP && parcelEventInfobip(fields.length - 1);
  };

  const getSearchOptions = () => {
    if (isDomesticShipping)
      return {
        types: ["address"],
        componentRestrictions: {
          country: methods.getValues("domesticCountryCode") || "",
        },
      };

    return {
      types: ["(cities)"],
    };
  };

  const getIsAddressFieldsDisabled = () => {
    return isDomesticShipping && !methods.getValues("domesticCountryCode");
  };

  const [searchOptions, setSearchOptions] = useState(getSearchOptions());
  const [isAddressFieldsDisabled, setIsAddressFieldsDisabled] = useState(
    getIsAddressFieldsDisabled()
  );

  useEffect(() => {
    setSearchOptions(getSearchOptions());
    setIsAddressFieldsDisabled(getIsAddressFieldsDisabled());
  }, [isDomesticShipping, methods.watch("domesticCountryCode")]);

  const handleSwitchingBetweenInternationalAndDomestic = () => {
    methods.setValue(
      "domesticCountryCode",
      generateDomesticCountryCode(methods.getValues(), user)
    );
    methods.setValue("locationFrom", generateLocationFrom(user));
    methods.setValue("locationTo", null);
  };

  const handleChangeCountry = (countryCode: any) => {
    methods.setValue("domesticCountryCode", countryCode?.value || null);
    methods.setValue("locationFrom", null);
    methods.setValue("locationTo", null);
  };

  const onResetErrorFrom = () => {
    // console.log("=onResetErrorFrom=", addressToInputError);
    setAddressFromInputError("");
    addressToInputError === COUNTRIES_DO_NOT_HAVE_TO_MATCH &&
      setAddressToInputError("");
  };

  const onResetErrorTo = () => {
    // console.log("=onResetErrorTo=", addressFromInputError);
    setAddressToInputError("");
    addressFromInputError === COUNTRIES_DO_NOT_HAVE_TO_MATCH &&
      setAddressFromInputError("");
  };

  useEffect(() => {
    (async () => {
      try {
        isAllowedGetBestPricePopup =
          (await handleAllowedGetBestPricePopup()) || false;
      } catch (error) {
        console.log("=Request failed=", error);
      }
    })();
  }, []);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        {isMobile ? (
          <MobileForm
            ref={mobileFormRef}
            count={fields.length}
            searchOptions={searchOptions}
            onChangeCounterAdd={handleAddCount}
            onSwitchingBetweenInternationalAndDomestic={
              handleSwitchingBetweenInternationalAndDomestic
            }
            onChangeCountry={handleChangeCountry}
            addressFromInputError={addressFromInputError}
            addressToInputError={addressToInputError}
            loading={loading}
            fields={fields}
            remove={handleRemovePackage}
            isAddressFieldsDisabled={isAddressFieldsDisabled}
            resetErrorFrom={onResetErrorFrom}
            resetErrorTo={onResetErrorTo}
          />
        ) : (
          <DesktopForm
            count={fields.length}
            searchOptions={searchOptions}
            onChangeCounterAdd={handleAddCount}
            onSwitchingBetweenInternationalAndDomestic={
              handleSwitchingBetweenInternationalAndDomestic
            }
            onChangeCountry={handleChangeCountry}
            addressFromInputError={addressFromInputError}
            loading={loading}
            addressToInputError={addressToInputError}
            fields={fields}
            remove={handleRemovePackage}
            isAddressFieldsDisabled={isAddressFieldsDisabled}
            resetErrorFrom={onResetErrorFrom}
            resetErrorTo={onResetErrorTo}
          />
        )}

        <BaseModal open={showErrorModal} onClose={handleCloseErrorModal}>
          <ErrorModalContent
            errorMessage={errorMessage}
            onClose={handleCloseErrorModal}
          />
        </BaseModal>

        <GetBestPricePopup
          open={isShowGetBestPricePopup}
          shipment={methods.getValues()}
          onModalHide={() => setIsShowGetBestPricePopup(false)}
        />

        <DevTool control={methods?.control} />
      </StyledForm>
    </FormProvider>
  );
};

export default Form;
