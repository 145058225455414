import * as React from "react";

export const Facebook: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5ZM12.8125 7C10.6385 7 10.6875 8.6855 10.6875 8.9375L10.6875 10.3125L9.6875 10.3125L9.6875 12L10.6875 12L10.6875 17L12.7812 17L12.7812 12L14.1562 12C14.1562 12 14.2808 11.1955 14.3438 10.3125L12.7812 10.3125L12.7812 9.15625C12.7812 8.98325 12.9948 8.75 13.2188 8.75L14.3438 8.75L14.3438 7L12.8125 7Z"
      fill="#7f90b1"
    />
  </svg>
);
