import { styled } from "@mui/system";

export const StyledLogo = styled("img")`
  width: 200px;
  margin-bottom: 40px;
`;

export const StyledDesktopBlock = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* @media (max-width: 991px) {
    display: none;
  } */
`;

export const StyledInfoText = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #284492;
  margin-bottom: 16px;
`;

export const StyledOrderIdText = styled("div")`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1fa5ad;
  margin-bottom: 16px;
  text-align: center;

  span {
    margin-left: 8px;
    text-transform: uppercase;
  }
`;

export const StyledHelpText = styled("div")`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #284492;
  margin-bottom: 16px;
  text-align: center;

  @media (max-width: 991px) {
    text-align: center;
  }
`;

export const StyledErrorText = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 16px;
  color: #ff5c5c;

  @media (max-width: 991px) {
    text-align: center;
  }
`;

export const StyledDocumentsList = styled("ul")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledDocumentsListItem = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledDocumentsListItemText = styled("div")`
  line-height: 150%;
  color: #284492;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

export const StyledDocumentsListItemIcons = styled("div")`
  display: flex;
  align-items: center;
`;

export const StyledDocumentsListItemIcon = styled("img")`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const StyledSocialLinks = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
`;
