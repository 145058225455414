import API from "../../api";
import { getErrorMessage } from "../../services/getErrorMessage";
import { Notify } from "../../services/notifications";

export const deleteAddress = async (id: string) => {
  try {
    const res = await API.delete(`/address-book/addresses/${id}`);

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};
