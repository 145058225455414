import "./breakpoints.scss";

export function getUiBreakpoint() {
  const body = document.querySelector("body");
  if (!body) {
    return undefined;
  }
  return window
    .getComputedStyle(body, ":before")
    .getPropertyValue("content")
    .replace(/"/g, "");
}
