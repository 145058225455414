import { styled } from "@mui/system";
import {
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import commonVariables from "../css/variables.scss";

export const StyledTableContainer: any = styled(TableContainer)`
  border-radius: ${commonVariables.borderRadiusLg};
  border: none !important;
  box-shadow: none !important;
  padding: 20px;
`;

export const StyledTableCell = styled(TableCell)`
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  color: #a8b6d2;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 180px;
`;

export const StyledTextBlue = styled("div")`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #284492;
`;

export const StyledProviderImage = styled("img")`
  max-height: 17px;
  margin-right: 6px;
`;

export const StyledStatusDate = styled("div")`
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  color: #a8b6d2;
  margin-top: 4px;
`;

export const StyledGrayButton = styled("div")`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #a8b6d2;
  padding: 6px 10px;
  border-radius: 8px;
  background: rgba(229, 233, 243, 0.4);
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    margin-left: 4px;
  }
`;

export const StyledStatusWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

export const StyledTableRow = styled(TableRow)`
  transition: all 0.3s ease-in;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:hover::after {
    content: "";
    width: 100%;
    z-index: -1;
    height: calc(100% - 20px);
    margin-bottom: 10px !important;
    position: absolute;
    left: 0;
    top: 10px;
    background: rgba(229, 233, 243, 0.4);
    display: inline-block;
    border-radius: 10px;
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    enterTouchDelay={0}
    // arrow
    // open
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "8px",
    borderRadius: "8px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
}));
