import { useSelector } from "react-redux";
import { selectLocale } from "../../data/selectors";
import HappySmiley from "../../assets/home-page/happy-smiley.png";
import styles from "./AfterSendingGetBestPricePopup.scss";

const translations: any = {
  title: {
    en: "Thank you!",
    he: "תודה שפנית אלינו!",
    tr: "Thank you!",
  },
  subTitle: {
    en: "We will contact you within a few minutes.",
    he: "ניצור איתך קשר תוך מספר דקות.",
    tr: "We will contact you within a few minutes.",
  },
};

export const AfterSendingGetBestPricePopup = () => {
  const locale = useSelector(selectLocale);

  return (
    <div className={styles.root}>
      <img className={styles.HappySmiley} src={HappySmiley} />

      <div className={styles.title}>{translations.title[locale]}</div>

      <div className={styles.subTitle}>{translations.subTitle[locale]}</div>
    </div>
  );
};
