import { createAction } from "redux-actions";
import { REDUCER_NAME } from "./constants";

export const setShipment = createAction(`${REDUCER_NAME}/SET_SHIPMENT`);

export const updateShipment = createAction(`${REDUCER_NAME}/UPDATE_SHIPMENT`);

export const saveAddressForm = createAction(
  `${REDUCER_NAME}/SAVE_ADDRESS_FORM`,
);

export const savePickupDetailsForm = createAction(
  `${REDUCER_NAME}/SAVE_PICKUP_DETAILS_FORM`,
);

export const setAddressFromFormErrors = createAction(
  `${REDUCER_NAME}/SET_ADDRESS_FROM_FORM_ERRORS`,
);

export const setAddressToFormErrors = createAction(
  `${REDUCER_NAME}/SET_ADDRESS_TO_FORM_ERRORS`,
);

export const setBillingAddressFormErrors = createAction(
  `${REDUCER_NAME}/SET_BILLING_ADDRESS_FORM_ERRORS`,
);

export const makeTransaction = createAction(`${REDUCER_NAME}/MAKE_TRANSACTION`);

export const setServices = createAction(`${REDUCER_NAME}/SET_SARVICES`);

export const setHasSkippedValidationToAddressFrom = createAction(
  `${REDUCER_NAME}/SET_HAS_SKIPPED_VALIDATION_TO_ADDRESS_FORM`,
);

export const setHasSkippedValidationToAddressTo = createAction(
  `${REDUCER_NAME}/SET_HAS_SKIPPED_VALIDATION_TO_ADDRESS_TO`,
);

export const setHasSkippedValidationToBillingAddress = createAction(
  `${REDUCER_NAME}/SET_HAS_SKIPPED_VALIDATION_TO_BILLING_ADDRESS`,
);

export const setIsShowPaymentBtn = createAction(
  `${REDUCER_NAME}/SET_IS_SHOW_PAYMENT_BTN`,
);

export const setCheckoutLoading = createAction(
  `${REDUCER_NAME}/SET_CHECKOUT_LOADING`,
);
