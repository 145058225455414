import { format } from "date-fns";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../../components/BaseIcon";
import styles from "./EstimatedDeliveryPriceCard.scss";
import { StyledTooltip } from "../../../../../../components/TooltipBase.styled";

export const EstimatedDeliveryPriceCard = ({ className, rate }: any) => {
  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  const estimatedDeliveryString = rate.estimated_date
    ? format(Date.parse(rate.estimated_date), "d MMM")
    : "No date";

  let pickupLabel = "";

  const pickupAvailableDate = new Date(rate.pickup_available_date);
  // console.log("=rate.pickup_available_date=", rate.pickup_available_date, pickupAvailableDate, dayjs(rate.pickup_available_date));

  const timezoneOffsetLocalMilliseconds =
    pickupAvailableDate.getTimezoneOffset();
  // console.log("=timezoneOffsetLocalMilliseconds=", timezoneOffsetLocalMilliseconds);

  const timezoneOffsetHours =
    +rate.timezone_offset.slice(0, 3) * 60 * 60 * 1_000;
  const timezoneOffsetMinutes = +rate.timezone_offset.slice(4, 6) * 60 * 1_000;
  const timezoneOffsetMilliseconds =
    timezoneOffsetHours + timezoneOffsetMinutes;
  // console.log("=rate.timezone_offset=", rate.timezone_offset, timezoneOffsetHours, timezoneOffsetMinutes, timezoneOffsetMilliseconds);

  const pickupAvailableDateMilliseconds = pickupAvailableDate.getTime();
  // console.log("=pickupAvailableDateMilliseconds=", pickupAvailableDateMilliseconds);

  const pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds =
    pickupAvailableDateMilliseconds -
    timezoneOffsetLocalMilliseconds +
    timezoneOffsetMilliseconds;
  // console.log("=pickupAvailableDateMilliseconds=", pickupAvailableDateMilliseconds);

  // console.log("=isToday=", rate.pickup_available_date, dayjs(pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds).isToday());

  // console.log("=day month=", dayjs(pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds).format('DD'), dayjs(pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds).format('MMM'));

  if (
    dayjs(pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds).isToday()
  ) {
    pickupLabel = "Today";
  } else {
    // const nextDayMillisecond = pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds - 24 * 60 * 60 * 1_000

    if (
      dayjs(
        pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds,
      ).isTomorrow()
    ) {
      pickupLabel = "Tomorrow";
    } else {
      const day = dayjs(
        pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds,
      ).format("DD");
      const month = dayjs(
        pickupAvailableDateWithTimezoneOffsetCorrectionMilliseconds,
      ).format("MMM");
      // console.log("=day month=", day, month);

      pickupLabel = `${day} ${month}`;
    }
  }

  return (
    <div
      className={`${styles["estimated-delivery-price-card"]}${className ? ` ${className}` : ""}`}
    >
      <div className={styles["estimated-delivery-price-card__section"]}>
        <div
          className={`${styles["estimated-delivery-price-card__section__title"]}`}
        >
          Earliest Pick Up
        </div>

        <div
          className={`${styles["estimated-delivery-price-card__section__value"]}`}
        >
          {pickupLabel}
        </div>
      </div>

      <div
        className={styles["estimated-delivery-price-card__section--separator"]}
      ></div>

      <div
        className={`${styles["estimated-delivery-price-card__section"]}`}
        style={{ minWidth: "50%" }}
      >
        <div
          className={`${styles["estimated-delivery-price-card__section__title"]}`}
        >
          <span style={{ marginBottom: "12px" }}>Estimated Delivery</span>

          <StyledTooltip title="Final delivery time varies due to shipment details, selected courier, actual package tendering, schedule adherence, and potential customs inspections/taxes.">
            <IconButton
              className={
                styles["estimated-delivery-price-card__section__title__icon"]
              }
              disableRipple
            >
              <BaseIcon name={Name.Info} size={20} />
            </IconButton>
          </StyledTooltip>
        </div>

        <div
          className={`${styles["estimated-delivery-price-card__section__value"]}`}
        >
          {estimatedDeliveryString}
        </div>
      </div>
    </div>
  );
};
