import React, { useState, useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import isEqual from "lodash.isequal";

import { BaseButton } from "../../../../../components/BaseButton";
import { BaseIcon, Name } from "../../../../../components/BaseIcon";
import {
  Accordion,
  AccordionCard,
  AccordionDetails,
  AccordionSummary,
} from "../Accordion";
import { FileUploader } from "./components/FileUploader";
import { PackageItem } from "./components/PackageItem/PackageItem";
import { BaseModal } from "../../../../../components/BaseModal";
import { PackageDetailsClosingModal } from "./PackageDetailsClosingModal";
import { ModalOnNextStepPackageDetailsCheckout } from "./components/ModalOnNextStepPackageDetailsCheckout";

import "../../../../../css/animations.css";

import styles from "./styles/PackageDetails.scss";
import { getCurrencyIcon } from "../../../../../services/getCurrencyIcon";
import { getConvertedWeightValue } from "../../../../../services/convertUnitSystemValues";
import { setCustomsItems } from "../../../api";
import { validationSchema } from "./services/validation";
import { getDefaultFormValues } from "./services/getDefaultFormValues";
import {
  StyledDescription,
  StyledFooterWrapper,
  StyledLabel,
  StyledLabels,
  StyledTotalLabel,
  StyledMessageCostRecalculated,
  StyledTotalValue,
} from "./styles/PackageDetails.styled";
import { useDispatch } from "react-redux";
import { updateShipment } from "../../../data/actions";
import { useSelector } from "react-redux";
import { selectShipmentUnitOfMeasurement } from "../../../data/selectors";
import { DevTool } from "@hookform/devtools";
import { selectShipment } from "../../../data/selectors";
import { submitToGoogleAnalyticsOnSubmitStep } from "../../Form/submitToGoogleAnalytics.FormCheckout";
import { CommercialInvoiceTooltipPackageDetails } from "./CommercialInvoiceTooltipPackageDetails";
import { UnitSystem } from "../../../../../services/convertUnitSystemValues";
import { getProhibitedItems } from "../../../../../services/getProhibitedItems";
import {
  MAX_WEIGHT_VALUE,
  MAX_WEIGHT_VALUE_LB,
} from "../../../../home/data/constants";
// import nextStepInCheckoutEventInfobip from "../../../../../plugins/infobip/nextStepInCheckoutEventInfobip";
// import getShortShipmentIdInfobip from "../../../../../plugins/infobip/getShortShipmentIdInfobip";

let isConfirmedByUserThatCustomsDeclarationIsCorrect = false;

const PackageDetails: React.FC<any> = (props) => {
  const shipment = useSelector(selectShipment);
  const unitSystem = useSelector(selectShipmentUnitOfMeasurement);

  const [
    isShownModalOnNextStepPackageDetailsCheckout,
    setIsShownModalOnNextStepPackageDetailsCheckout,
  ] = useState(false);
  const methods = useForm({
    defaultValues: getDefaultFormValues(props.shipment),
    // resolver: validationSchema({ unitSystem })
  });

  const formIsActive = props.expanded === props.name;
  const prohibitedItemsLink = getProhibitedItems(
    shipment?.chosen_rate?.provider?.id,
    shipment?.address_from?.country_code,
    shipment?.address_to?.country_code
  );

  const isFormChanged = () => {
    if (!shipment.customs_declaration_original?.customs_items) return false;

    const removeID = (items: any) =>
      items.map((item: any) => {
        delete item.id;
        delete item.object_id;

        item.item_weight = +item.item_weight;
        item.item_value = +item.item_value;

        return item;
      });

    const result = !isEqual(
      removeID(shipment.customs_declaration_original.customs_items),
      removeID(methods.getValues("customs_items"))
    );

    props.onFormChanged(result);

    return result;
  };

  const [showPackageDetailsClosingModal, setShowPackageDetailsClosingModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.showClosingModal.show) {
      setShowPackageDetailsClosingModal(true);
    }
  }, [props.showClosingModal]);

  const handleResetForm = () => {
    methods.reset(getDefaultFormValues(props.shipment));
    props.onClosingModal(props.showClosingModal.goToAccordion);
    setShowPackageDetailsClosingModal(false);
  };

  const handleCloseModal = () => {
    props.onClosingModal();
    setShowPackageDetailsClosingModal(false);
  };

  const disabledNextBtn = (): boolean => {
    // console.log("=disabledNextBtn=", isSownMessageOverweight);

    isFormChanged();

    if (isSownMessageOverweight) return false;

    return (
      !!methods.watch("customs_items")?.length &&
      !methods
        .watch("customs_items")
        ?.find(
          (el: any) =>
            !(
              el.description &&
              !props.getWeightItemErrorMessageInPackageDetails(
                el.item_weight
              ) &&
              el.item_value &&
              el.quantity &&
              el.origin_country_code
            )
        )
    );
  };

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: methods.control,
      name: "customs_items",
    }
  );

  const dispatch = useDispatch();

  const totalAmount = methods
    .watch("customs_items")
    ?.reduce((acc: any, currentPackage: any) => {
      if (currentPackage.quantity) {
        acc +=
          currentPackage.quantity *
          (currentPackage?.item_value ? +currentPackage.item_value : 0);
      }

      return acc;
    }, 0);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (): Promise<void> => {
    // console.log("=onSubmit=", methods.getValues("customs_items"), isConfirmedByUserThatCustomsDeclarationIsCorrect);

    if (!isConfirmedByUserThatCustomsDeclarationIsCorrect) {
      setIsShownModalOnNextStepPackageDetailsCheckout(true);

      return;
    }

    try {
      setLoading(true);

      const outgoingCustomsItems = methods
        .getValues("customs_items")
        .map((item: any) => {
          delete item.id;

          if (item.hs_code?.value) {
            item.hs_code = {
              id: item.hs_code.id,
              value: item.hs_code.value,
              category_id: item.hs_code.category_id,
            };
          }

          return item;
        });

      const res = await setCustomsItems(
        shipment.object_id,
        outgoingCustomsItems
      );

      dispatch(
        updateShipment({
          customs_declaration: { items: res },
        })
      );

      process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
        submitToGoogleAnalyticsOnSubmitStep({
          shipment,
          event: "begin_checkout",
          var_step: "Customs details",
          var_invoice: shipment.customs_declaration?.custom_invoice
            ? "Yes"
            : "No",
        });

      // process.env.REACT_APP_INFOBIP &&
      //   nextStepInCheckoutEventInfobip(
      //     "customs_details",
      //     5,
      //     getShortShipmentIdInfobip(props.shipment.object_id)
      //   );

      props.onNextStep();
    } finally {
      setLoading(false);
    }
  };

  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    shipment?.customs_declaration &&
      setFileName(
        // shipment.customs_declaration?.custom_invoice?.url
        //   ? `...${shipment.customs_declaration.custom_invoice.url.slice(-11)}`
        //   : ''
        shipment.customs_declaration.custom_invoice?.original_file_name ||
          shipment.customs_declaration.custom_invoice?.file_name ||
          ""
      );
  }, [shipment]);

  const onChangeFileInvoice = (file: any) => {
    dispatch(
      updateShipment({
        customs_declaration: {
          ...shipment.customs_declaration,
          custom_invoice: file,
        },
      })
    );
  };

  const totalWeight = Number(
    (
      methods
        .watch("customs_items")
        ?.reduce((totalWeigh: number, item: any) => {
          if (item.quantity) {
            totalWeigh +=
              item.quantity * (item?.item_weight ? +item.item_weight : 0);
          }

          return totalWeigh;
        }, 0) || 0
    ).toFixed(props.roundingRankOfItemWeightInParcelInPackageDetails)
  );

  const old_customs_items_total_weight = Number(
    (
      shipment?.customs_declaration_original?.customs_items?.reduce(
        (totalWeigh: number, item: any) => {
          totalWeigh += item.quantity * +item.item_weight;

          return totalWeigh;
        },
        0
      ) || 0
    ).toFixed(props.roundingRankOfItemWeightInParcelInPackageDetails)
  );

  const parcels_total_weight = Number(
    (
      shipment?.parcels?.reduce((totalWeigh: number, item: any) => {
        totalWeigh += +item.weight;

        return totalWeigh;
      }, 0) || 0
    ).toFixed(props.roundingRankOfItemWeightInParcelInPackageDetails)
  );

  const maxWeight = [MAX_WEIGHT_VALUE, MAX_WEIGHT_VALUE_LB][
    props.shipment.unit_of_measurement === "metric" ? 0 : 1
  ];
  const unitOfMeasurementWeight = getConvertedWeightValue({
    unitSystem: props.shipment.unit_of_measurement,
    returnOnlyUnit: true,
  });
  const isSownMessageOverweight = totalWeight > maxWeight;

  const isSownMessageCostRecalculated =
    old_customs_items_total_weight !== totalWeight &&
    ((+totalWeight).toFixed(
      props.roundingRankOfItemWeightInParcelInPackageDetails
    ) >
      (+parcels_total_weight).toFixed(
        props.roundingRankOfItemWeightInParcelInPackageDetails
      ) ||
      old_customs_items_total_weight.toFixed(
        props.roundingRankOfItemWeightInParcelInPackageDetails
      ) >
        parcels_total_weight.toFixed(
          props.roundingRankOfItemWeightInParcelInPackageDetails
        ));

  const onSelectedCountry = (index: number, selectedCountry: any) => {
    // console.log("=onSelectedCountry=", index, selectedCountry);
    methods.setValue(
      `customs_items.${index}.origin_country_code`,
      selectedCountry.value
    );
  };

  return (
    <AccordionCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={formIsActive}
            onChange={props.onChangeAccordion(props.name)}
            disabled={props.disabled}
          >
            <AccordionSummary disabled={props.disabled} expanded={formIsActive}>
              Customs details
            </AccordionSummary>

            <AccordionDetails>
              <StyledDescription>
                It is required by customs to describe package content in as much
                detail as possible. If your package contains different items,
                please describe each item separately.
                {prohibitedItemsLink && (
                  <div className={styles.messageProhibitedItems}>
                    {/* {prohibitedItemsLink} */}
                    Ensure your sent items comply with destination country
                    import regulations and avoid &thinsp;
                    <a
                      className={styles.messageProhibitedItemsLink}
                      href={prohibitedItemsLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      prohibited or restricted items
                    </a>
                    .
                  </div>
                )}
              </StyledDescription>

              <div className={styles.packageItems}>
                {fields?.map((packageName: any, index: any) => (
                  <PackageItem
                    key={packageName.id}
                    arrayName="customs_items"
                    package={methods.watch("customs_items")[index]}
                    methods={methods}
                    index={index}
                    // register={methods.register}
                    // control={methods.control}
                    unitSystem={unitSystem}
                    shipment={props.shipment}
                    change={({ fieldName, value }: any) => {
                      // console.log("=change=", fieldName, value);
                      // methods.register(fieldName)
                      methods.setValue(fieldName, value);
                    }}
                    watch={methods.watch}
                    listOptions={methods.watch("customs_items")}
                    getWeightItemErrorMessageInPackageDetails={
                      props.getWeightItemErrorMessageInPackageDetails
                    }
                    onSelectedCountry={(value: any) =>
                      onSelectedCountry(index, value)
                    }
                    onRemove={() => {
                      remove(index);
                    }}
                  />
                ))}
              </div>

              <div className={styles.listFooter}>
                <button
                  className={styles.addNewBtn}
                  onClick={() =>
                    append({
                      id: Date.now(),
                      description: "",
                      quantity: null,
                      item_weight: null,
                      item_value: null,
                      origin_country_code: methods
                        .getValues("customs_items")
                        .at(-1).origin_country_code,
                      hs_code: {
                        value: "",
                      },
                    })
                  }
                  type="button"
                >
                  <BaseIcon
                    className={styles.addAddressManuallyIcon}
                    name={Name.Plus}
                    size={12}
                  />
                  Add item
                </button>

                <StyledLabels>
                  {!!fields.length && (
                    <>
                      <StyledTotalLabel style={{ marginTop: "10px" }}>
                        Total weight:
                        <StyledTotalValue>
                          {totalWeight} {unitOfMeasurementWeight}
                        </StyledTotalValue>
                      </StyledTotalLabel>

                      <StyledTotalLabel>
                        Total value:
                        <StyledTotalValue>
                          {totalAmount}{" "}
                          {getCurrencyIcon(
                            props.shipment?.customs_declaration?.currency
                          )}
                        </StyledTotalValue>
                      </StyledTotalLabel>
                    </>
                  )}
                </StyledLabels>
              </div>

              {isSownMessageOverweight ? (
                <StyledMessageCostRecalculated>
                  Total weight exceeds the limit. Maximum weight for a single
                  package is {maxWeight} {unitOfMeasurementWeight}
                </StyledMessageCostRecalculated>
              ) : (
                isSownMessageCostRecalculated && (
                  <StyledMessageCostRecalculated>
                    {/* <BaseIcon name={Name.Info} size={20} /> */}
                    {/* <span style={{ marginLeft: '12px' }}> */}
                    Shipping cost will be recalculated after saving
                    {/* </span> */}
                  </StyledMessageCostRecalculated>
                )
              )}

              <StyledLabel>
                Commercial invoice:
                <CommercialInvoiceTooltipPackageDetails />
              </StyledLabel>

              <FileUploader
                className={styles.fileUploader}
                shipmentId={props.shipment?.object_id}
                fileName={fileName}
                onChange={onChangeFileInvoice}
              />

              <StyledFooterWrapper>
                <BaseButton
                  type="submit"
                  loading={loading}
                  disabled={!disabledNextBtn()}
                >
                  NEXT STEP
                </BaseButton>
              </StyledFooterWrapper>
            </AccordionDetails>
          </Accordion>
        </form>

        {/* <DevTool control={methods.control} /> */}
      </FormProvider>

      <BaseModal
        open={showPackageDetailsClosingModal}
        onClose={handleCloseModal}
      >
        <PackageDetailsClosingModal
          onClose={handleCloseModal}
          onReset={handleResetForm}
        />
      </BaseModal>

      <ModalOnNextStepPackageDetailsCheckout
        visible={isShownModalOnNextStepPackageDetailsCheckout}
        onSuccess={() => {
          isConfirmedByUserThatCustomsDeclarationIsCorrect = true;
          onSubmit();
          setIsShownModalOnNextStepPackageDetailsCheckout(false);
        }}
        onHide={() => {
          setIsShownModalOnNextStepPackageDetailsCheckout(false);
        }}
      />
    </AccordionCard>
  );
};

export default PackageDetails;
