import { styled } from "@mui/system";
import { Breakpoints, media } from "../../../../../../../../../css/breakpoints";

import brandColors from "../../../../../../../../../css/brandColors.scss";

export const StyledLabel = styled("div")`
  font-weight: 600;
  line-height: 100%;
  color: ${brandColors.mainBlue};
`;

export const StyledDividerLine = styled("div")`
  display: block;
  height: 1px;
  background: ${brandColors.lightestBlue};
  margin: 24px 0;
`;

export const StyledListTitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  /* @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    justify-content: space-between;
  } */

  @media (min-width: 600px) {
    margin-bottom: 24px;
  }
`;

export const StyledListTitle = styled("div")`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${brandColors.mainBlue};
  margin-right: 16px;
`;

export const StyledListDescriptionWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-bottom: 16px;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const StyledListItemLabel = styled("div")`
  color: ${brandColors.lightBlueColor};
  min-width: 150px;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    min-width: 140px;
  }
`;

export const StyledListItemValue = styled("div")`
  color: ${brandColors.mainBlue};
  max-width: 305px;
  line-height: 22px;
`;
