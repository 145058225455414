export const setFormErrors = ({ formErrors, methods, formName }: any): void => {
  formErrors?.forEach((el: any, i: any) => {
    const transformedFieldName = formName
      ? `${formName}.${el.fieldName?.split(".")?.[1]}`
      : el.fieldName;

    if (methods.watch(transformedFieldName)) {
      const transformedErrorsObj: any = {};

      for (let i = 0; i < el.errors.length; i++) {
        transformedErrorsObj[i] = el.errors[i];
      }

      methods.setError(transformedFieldName.toString(), {
        types: transformedErrorsObj,
      });
    }
  });
};
