import { ChangeEvent } from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/system";

import brandColors from "../../css/brandColors.scss";

const StyledRoot = styled("div")`
  display: flex;
  align-items: center;
`;

type StyledLabelType = {
  checked: boolean;
};

const StyledLabel: any = styled("div")`
  font-size: 14px;
  line-height: 130%;
  color: ${({ checked }: StyledLabelType) =>
    checked ? brandColors.mainBlue : brandColors.lightBlueColor} !important;
`;

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: brandColors.mainBlue,
  },
  "& .MuiSwitch-switchBase": {
    color: brandColors.mainBlue,
    // color: brandColors.lightBlueColor
  },
  "& .MuiSwitch-switchBase ~ .MuiSwitch-track": {
    backgroundColor: "#E5E9F3",
    opacity: 1,
  },
  paddingLeft: "8px",
  paddingRight: "8px",
}));

const BaseSwitch: React.FC<any> = (props) => {
  return (
    <StyledRoot className={props.className} onClick={props.onClick}>
      <StyledLabel
        onClick={() => props.onChange(false)}
        checked={!props.checked}
      >
        {props.leftSlot}
      </StyledLabel>

      <StyledSwitch
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          props.onChange(e.target.checked)
        }
      />

      <StyledLabel onClick={() => props.onChange(true)} checked={props.checked}>
        {props.rightSlot}
      </StyledLabel>
    </StyledRoot>
  );
};

export default BaseSwitch;
