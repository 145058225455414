import UnlockPowerOfGlobalShippingImg from "../../../../assets//home-page/Unlock-the-Power-of-Global-Shipping.png";
import UnlockPowerOfGlobalShippingMobileImg from "../../../../assets//home-page/Unlock-the-Power-of-Global-Shipping-Mobile.png";
import AppleIcon from "../../../../assets/logo-apple.svg";
import GooglePlayLogoIcon from "../../../../assets/google-play-logo.svg";
import QrCodeAppStores from "../../../../assets/qr-code-app-stores.png";
import AgitationBannersHomePageStyles from "./AgitationBannersHomePage.scss";
import UnlockThePowerBannerStyles from "./UnlockThePowerBanner.scss";
import { mobileVendor } from "react-device-detect";

interface UnlockThePowerBannerProps {
  isMobileBunners: boolean;
}

export const UnlockThePowerBanner = ({
  isMobileBunners,
}: UnlockThePowerBannerProps) => {
  const title = "Unlock the Power of Global Shipping";

  const appleStoreLinkArg: any = {
    href: process.env.REACT_APP_PATH_TO_APPLE_STORE_APP,
    target: "_blank",
  };

  if (mobileVendor === "Apple") {
    appleStoreLinkArg.href = `itms-apps${appleStoreLinkArg.href}`;
    appleStoreLinkArg.target = "_self";
  } else {
    appleStoreLinkArg.href = `https${appleStoreLinkArg.href}`;
  }

  const googlePlayStoreLinkArg: any = {
    href: `https${process.env.REACT_APP_PATH_TO_ANDROID_STORE_APP}`,
    target: "_blank",
  };

  return (
    <div className={AgitationBannersHomePageStyles.bannerCentering}>
      <div
        className={
          AgitationBannersHomePageStyles.contentBannerWrapper +
          ` ${UnlockThePowerBannerStyles["banner5-wrapper"]}`
        }
      >
        <div
          className={
            AgitationBannersHomePageStyles.contentBanner +
            " " +
            UnlockThePowerBannerStyles["content-banner5"]
          }
        >
          <h2>{title}</h2>

          <p>Be assured your parcels and documents reach their destination.</p>

          <div className={UnlockThePowerBannerStyles.actionBanner5}>
            {!isMobileBunners && (
              <div className={UnlockThePowerBannerStyles.qrCodeAppStores}>
                <div>
                  <img
                    src={QrCodeAppStores}
                    alt="Shipper Global app QR code app stores"
                  />
                </div>
              </div>
            )}

            <div className={UnlockThePowerBannerStyles.storeBtnsWrapper}>
              <a
                className={UnlockThePowerBannerStyles.storeBtn}
                {...appleStoreLinkArg}
              >
                <img
                  src={AppleIcon}
                  alt="Shipper Global app on the Apple Store"
                />

                <div>
                  Download on the
                  <span>App Store</span>
                </div>
              </a>

              <a
                className={UnlockThePowerBannerStyles.storeBtn}
                {...googlePlayStoreLinkArg}
              >
                <img
                  src={GooglePlayLogoIcon}
                  alt="Shipper Global app on the Apple Store"
                />

                <div>
                  GET IT ON
                  <span>Google play</span>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className={UnlockThePowerBannerStyles["img-banner5-wrapper"]}>
          <img
            className={UnlockThePowerBannerStyles["img-banner5"]}
            src={
              isMobileBunners
                ? UnlockPowerOfGlobalShippingMobileImg
                : UnlockPowerOfGlobalShippingImg
            }
            alt={title + " - Shipper Global"}
          />
        </div>
      </div>
    </div>
  );
};
