import { styled } from "@mui/system";
import React from "react";

import PlaceOrderManImage from "../../assets/images/placing-order-man.svg";

const Root = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
`,
);

const StyledImage = styled("img")(
  () => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`,
);

const StyledLoading = styled("div")(
  () => `
  --colour1: #284492;
  --colour2: #FF5C5C;
  --speed: 0.5s;

  @keyframes slide {
   from {
     background-position-x: 0;
   }

   to {
      background-position-x: 113px;
    }
  }

   min-height: 13px;
   border-radius: 10px;
   width: calc(113px * 4);
   @media only screen and (max-width: 450px) {
     width: calc(113px * 3);
   }
   @media only screen and (max-width: 350px) {
     width: calc(113px * 2);
   }
   background-color: #fff;
   background-image: repeating-linear-gradient(
     45deg,
     transparent,
     transparent 40px,
     var(--colour1) 40px,
     var(--colour1) 80px
   );

   animation: slide var(--speed) linear infinite;
   will-change: background-position;
   `,
);

const StyledText = styled("div")(
  () => `
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #284492;
    max-width: 340px;
    text-align: center;
    margin-top: 24px;
`,
);

const BaseLoading = (props: any) => {
  return (
    <Root>
      <StyledImage src={PlaceOrderManImage} alt="" />

      <StyledLoading />

      <StyledText>{props.text}</StyledText>
    </Root>
  );
};

export default BaseLoading;
