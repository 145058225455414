import { styled } from "@mui/system";

import brandColors from "../../../css/brandColors.scss";

type StyledCardProps = {
  withShadow?: boolean;
};

export const StyledCard = styled("div")<StyledCardProps>(
  ({ withShadow }) => `
 padding: 24px 24px;
 background-color: ${brandColors.whiteColor};
 box-shadow: ${withShadow ? "0px 2px 15px rgba(19, 45, 97, 0.1)" : ""};
 border-radius: 10px;
`,
);
