import { useSelector } from "react-redux";
import { BaseNewSelect } from "./BaseNewSelect";
import styles from "./CountrySelect.scss";
import {
  blockedCountriesCodes,
  defaultCountryNameHongKong,
} from "../constants";
import { getCountryName } from "../services/getCountryName";
import { selectRtl } from "../data/selectors";

const { getName, getCodes } = require("country-list");

const selectCountry = {
  he: "ממדינה",
  en: "Select country",
};

interface CountrySelectProps {
  className?: string;
  countryCode: string;
  placeholder?: string;
  bordered?: boolean;
  allowedCountriesCodes?: string[];
  required?: boolean;
  onSelectedCountry(value: any): void;
}

const generateOptionsSelect = (countryCodesProps: string[]) => {
  const filtredCountryCodes = countryCodesProps.filter(
    (countryCode) => !blockedCountriesCodes.includes(countryCode),
  );

  return filtredCountryCodes.map((isoCode) => {
    return {
      value: isoCode,
      label: getName(isoCode),
    };
  });
};

export const CountrySelect = ({
  className,
  countryCode,
  placeholder,
  bordered = false,
  allowedCountriesCodes,
  required,
  onSelectedCountry,
}: CountrySelectProps) => {
  const rtl = useSelector(selectRtl);

  const innerValue = countryCode
    ? {
        label: getCountryName(countryCode),
        value: countryCode,
      }
    : undefined;

  const optionsSelect = generateOptionsSelect(
    allowedCountriesCodes ?? getCodes(),
  );

  // === Изменяем имя страны Гонконг ===
  const hongKongIndex = optionsSelect.findIndex(
    (countryCode) => countryCode.value === "HK",
  );
  hongKongIndex > -1 &&
    (optionsSelect[hongKongIndex].label = defaultCountryNameHongKong);
  // ===END ===

  !allowedCountriesCodes &&
    optionsSelect.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
    );

  const innerPlaceholder =
    placeholder ?? `${selectCountry[rtl ? "he" : "en"]}${required ? " *" : ""}`;

  return (
    <BaseNewSelect
      className={`${styles.CountrySelect} ${className}`}
      options={optionsSelect}
      value={innerValue}
      placeholder={innerPlaceholder}
      bordered={bordered}
      label={selectCountry[rtl ? "he" : "en"]}
      withoutHover
      onChange={onSelectedCountry}
    />
  );
};
