export const getErrorMessage = (error: any) => {
  // console.log("=getErrorMessage=", error);

  if (error?.details?.length) {
    const convertedDetailsArray = Object.entries(error?.details).map((el) => ({
      fieldName: el[0],
      errors: el[1],
    }));

    return `
        <div style="font-weight: 600; font-size: 16px;">Validation error:</div>
        <br />

        <div>
          ${convertedDetailsArray.map(
            (el: any) =>
              `
              <span style="font-weight: 600">${el.fieldName}</span>:
              <ol style="list-style: inside; margin-bottom: 12px;">
                <br />
                ${el.errors.map(
                  (error: string) =>
                    `<li style="list-style: inside">${error}</li>`,
                )}
              </ol>
            `,
          )}
        </div>
      `;
  } else {
    return (
      error?.response?.data?.message ||
      error?.response?.message ||
      error?.message ||
      error ||
      "Something went wrong, please try later"
    );
  }
};
