import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { ParcelSizeRow } from "./ParcelSizeRow";

import { StyledTransitionGroup, StyledPackageItemRow } from "./Parcels.styled";
import { EnvelopeSizes } from "../../scenes/home/data/constants";
import { parcelTypes } from "../../scenes/home/data/constants";

export const Parcels = (props: any) => {
  const {
    getFieldState,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const handleRemove = (index: number) => {
    props.remove(index);
  };

  const [selectedEnvelopeSize, setSelectedEnvelopeSize] = useState(
    props.fields.reduce((accumulator: any, field: any) => {
      accumulator.push(field.envelopeSize);
      return accumulator;
    }, []),
  );

  useEffect(() => {
    setSelectedEnvelopeSize(
      props.fields.reduce((accumulator: any, field: any) => {
        accumulator.push(field.envelopeSize);
        return accumulator;
      }, []),
    );
  }, [props.parcelType, props.fields]);

  return (
    <StyledTransitionGroup>
      {props.fields.map((item: any, index: any) => (
        <CSSTransition key={item.id} timeout={400} classNames="item">
          <StyledPackageItemRow
            disabled={
              props.parcelType === parcelTypes[0].value &&
              (getValues(`packages.${index}.envelopeSize`) ===
                EnvelopeSizes.small ||
                getValues(`packages.${index}.envelopeSize`) ===
                  EnvelopeSizes.large)
            }
          >
            <ParcelSizeRow
              index={index}
              arrayName="packages"
              onRemove={() => handleRemove(index)}
              register={props.register}
              onChange={({ fieldName, value }: any) => {
                // console.log("=onChange=", fieldName, value);
                props.onChange({ fieldName, value });
              }}
              fields={props.fields}
              parcelType={props.parcelType}
              getFieldState={getFieldState}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              unitSystem={props.unitSystem}
              onChangeEnvelopeSize={(payload: any) => {
                const _electedEnvelopeSize = [...selectedEnvelopeSize];
                _electedEnvelopeSize[payload.index] = payload.value;
                setSelectedEnvelopeSize(_electedEnvelopeSize);
              }}
            />
          </StyledPackageItemRow>
        </CSSTransition>
      ))}
    </StyledTransitionGroup>
  );
};
