import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { BaseButton } from "../../../../../../../../components/BaseButton";
import { BaseCard } from "../../../../../../../../components/BaseCard";
import { BaseModal } from "../../../../../../../../components/BaseModal";
import { BaseSwitch } from "../../../../../../../../components/BaseSwitch";
import { selectIsLoading } from "../../../../../../../../data/selectors";
import { getCurrencyIcon } from "../../../../../../../../services/getCurrencyIcon";
import { makeTransaction, setServices } from "../../../../../../data/actions";
import {
  selectIsShowPaymentBtn,
  selectShipment,
} from "../../../../../../data/selectors";
import { ErrorModalContent } from "../ErrorModalContent";
import PromoCode from "./components/PromoCode/PromoCode";
import { submitToGoogleAnalyticsGotoPayment } from "./submitToGoogleAnalytics.CheckoutRightPanels";
import { ExportDeclarationTooltipRightPanels } from "./ExportDeclarationTooltipRightPanels";
import { ModalInsurance } from "./ModalInsurance";
import styles from "./RightPanels.scss";
import {
  StyledCardTitle,
  StyledDividerLine,
  StyledPrice,
  StyledPriceLabel,
  StyledPriceListWrapper,
  StyledSwitchBlock,
  StyledSwitchPrice,
  StyledSwitchRow,
  StyledSwitchTitle,
  StyledSwitchTitleBlock,
  StyledTotalPriceWrapper,
} from "./styles/RightPanels.styled";
import { goToVue } from "../../../../../../../../integrationWithVue/goToVue";
import { getErrorMessage } from "../../../../../../../../services/getErrorMessage";
// import { eventInfobip } from "../../../../../../../../plugins/infobip/infobip";
// import getShortShipmentIdInfobip from "../../../../../../../../plugins/infobip/getShortShipmentIdInfobip";

type Props = {
  shipmentId: string;
};

const insuranceServiceType = "insurance";

const RightPanels: React.FC<Props> = (props) => {
  const shipment = useSelector(selectShipment);
  const loading = useSelector(selectIsLoading);
  const isShowPaymentBtn = useSelector(selectIsShowPaymentBtn);
  const dispatch = useDispatch();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isShownModalInsurance, setIsShownModalInsurance] = useState(false);
  const [insurancePolicyUrl, setInsurancePolicyUrl] = useState("");
  const [isModalInsuranceInformationOnly, setIsModalInsuranceInformationOnly] =
    useState(false);
  const params = useParams();

  const handleCloseErrorModal = (): void => {
    setErrorModalOpen(false);
  };

  const [loadingTotalPrice, setLoadingTotalPrice] = useState(false);

  const loadingAvailableService =
    shipment.additional_payments?.available_services.reduce(
      (acc: any, availableService: any) => {
        acc[availableService.type] = false;

        return acc;
      },
      {}
    );

  const [loadingService, setLoadingService] = useState(
    loadingAvailableService || {}
  );

  const auxiliaryServiceMessages: any = {
    [insuranceServiceType]: (availableService: any) => (
      <div
        className={styles.auxiliaryServiceMessageInsurance}
        onClick={() => {
          // console.log("==", availableService, availableService.documents.find((document: any) => document.key === "insurance_policy").url);

          if (availableService.type === insuranceServiceType) {
            setInsurancePolicyUrl(
              availableService.documents.find(
                (document: any) => document.key === "insurance_policy"
              )?.url
            );
          }

          setIsModalInsuranceInformationOnly(true);
          handleShownModalInsurance();
        }}
      >
        (See terms and coverage)
      </div>
    ),
  };

  useEffect(() => {
    setLoadingService(loadingAvailableService);
  }, [shipment.additional_payments?.available_services]);

  const onChangeShippingService = (
    availableService: any,
    serviceValue: boolean
  ): void => {
    // console.log("=onChangeShippingService=", serviceValue);
    if (availableService.type === insuranceServiceType && serviceValue) {
      setInsurancePolicyUrl(
        availableService.documents.find(
          (document: any) => document.key === "insurance_policy"
        )?.url
      );
      handleShownModalInsurance();
      return;
    }

    handleChangeShippingService(availableService.type, serviceValue);
  };

  const handleChangeShippingService = (
    serviceType: string,
    serviceValue: boolean
  ): void => {
    setLoadingTotalPrice(true);
    loadingAvailableService[serviceType] = true;
    setLoadingService(loadingAvailableService);

    const serviceData = shipment.additional_payments?.available_services.map(
      (availableService: any) => {
        const _service = {
          name: availableService.type,
          value:
            availableService.type === serviceType
              ? serviceValue
              : availableService.enable,
        };

        return _service;
      }
    );

    new Promise((resolve, reject) => {
      dispatch(
        setServices({
          shipmentId: props.shipmentId,
          serviceData,
          resolve,
        })
      );
    }).finally(() => {
      setLoadingTotalPrice(false);
      loadingAvailableService[serviceType] = false;
      setLoadingService(loadingAvailableService);
    });
  };

  const currency = getCurrencyIcon(shipment?.additional_payments?.currency);

  const onSubmit = (): void => {
    // process.env.REACT_APP_INFOBIP &&
    //   eventInfobip("ProceedToPayment", {
    //     shipment_short_hash: getShortShipmentIdInfobip(shipment.object_id),
    //   });

    new Promise((resolve, reject) =>
      dispatch(
        makeTransaction({
          shipment_id: params.id,
          resolve,
          reject,
        })
      )
    )
      .then((res: any) => {
        // console.log("=res=", res);
        if (res?.status !== 200) {
          setErrorModalOpen(true);
        } else {
          process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
            submitToGoogleAnalyticsGotoPayment(shipment, res.data.data);

          // if (process.env.REACT_APP_IS_MOBILE_APP === 'true') {
          //   const paymentUrl = res?.data.data.payment.payment_url

          //   window.location.href = paymentUrl
          // } else {
          goToVue(`/payments/${res.data.data.payment.id}`);
          // }
        }
      })
      .catch((err) => {
        console.error("=onSubmit err=", err);

        setErrorMessage(getErrorMessage(err));
        // console.log("=errorMessage=", errorMessage);

        setErrorModalOpen(true);
      });
  };

  const onCloseModalInsurance = () => {
    handleHideModalInsurance();
    setIsModalInsuranceInformationOnly(false);
  };

  const onAcceptInsurance = () => {
    handleHideModalInsurance();
    handleChangeShippingService(insuranceServiceType, true);
  };

  const handleShownModalInsurance = () => {
    globalThis?.UserWay?.iconVisibilityOff();

    setIsShownModalInsurance(true);
  };

  const handleHideModalInsurance = () => {
    globalThis?.UserWay?.iconVisibilityOn();

    setIsShownModalInsurance(false);
  };

  // console.log("=shipment?.additional_payments=", shipment?.additional_payments);

  return (
    <>
      {shipment?.additional_payments?.is_insurance_allowed && (
        <BaseCard className={styles.insuranceCard} padding="24px">
          <StyledCardTitle>Extras:</StyledCardTitle>

          {shipment.additional_payments?.available_services.map(
            (availableService: any) => (
              <StyledSwitchRow
                checked={availableService.enable}
                key={availableService.type}
              >
                <StyledSwitchTitleBlock>
                  <StyledSwitchTitle>
                    <div>{availableService.name}</div>

                    {auxiliaryServiceMessages[availableService.type] &&
                      auxiliaryServiceMessages[availableService.type](
                        availableService
                      )}
                  </StyledSwitchTitle>
                </StyledSwitchTitleBlock>

                <StyledSwitchBlock>
                  {loadingService && loadingService[availableService.type] ? (
                    <CircularProgress color="primary" size="38px" />
                  ) : (
                    <BaseSwitch
                      checked={availableService.enable}
                      onChange={(value: any) => {
                        onChangeShippingService(availableService, value);
                      }}
                    />
                  )}

                  <StyledSwitchPrice>
                    {currency} {availableService.amount}
                  </StyledSwitchPrice>
                </StyledSwitchBlock>
              </StyledSwitchRow>
            )
          )}
        </BaseCard>
      )}

      <PromoCode
        shipmentId={props.shipmentId}
        coupon_code={shipment.coupon_code}
        onChange={(isLoading: boolean) => {
          setLoadingTotalPrice(isLoading);
        }}
      />

      <BaseCard className={styles.summaryCard} padding="24px">
        <StyledCardTitle>Summary:</StyledCardTitle>

        {shipment.additional_payments?.summary?.items.map((item: any) => (
          <StyledPriceListWrapper>
            <StyledPriceLabel>
              {item.name}

              {item.type === "export_declaration" && (
                <ExportDeclarationTooltipRightPanels />
              )}
            </StyledPriceLabel>

            <StyledPrice>
              {currency} {item.amount}
            </StyledPrice>
          </StyledPriceListWrapper>
        ))}

        {shipment.additional_payments?.summary?.discount && (
          <StyledPriceListWrapper>
            <StyledPriceLabel>
              {shipment.additional_payments.summary.discount?.name}
            </StyledPriceLabel>
            <StyledPrice>
              - {currency}{" "}
              {shipment.additional_payments.summary.discount?.amount}
            </StyledPrice>
          </StyledPriceListWrapper>
        )}

        {shipment.additional_payments?.summary?.vat && (
          <StyledPriceListWrapper>
            <StyledPriceLabel>
              {shipment.additional_payments.summary.vat?.name}
            </StyledPriceLabel>
            <StyledPrice>
              {currency} {shipment.additional_payments.summary.vat?.amount}
            </StyledPrice>
          </StyledPriceListWrapper>
        )}

        <StyledDividerLine />

        <StyledTotalPriceWrapper>
          <div>Total</div>
          {shipment.additional_payments?.summary?.total &&
          !loadingTotalPrice ? (
            <div>
              {" "}
              {currency} {shipment.additional_payments.summary.total}{" "}
            </div>
          ) : (
            <CircularProgress color="primary" size="30px" />
          )}
        </StyledTotalPriceWrapper>
      </BaseCard>

      {/* {isShowPaymentBtn && ( */}
      <BaseButton onClick={onSubmit} loading={loading} fullWidth>
        Proceed to payment
      </BaseButton>
      {/* )} */}

      <ModalInsurance
        open={isShownModalInsurance}
        informationOnly={isModalInsuranceInformationOnly}
        insurancePolicyUrl={insurancePolicyUrl}
        onClose={onCloseModalInsurance}
        onAccept={onAcceptInsurance}
      />

      <BaseModal open={errorModalOpen} onClose={handleCloseErrorModal}>
        <ErrorModalContent onClose={handleCloseErrorModal}>
          The courier company encountered an error while checking your shipment
          data.
          <br />
          <br />
          {errorMessage}
          <br />
          <br />
          Please update the data and retry. If you encounter any further issues,
          contact&ensp;
          <a
            href={process.env.REACT_APP_LINK_SHIPPER_DESK_SUPPORT}
            target="_blank"
            rel="noreferrer"
          >
            support
          </a>
          .
        </ErrorModalContent>
      </BaseModal>
    </>
  );
};

export default RightPanels;
