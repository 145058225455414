import * as React from "react";

export const BrandBox: React.FC = () => (
  <svg
    width="224"
    height="224"
    viewBox="0 0 224 224"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 149.333H74.6657V223.999H0V149.333Z" fill="white" />
    <path d="M0 74.5V0H224V224H149.333V74.5H0Z" fill="currentColor" />
  </svg>
);
