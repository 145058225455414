import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { styled } from "@mui/system";

import { BaseButton } from "../../../../components/BaseButton";
import { AdditionalSignInBlock } from "../AdditionalSignInBlock";
import { AlreadySignBlock } from "../AlreadySignBlock";
import { HF } from "../../../../components";

import { SIGN_IN_TYPE, SIGN_UP_TYPE } from "../../data/constants";
import { validationSchema } from "./services/validation";

import brandColors from "../../../../css/brandColors.scss";
import styles from "./SignInForm.scss";

const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const StyledForgotPassword = styled("div")`
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  cursor: pointer;
  color: ${brandColors.mainBlue};
  margin-right: auto;
`;

const SignInForm: React.FC<any> = ({ className, ...props }: any) => {
  const handleChangeAuthType = (): void => {
    props.onChangeAuthType(SIGN_UP_TYPE);
  };

  const methods = useForm({
    mode: "all",
    resolver: validationSchema,
  });

  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={methods.handleSubmit(props.onSubmit)}
        className={className}
      >
        <HF.Input name="email" label="E-mail" type="email" />
        <HF.Input name="password" label="Password" type="password" />

        <StyledForgotPassword onClick={() => props.onChangeTab(1)}>
          Forgot password?
        </StyledForgotPassword>

        <BaseButton
          className={styles.submitBtn}
          type="submit"
          loading={props.loading}
        >
          Sign in
        </BaseButton>

        <AlreadySignBlock
          type={SIGN_IN_TYPE}
          onChangeAuthType={() => handleChangeAuthType()}
        />

        <AdditionalSignInBlock type="sign-in" />
      </StyledForm>
    </FormProvider>
  );
};

export default SignInForm;
