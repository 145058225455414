import * as React from "react";

export const ThinClose: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#284492"
      d="M4 3.636a.9.9 0 0 1 1.273 0l15.09 15.091A.9.9 0 0 1 19.092 20L4.001 4.91a.9.9 0 0 1 0-1.274Z"
    />
    <path
      fill="#284492"
      d="M3.636 20a.9.9 0 0 1 0-1.273l15.091-15.09A.9.9 0 0 1 20 4.909L4.91 20a.9.9 0 0 1-1.274 0Z"
    />
  </svg>
);
