import { useParams, useNavigate } from "react-router";
import { IconButton } from "@mui/material";
import { BaseButton } from "../../../../../../components/BaseButton";
import { BaseIcon, Name } from "../../../../../../components/BaseIcon";
import { TopPriceCard } from "./TopPriceCard";
import { RateClassPriceCard } from "./RateClassPriceCard";
import { EstimatedDeliveryPriceCard } from "./EstimatedDeliveryPriceCard";
import styles from "./PriceCard.scss";
import { chooseShipmentRate } from "../../../../api";
import { getProviderImage } from "../../../../../../services/getRateImage";
import { useSelector } from "react-redux";
import { selectIsAuth } from "../../../../../auth/data/selectors";
import { submitToGoogleAnalyticsOnChoosingCarrier } from "./submitToGoogleAnalytics.PriceCard";
import { goToVue } from "../../../../../../integrationWithVue/goToVue";
import { StyledForm } from "./PriceCard.styled";
import { StyledTooltip } from "../../../../../../components/TooltipBase.styled";

export const PriceCard = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const isAuth = useSelector(selectIsAuth);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (isAuth) {
      await chooseShipmentRate({
        shipmentId: params.id,
        rate_id: props.rate?.object_id,
      });

      process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
        submitToGoogleAnalyticsOnChoosingCarrier(
          props.shipmentInfo,
          props.rate
        );

      navigate(`/shipments/${params.id}/checkout`);
      // goToVue(`/shipments/${params.id}/checkout`)
    } else {
      navigate(`/auth?type=sign-in&from=/shipments/${params.id}`);
    }
  };

  const isHasRateClas = props.rate.tags?.length > 0;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TopPriceCard fullWidth={!isHasRateClas} />

      {isHasRateClas && <RateClassPriceCard tags={props.rate.tags} />}

      <img
        className={styles["price-card__logo-provider"]}
        src={getProviderImage(props.rate.provider?.image_url)}
        alt={props.rate.provider.name}
      />

      <p className={styles["price-card__service-name"]}>
        {props.rate.service_level.name}
      </p>

      <p className={styles["price-card__price-title"]}>
        Starting from
        <StyledTooltip title="Prices are based on provided information and exclude duties, taxes, and extra fees. Additional charges may apply during processing or post-delivery based on shipment details or courier fees.">
          <IconButton
            style={{ color: "#0000008f", cursor: "default" }}
            disableRipple
          >
            <BaseIcon name={Name.Info} size={20} />
          </IconButton>
        </StyledTooltip>
      </p>

      <p
        className={styles["price-card__price"]}
      >{`${props.rate.currency} ${props.rate.amount}`}</p>

      <EstimatedDeliveryPriceCard
        className={styles["price-card__estimated"]}
        rate={props.rate}
      />

      <BaseButton className={styles["price-card__btn"]} type="submit">
        Quote & book
      </BaseButton>
    </StyledForm>
  );
};
