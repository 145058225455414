import { styled } from "@mui/system";
import { BaseIcon, Name } from "../../../../../components/BaseIcon";
import { parcelTypes } from "../../../data/constants";

const StyledButton = styled("button")`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #284492;
  width: auto !important;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px auto 0 auto;
`;

const StyledPlusIcon = styled(BaseIcon)(
  () => `
    stroke: #284492;
`,
);

export const AddNewMobileButton = (props: any) => {
  // console.log("=AddNewMobileButton=", props);
  const parcelTypeLabel = (
    parcelTypes.find((item) => item.value === props.parcelType)?.label ??
    parcelTypes[1].label
  ).toLocaleLowerCase();
  const label = `Add new ${parcelTypeLabel}`;

  return (
    <StyledButton
      className={props.className}
      type="button"
      onClick={props.onClick}
    >
      <StyledPlusIcon name={Name.Plus} size={14} />

      <span>{label}</span>
    </StyledButton>
  );
};
