import React from "react";

import { styled } from "@mui/system";

import { BaseLink } from "../../../../components/BaseLink";

import { Breakpoints, media } from "../../../../css/breakpoints";
import brandColors from "../../../../css/brandColors.scss";
import { SIGN_IN_TYPE } from "../../data/constants";

const StyledAlreadyRegistered = styled("div")(
  () => `
 font-weight: 400;
 font-size: 12px;
 line-height: 150%;
 color: ${brandColors.mainBlue};
 margin-top: 16px;
 text-align: center;
 width: 100%;

 @media ${media(Breakpoints.Desktop, Breakpoints.Desktop2K)} {
   display: none;
 }
`,
);

const AlreadySignBlock = (props: any) => {
  return (
    <StyledAlreadyRegistered className={props.className}>
      {props.type === SIGN_IN_TYPE
        ? "Don't have an account yet?"
        : "Already registered?"}{" "}
      <BaseLink
        onClick={props.onChangeAuthType}
        color={brandColors.error}
        withoutRedirect
      >
        {props.type === SIGN_IN_TYPE ? "Sign up" : "Sign in"}
      </BaseLink>
    </StyledAlreadyRegistered>
  );
};

export default AlreadySignBlock;
