import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FIELD_IS_REQUIRED } from "../../constants";
import {
  getMaxValidationMessage,
  getMinValidationMessage,
} from "../../services/validation";
import {
  ENG_AND_HEBREW_AND_NUMS_REG_EXP,
  FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
} from "../../data/constants";

export const validationSchemaAddressbook = yupResolver(
  yup.object().shape(
    {
      first_name: yup
        .string()
        .required(FIELD_IS_REQUIRED)
        .max(35, getMaxValidationMessage(35)),
      last_name: yup
        .string()
        .required(FIELD_IS_REQUIRED)
        .max(35, getMaxValidationMessage(35)),

      phone: yup.string().required(FIELD_IS_REQUIRED),

      passport: yup.string().when("passport", (val, schema) => {
        if (val?.length) {
          return yup
            .string()
            .required(FIELD_IS_REQUIRED)
            .max(50, getMaxValidationMessage(50));
          // .test(
          //   'check letters and numbers',
          //   FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
          //   (value: any, formValues: any): boolean =>
          //     !value.search(ENG_AND_HEBREW_AND_NUMS_REG_EXP)
          // )
        } else {
          return yup.mixed().notRequired();
        }
      }),

      company_name: yup.string().when("company_name", (val, schema) => {
        if (val?.length) {
          return yup
            .string()
            .required(FIELD_IS_REQUIRED)
            .min(3, getMinValidationMessage(3))
            .max(35, getMaxValidationMessage(35))
            .test(
              "check letters and numbers",
              FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
              (value: any, formValues: any): boolean =>
                !value.search(/^[- 0-9a-z\u0590-\u05fe]+$/i),
            );
        } else {
          return yup.mixed().notRequired();
        }
      }),

      company_ein: yup.string().when("company_ein", (val, schema) => {
        if (val?.length) {
          return yup
            .string()
            .required(FIELD_IS_REQUIRED)
            .max(50, getMaxValidationMessage(50));
          // .test(
          //   'check letters and numbers',
          //   FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
          //   (value: any, formValues: any): boolean =>
          //     !value.search(ENG_AND_HEBREW_AND_NUMS_REG_EXP)
          // )
        } else {
          return yup.mixed().notRequired();
        }
      }),

      // street_1: yup
      //   .string()
      //   .required(FIELD_IS_REQUIRED)
      //   .min(3, getMinValidationMessage(3))
      //   .max(35, getMaxValidationMessage(35)),
      street_2: yup.string().nullable().max(35, getMaxValidationMessage(35)),
      postal_code: yup
        .string()
        .required(FIELD_IS_REQUIRED)
        .max(12, getMaxValidationMessage(12)),
      instructions: yup
        .string()
        .nullable()
        .max(80, "Maximum instruction length 80 characters"),
      email: yup.string().required(FIELD_IS_REQUIRED).email(),
    },
    [
      ["company_ein", "company_ein"],
      ["passport", "passport"],
      ["company_name", "company_name"],
    ],
  ),
);
