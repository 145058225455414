import { useState } from "react";

import AuthRequests from "../../api";
import {
  socialProviders,
  socialProvidersValues,
} from "../../../../constants/SocialProviders";
import type { SocialProvidersValuesType } from "../../../../constants/SocialProviders";

import { BaseLink } from "../../../../components/BaseLink";

import AppleIcon from "../../../../assets/logo-apple.svg";
import GoogleIcon from "../../../../assets/google.svg";
import FacebookIcon from "../../../../assets/facebook.svg";

import styles from "./styles/AdditionalSignInBlock.scss";
import {
  StyledRoot,
  StyledLinesBlock,
  StyledSignUpWithText,
  StyledLine,
  StyledSocialLinkBlock,
  StyledSocialLink,
  StyledSpinner,
} from "./styles/AdditionalSignInBlock.styled";
import { goToAuthError } from "../../services/goToAuthError";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLocale } from "../../../../data/selectors";

interface SocialNetworks {
  srcIcon: string;
  name: SocialProvidersValuesType;
}

const socialNetworks = [
  {
    srcIcon: AppleIcon,
    name: socialProviders.apple,
  },
  {
    srcIcon: GoogleIcon,
    name: socialProviders.google,
  },
  {
    srcIcon: FacebookIcon,
    name: socialProviders.facebook,
  },
];

const AdditionalSignInBlock: React.FC<any> = (props) => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const objLoadingSocial = socialProvidersValues.reduce(
    (accumulator: any, provider) => {
      accumulator[provider] = false;

      return accumulator;
    },
    {},
  );

  const [loadingSocial, setLoadingSocial] = useState(objLoadingSocial);

  const handleClickSocialBtn = async (
    socialNetworkName: SocialProvidersValuesType,
  ): Promise<void> => {
    objLoadingSocial[socialNetworkName] = true;
    setLoadingSocial(objLoadingSocial);

    try {
      const response =
        await AuthRequests.getUrlToLoginViaSocial(socialNetworkName);

      const state: any = {
        platform:
          process.env.REACT_APP_IS_MOBILE_APP === "true" ? "mobile" : "browser",
      };

      if (from) {
        state.from = from;
      }

      const redirectUrl = new URL(response.url);
      redirectUrl.searchParams.append("state", JSON.stringify(state));

      response.url ? globalThis.open(redirectUrl, "_self") : goToAuthError();
    } catch (err) {
      console.log(err);
      goToAuthError();
    }
  };

  return (
    <StyledRoot className={props.className}>
      <BaseLink
        to={locale !== "en" ? `/${locale}` : "/"}
        className={styles.goToMainPage}
      >
        Go to Main page
      </BaseLink>

      <StyledLinesBlock>
        <StyledLine />
        <StyledSignUpWithText>
          {props.type === "sign-in" ? "Sign in with" : "Sign up with"}
        </StyledSignUpWithText>
        <StyledLine />
      </StyledLinesBlock>

      <StyledSocialLinkBlock>
        {socialNetworks.map((socialNetwork: SocialNetworks) => (
          <StyledSocialLink
            onClick={() => handleClickSocialBtn(socialNetwork.name)}
          >
            {loadingSocial[socialNetwork.name] ? (
              <StyledSpinner />
            ) : (
              <img src={socialNetwork.srcIcon} alt={socialNetwork.name} />
            )}
          </StyledSocialLink>
        ))}
      </StyledSocialLinkBlock>
    </StyledRoot>
  );
};

export default AdditionalSignInBlock;
