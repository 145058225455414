import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { selectIsDomesticShipping } from "../../data/selectors";
import { BaseCard } from "../BaseCard";
import BrandColors from "../../css/brandColors.scss";
import { normalizeUnicodeString } from "./normalizeUnicodeString";
import { selectLocale } from "../../data/selectors";

const StyledRoot = styled((props: any) => (
  <BaseCard {...props} padding="21px 24px" withShadow>
    {props.children}
  </BaseCard>
))(
  ({ dropdownUp }) => `
  position: absolute;
  bottom: ${dropdownUp ? "60px" : "initial"};
  top: ${dropdownUp ? "initial" : "64px"};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11111;
`,
);

const StyledOptions = styled("div")`
  height: 100%;
  width: 100%;
`;

const paddingItemDropdownPx = "16px";

const StyledOption: any = styled("div")(
  ({ isActive }: any): any => `
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${BrandColors.mainBlue};
  padding: 6px calc(${paddingItemDropdownPx}/2);
  margin-left: -${paddingItemDropdownPx};
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 4px;
  width: calc(100% + ${paddingItemDropdownPx} + ${paddingItemDropdownPx});
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${isActive ? "rgba(40, 68, 146, 0.05)" : "none"};

  &:hover {
    background: rgba(40, 68, 146, 0.05);
  }
`,
);

const StyledAuxiliaryMessage = styled("span")`
  font-size: 14px;
  color: #0000008f;
`;

const StyledSpinner = styled((props) => (
  <CircularProgress color="primary" {...props} />
))(
  () => `
`,
);

export const SearchResultsMenu = (props: any) => {
  // console.log("=SearchResultsMenu=", props);
  const locale = useSelector(selectLocale);

  const translations: any = {
    noResults: {
      en: "No results. Double-check and re-enter.",
      he: "אין תוצאות, בדקו מחדש",
      tr: "No results. Double-check and re-enter.",
    },
    isDomestic: {
      en: "Start typing to find an address...",
      he: "התחל להקליד כדי למצוא כתובת",
      tr: "Start typing to find an address...",
    },
    noDomestic: {
      en: "Start typing to find a city...",
      he: "התחל להקליד כדי למצוא עיר",
      tr: "Bir şehir bulmak için yazmaya başlayın",
    },
  };

  const reverseSuggestions = props.dropdownUp
    ? [...props.suggestions].reverse()
    : props.suggestions;
  // console.log("=reverseSuggestions=", reverseSuggestions);

  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  return (
    <StyledRoot dropdownUp={props.dropdownUp}>
      {props.loading ? (
        <StyledSpinner />
      ) : (
        <StyledOptions>
          {reverseSuggestions?.length ? (
            reverseSuggestions.map((suggestion: any) => (
              <StyledOption
                isActive={suggestion.active}
                key={suggestion.placeId}
                {...props.getSuggestionItemProps(suggestion)}
              >
                {/* {normalizeUnicodeString(suggestion.description)} */}
                {suggestion.description}
              </StyledOption>
            ))
          ) : (
            <StyledAuxiliaryMessage>
              {props.searchString
                ? translations.noResults[locale]
                : isDomesticShipping
                  ? translations.isDomestic[locale]
                  : translations.noDomestic[locale]}
            </StyledAuxiliaryMessage>
          )}
        </StyledOptions>
      )}
    </StyledRoot>
  );
};
