import { styled } from "@mui/system";
import { TransitionGroup } from "react-transition-group";
import { Breakpoints, media } from "../../css/breakpoints";

export const StyledTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  gap: calc(33px - 2 * 6px);
  margin-top: 20px;

  @media ${media(Breakpoints.Tablet)} {
    margin-top: 28px;
    gap: 20px;
  }
`;

const colorBg = "#dce0e9";

export const StyledPackageItemRow = styled("li")<{ disabled: boolean }>`
  width: 100%;
  background: ${({ disabled }) => (disabled ? colorBg : "transparent")};
  border-radius: 10px;
  padding: 6px 6px 20px 6px;
  border: 1px solid rgb(229, 233, 243);
`;
