import { useState, useEffect } from "react";
import { SelectBase } from "../../../../components/SelectBase";
import { getAddressBookList } from "../../../../api/commonRequests";

interface AddressSelectProps {
  countryCode?: string;
  cityName?: string;
  value?: string;
  refreshList?: boolean;
  onSelected(address: any): void;
}

export const AddressSelect = ({
  value,
  countryCode,
  refreshList,
  onSelected,
}: AddressSelectProps) => {
  const [addressBook, setAddressBook] = useState([]);

  let updatedAddressBook: any = [];

  useEffect(() => {
    refreshList && loadAddressBook();
  }, [refreshList]);

  const loadAddressBook = async (page: number = 1) => {
    try {
      const res = await getAddressBookList({
        page,
        countryCode,
      });

      if (res) {
        const newAddressBook: any = res.collection.map((item: any) => ({
          label: item.title,
          value: item.object_id,
          ...item,
        }));

        updatedAddressBook = [...updatedAddressBook, ...newAddressBook];

        res.meta.current_page < res.meta.last_page
          ? loadAddressBook(res.meta.current_page + 1)
          : setAddressBook(updatedAddressBook);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!!addressBook.length && (
        <SelectBase
          options={addressBook}
          value={value}
          disabled={!addressBook.length}
          label="Select address from address book"
          noOptionsText="No addresses"
          hideAutocomplete
          disableClearable
          onChange={onSelected}
        />
      )}
    </>
  );
};
