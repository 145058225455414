import * as React from "react";

import { useSnackbar, WithSnackbarProps } from "notistack";

import { Notification } from "./components/Notification";

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef: any = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

const Notify = ({ message, type }: any) => {
  // console.log("=Notify=", message, type);

  useSnackbarRef.enqueueSnackbar(message, {
    variant: type,
    persist: false,
    anchorOrigin: { horizontal: "right", vertical: "top" },
    content: (key: string | number, message: any) => (
      <Notification id={key} message={message} type={type} />
    ),
  });
};

export default Notify;
