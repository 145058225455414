import { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { uid } from "uid";
import { StyledInput } from "./BaseInput/BaseInput.styled";

interface SelectBaseProps {
  style?: CSSProperties;
  className?: string;
  options: any;
  label: string;
  value?: string;
  noOptionsText?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  blurOnSelect?: boolean;
  hideAutocomplete?: boolean;
  popupIcon?: any;
  register?: any;
  controllerProps?: any;
  isOptionEqualToValue?(option: any, value: any): boolean;
  filterOptions?(options: any, state: any): any;
  onChange(value: any): void;
  onClose?(event: React.SyntheticEvent, reason: string): void;
  onBlur?(value: any): void;
  onInputChange?(
    event: React.SyntheticEvent,
    value: string,
    reason: string,
  ): void;
}

export const SelectBase = ({
  style,
  className,
  options,
  label,
  value,
  noOptionsText,
  disabled,
  disableClearable,
  blurOnSelect,
  hideAutocomplete,
  popupIcon,
  register,
  controllerProps,
  isOptionEqualToValue,
  filterOptions,
  onChange,
  onBlur,
  onClose,
  onInputChange,
}: SelectBaseProps) => {
  // console.log("=SelectBase=", label, value);
  const [open, setOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleOnClose = (event: React.SyntheticEvent, reason: string) => {
    setOpen(false);

    onClose && onClose(event, reason);
  };

  const handleOnInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string,
  ) => {
    onInputChange && onInputChange(event, value, reason);
  };

  const handleOnFocus = () => {
    // console.log("=handleOnFocus=");

    setIsFocus(true);
  };

  const handleOnBlur = () => {
    // console.log("=handleOnBlur=");

    setIsFocus(false);
  };

  useEffect(() => {
    // console.log("=useEffect=", label, isFocus, options.length);

    if (options?.length && isFocus) return setOpen(true);
  }, [options, isFocus]);

  // const inputRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   // console.log("==", inputRef);
  //   // console.log("==", inputRef.current);
  //   // console.log("==", inputRef.current.getAttribute('autocomplete'));
  //   // inputRef?.current?.setAttribute('autocomplete', uid());
  // }, []);

  const generateInputProps = (params: any) => {
    const result = { ...params.inputProps };
    hideAutocomplete && (result.autocomplete = uid());

    return result;
  };

  return (
    <Autocomplete
      style={style}
      className={className}
      // value={innerValue}
      value={value}
      popupIcon={popupIcon}
      options={options}
      disabled={disabled}
      disableClearable={disableClearable}
      blurOnSelect={blurOnSelect}
      noOptionsText={noOptionsText}
      open={open}
      // open
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={handleOnInputChange}
      onClose={handleOnClose}
      onBlur={onBlur}
      filterOptions={filterOptions}
      onChange={(event, value, reason) => {
        // console.log("=onChange=", event, value, reason);
        onChange(value);
        setOpen(false);
        setIsFocus(false);
        // setInnerValue("")
      }}
      renderInput={(params) => (
        <StyledInput
          // inputRef={inputRef}
          {...params}
          label={label}
          {...register}
          variant="standard"
          inputProps={generateInputProps(params)}
          {...controllerProps}
          error={!!controllerProps?.fieldState?.error}
          helperText={controllerProps?.fieldState?.error?.message}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          // onChange={(event) => {
          //   // console.log("=onChange=", event);
          //   // setInnerValue(event.target.value)
          // }}
        />
      )}
    />
  );
};
