import * as React from "react";

export const Download: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#a)" fill="currentColor">
      <path d="M6.375 13.425a.375.375 0 0 1 .375.375v1.875a.75.75 0 0 0 .75.75h9a.75.75 0 0 0 .75-.75V13.8a.375.375 0 1 1 .75 0v1.875a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5V13.8a.375.375 0 0 1 .375-.375Z" />
      <path d="M11.735 14.89a.375.375 0 0 0 .531 0l2.25-2.25a.375.375 0 0 0-.531-.53l-1.61 1.61V7.125a.375.375 0 1 0-.75 0v6.595l-1.609-1.61a.376.376 0 0 0-.531.53l2.25 2.25Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="currentColor"
          transform="translate(6 6)"
          d="M0 0h12v12H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
