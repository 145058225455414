import React from "react";

import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectLocale } from "../../data/selectors";

import { BasePage } from "../../components/BasePage";
import { BaseZeroscreen } from "../../components/BaseZeroscreen";

import ErrorImage from "../../assets/images/man_error.svg";

const Error404: React.FC = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const navigate = useNavigate();

  const goHome = (): void => {
    navigate(locale !== "en" ? `/${locale}` : "/");
  };

  return (
    <BasePage>
      <BaseZeroscreen
        img={ErrorImage}
        title="404 error"
        description="This page does not exist"
        btnLabel="Go to main page"
        onClick={goHome}
      />
    </BasePage>
  );
};

export default Error404;
