export const GET_USER = "GET_USER";

export const REDUCER_NAME = "root";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong, please try later";

export const ENG_AND_HEBREW_REG_EXP = /^[-a-z\u0590-\u05fe]+$/i;

export const ENG_AND_HEBREW_AND_NUMS_REG_EXP = /^[-0-9a-z\u0590-\u05fe]+$/i;

export const FIELD_MUST_CONTAIN_ONLY_LETTERS =
  "Field must contain only letters";

export const FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_HYPHEN =
  "Field must contain only letters and hyphen";

export const FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS =
  "Field must contain only nums and letters";

export const ENTER_VALID_EMAIL = "Enter valid email";
