/* istanbul ignore file */
import * as React from "react";

import { Breakpoints } from "../../css/breakpoints";

export function useIsResponsive() {
  const [breakpoint, setBreakpoint] = React.useState<Breakpoints>(
    (window as any).getUiBreakpoint(),
  );
  const [innerWidth, setInnerWidth] = React.useState<number>(
    globalThis.innerWidth,
  );

  const isMobile = breakpoint === Breakpoints.Mobile;
  const isTablet = breakpoint === Breakpoints.Tablet;
  const isDesktop = breakpoint === Breakpoints.Desktop;
  const isDesktop2k = breakpoint === Breakpoints.Desktop2K;
  const isMobileDevice = isMobile || isTablet;

  React.useLayoutEffect(() => {
    let running = false;

    // fired on resize event
    function handleResize(event: any) {
      if (!running) {
        running = true;
        window.requestAnimationFrame(() => {
          const currentBreakpoint = (window as any).getUiBreakpoint();
          setInnerWidth(event.currentTarget.innerWidth);

          if (breakpoint !== currentBreakpoint) {
            setBreakpoint(currentBreakpoint);
          }
          running = false;
        });
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);

  return {
    innerWidth,
    breakpoint,
    isMobile,
    isTablet,
    isDesktop,
    isDesktop2k,
    isMobileDevice,
  };
}
