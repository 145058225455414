import { useParams, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { ShipmentAPI } from "../../../../api";
import { PriceCard } from "./components/PriceCard/PriceCard";
import { BasePage } from "../../../../components/BasePage";
import { BaseLoading } from "../../../../components/BaseLoading";
import { NotificationPrices } from "./NotificationPrices";
import brandColors from "../../../../css/brandColors.scss";
import { Name } from "../../../../components/BaseIcon";
import { parcelTypes } from "../../data/constants";
import { submitToGoogleAnalyticsViewItemList } from "./submitToGoogleAnalytics.Prices";
import {
  Root,
  StyledTitle,
  StyledSubtitle,
  StyledPencilIcon,
  StyledCardsWrapper,
} from "./Prices.styled";
import { useSelector } from "react-redux";
import { selectLocale } from "../../../../data/selectors";

const Prices = (props: any) => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const params = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shipmentInfo, setShipmentInfo] = useState<any>({});

  const shipmentId: string =
    "id" in params && params.id !== "undefined" && params.id !== undefined
      ? params.id
      : "";

  useEffect(() => {
    const handlePopstate = () => {
      // ! Этот код нужен, чтобы при переходе назад со страницы Рейтов (по кнопке назад в браузере), страница Home знала о созданном шипменте и в поля подставлялись его данные
      // console.log("=addEventListener popstate=");
      localStorage.setItem("shipment_id_for_home", shipmentId);
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      document.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!shipmentId) {
        setLoading(false);
        return;
      }

      try {
        const response = await ShipmentAPI.getShipment(shipmentId);
        // console.log(response)

        process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
          submitToGoogleAnalyticsViewItemList(response);

        setShipmentInfo(response);
      } catch (error) {
        navigate(locale !== "en" ? `/${locale}` : "/");
        dispatch({ type: "SET_ERROR_MODAL", payload: true });
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, navigate, shipmentId]);

  const goToHomePage = () => {
    const _locale = locale !== "en" ? `/${locale}` : "";

    navigate(shipmentId ? `${_locale}/?shipment_id=${shipmentId}` : _locale);
  };

  return (
    <BasePage>
      <Root>
        {loading ? (
          <BaseLoading text="I flew looking, another moment back with a beauty of suggestions" />
        ) : (
          <>
            {(shipmentInfo.address_from.country_code === "IL" ||
              shipmentInfo.address_to.country_code === "IL") && (
              <>
                <NotificationPrices />
              </>
            )}

            <StyledTitle>
              {shipmentInfo?.rates?.length ? (
                "Shipping options"
              ) : (
                <span>
                  There are no shipping options.{" "}
                  <a
                    href={shipmentId ? `/?shipment_id=${shipmentId}` : "/"}
                    target="_self"
                  >
                    Choose
                  </a>{" "}
                  another city
                </span>
              )}
            </StyledTitle>

            {Object.keys(shipmentInfo).length > 0 && (
              <StyledSubtitle>
                {/* <span>
                  {parcelTypes[shipmentInfo?.is_customs_declarable ? 1 : 0].label}{shipmentInfo?.parcels?.length > 1 ? 's' : ''}{' '}{shipmentInfo?.parcels?.length}<span style={{ color: brandColors.lightBlueColor }}> from</span>{' '}{shipmentInfo?.address_from?.city_name}<span style={{ color: brandColors.lightBlueColor }}> to</span>
                  {' '}{shipmentInfo?.address_to?.city_name}.<span style={{ color: brandColors.lightBlueColor }}> Total weight</span>
                  {' '}{(shipmentInfo?.parcels?.reduce((totalWeigh: number, item: any) => {
                    totalWeigh += item.weight

                    return totalWeigh
                  }, 0) || 0)
                    .toFixed(2)
                  }
                  {' '}{shipmentInfo?.unit_of_measurement === 'metric' ? 'kg' : 'lb'}
                </span> */}
                <span>
                  <span style={{ color: brandColors.lightBlueColor }}>
                    Shipping from{" "}
                  </span>
                  {shipmentInfo?.address_from?.city_name}
                  <span style={{ color: brandColors.lightBlueColor }}>
                    {" "}
                    to{" "}
                  </span>
                  {shipmentInfo?.address_to?.city_name}
                  {", "}
                  {shipmentInfo?.parcels?.length}{" "}
                  {
                    parcelTypes[
                      shipmentInfo?.is_customs_declarable
                        ? shipmentInfo?.is_pallet
                          ? 2
                          : 1
                        : 0
                    ].label
                  }
                  {shipmentInfo?.parcels?.length > 1 ? "s" : ""}
                  {", "}{" "}
                  {(
                    shipmentInfo?.parcels?.reduce(
                      (totalWeigh: number, item: any) => {
                        totalWeigh += item.weight;

                        return totalWeigh;
                      },
                      0,
                    ) || 0
                  ).toFixed(2)}{" "}
                  {shipmentInfo?.unit_of_measurement === "metric" ? "kg" : "lb"}
                </span>

                <StyledPencilIcon
                  onClick={goToHomePage}
                  name={Name.Pencil}
                  isButton
                />
              </StyledSubtitle>
            )}

            <StyledCardsWrapper>
              {shipmentInfo?.rates?.map((rate: any) => (
                <PriceCard
                  rate={rate}
                  shipmentInfo={shipmentInfo}
                  // homeFormValues={homeFormValues}
                  key={rate.object_id}
                />
              ))}
            </StyledCardsWrapper>
          </>
        )}
      </Root>
    </BasePage>
  );
};

export default Prices;
