export const getTransformedFromValuesForApi = (formValues: any) => {
  let transformedFormValues = {
    ...formValues,
    email: formValues.email,
    password: formValues.password,
    phone: `+${formValues.phone}`,
  };

  return transformedFormValues;
};
