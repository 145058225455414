import React, { useEffect } from "react";
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import type { Location } from "@remix-run/router";
import { useDispatch } from "react-redux";
import { socialProvidersValues } from "../../../../constants/SocialProviders";
import type { SocialProvidersValuesType } from "../../../../constants/SocialProviders";
import { BaseLoading } from "../../../../components/BaseLoading";
import { signUpViaSocial } from "../../data/actions";
import { goToAuthError } from "../../services/goToAuthError";
import { BaseModal } from "../../../../components/BaseModal";
import { BasePage } from "../../../../components/BasePage";
import { BaseButton } from "../../../../components/BaseButton";
import styles from "./authSocial.scss";
import { useSelector } from "react-redux";
import { selectLocale } from "../../../../data/selectors";

export const AuthSocial: React.FC = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const navigate = useNavigate();
  const location: Location = useLocation();
  const dispatch = useDispatch();
  const { provider } = useParams() as { provider: SocialProvidersValuesType };

  const [searchParams] = useSearchParams();
  const code: any = searchParams.get("code");
  const authuser: any = searchParams.get("authuser");
  const scope: any = searchParams.get("scope");
  const prompt: any = searchParams.get("prompt");
  let state: any = searchParams.get("state");

  state = JSON.parse(state) || {};

  const handleGoToApp = () => {
    state.platform = "browser";

    const params = new URLSearchParams();

    params.append("state", JSON.stringify(state));
    params.append("code", code);
    params.append("authuser", authuser);
    params.append("scope", scope);
    params.append("prompt", prompt);

    // globalThis.open(`shipperglobalapp:/${location.pathname}?${params.toString()}`, '_system')
    window.location.href = `shipperglobalapp:/${location.pathname}?${params.toString()}`;

    handleCloseModal();
  };

  // let showAppTransitionModal = false
  if (state?.platform === "mobile") {
    // showAppTransitionModal = true
    handleGoToApp();
  }

  useEffect(() => {
    if (state?.platform === "browser" || !state?.platform) {
      if (socialProvidersValues.includes(provider) && code) {
        dispatch(
          signUpViaSocial({
            provider,
            code,
            authuser: searchParams.get("authuser"),
            scope: searchParams.get("scope"),
            prompt: searchParams.get("prompt"),
            from: state?.from ?? "",
          }),
        );
      } else {
        goToAuthError();
      }
    }
  }, []);

  const handleCloseModal = () => {
    globalThis.close();
    navigate(locale !== "en" ? `/${locale}` : "/");
  };

  return (
    <>
      <BaseLoading />

      {/* <BaseModal
        open={showAppTransitionModal}
        onClose={handleCloseModal}
      >
        <h6 className={styles.authSocialModalTitle}>
          Authorization in <span style={{ textTransform: 'capitalize' }}>{provider}</span> passed
        </h6>
        <BaseButton className={styles.authSocialModalBtn} onClick={handleGoToApp}>Go to app</BaseButton>
      </BaseModal> */}
    </>
  );
};
