import React, { useEffect, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import AgitationBannersHomePageStyles from "./AgitationBannersHomePage.scss";
import DoorToDoorGlobalRtlImg from "../../../../assets//home-page/Door-to-Door-Global-rtl.png";
import DoorToDoorGlobalMobileImg from "../../../../assets//home-page/Door-to-Door-Global-Mobile.png";
import GetTheBestRtlImg from "../../../../assets//home-page/Get-the-Best-rtl.png";
import GetTheBestMobileImg from "../../../../assets//home-page/Get-the-Best-Mobile.png";
import TrackingAndNotificationsRtlImg from "../../../../assets//home-page/Tracking-and-Notifications-rtl.png";
import TrackingAndNotificationsMobileImg from "../../../../assets//home-page/Tracking-and-Notifications-Mobile.png";
import ConnectYourStoreRtlImg from "../../../../assets//home-page/Connect-Your-Store-rtl.png";
import ConnectYourStoreMobileImg from "../../../../assets//home-page/Connect-Your-Store-Mobile.png";
import GetPromoImg from "../../../../assets//home-page/Get-promo.svg";
import GetPromoMobileImg from "../../../../assets//home-page/Get-promo-Mobile.svg";
import { useIsResponsive } from "../../../../services/breakpoint/useIsResponsive";
// import * as rdd from 'react-device-detect';
import arrowRedLeft from "../../../../assets/icons/arrow-red-left.svg";
import brandArrowBlue from "../../../../assets/icons/brand-arrow-blue.svg";
import brandArrow from "../../../../assets/icons/brand-arrow.svg";
import { UnlockThePowerBanner } from "./UnlockThePowerBanner";
import { goToVue } from "../../../../integrationWithVue/goToVue";
// import getPromoEventInfobip from "../../../../plugins/infobip/getPromoEventInfobip";

export const AgitationBannersHomePage = () => {
  const { innerWidth } = useIsResponsive();

  const scheckIsMobileBunners = () => {
    // return innerWidth < 850
    return innerWidth < 1080;
  };

  const [isMobileBunners, setIsMobileBunners] = useState(
    scheckIsMobileBunners()
  );

  useEffect(() => {
    setIsMobileBunners(scheckIsMobileBunners());
  }, [innerWidth]);

  const banersFirstAlt: any = [
    {
      title: "משלוח מדלת לדלת ביותר מ-220 מדינות ברחבי העולם",
      subTitle:
        "הזמנת איסוף מכל מקום ובכל זמן. אתם תכניסו את הכתובת אנחנו נדאג שהחבילה תאסף ותצא לדרך. אין צורך בידע לוגיסטי או הבנה בשיטות המיסוי, אנחנו איתכם לאורך כל הדרך.",
      classesContent: "content-banner1",
      classesWrapper: "banner1-wrapper",
      action: (
        <button
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          onClick={() => {
            globalThis.scrollTo(0, 0);
          }}
        >
          משלוח מהיר
          <img
            src={brandArrow}
            alt=""
            style={{ transform: "rotate(180deg)" }}
          />
        </button>
      ),
    },
    {
      title: "קבלו את מחירי המשלוח הטובים ביותר, באופן מיידי",
      subTitle:
        "אצלנו תקבלו את התעריפים הנמוכים ביותר מהשותפים הבינלאומיים והמקומיים שלנו כמו DHL, FedEx, UPS, Aramex ודואר ישראל ב-220 מדינות ברחבי העולם.",
      classesContent: "content-banner2",
      classesWrapper: "banner2-wrapper",
      action: (
        <button
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          onClick={() => {
            globalThis.scrollTo(0, 0);
          }}
        >
          השוואת מחירים
          <img
            src={brandArrow}
            alt=""
            style={{ transform: "rotate(180deg)" }}
          />
        </button>
      ),
    },
    {
      title: "מעקב והתראות",
      subTitle:
        "הישארו מעודכנים בכל רגע נתון - תוכלו לעקוב אחרי המשלוח בכל שלב ושלב עד להגעתו ליעד.",
      classesContent: "content-banner3",
      classesWrapper: "banner3-wrapper",
      action: (
        <button
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          onClick={(event) => goToVue("/tracking", event)}
        >
          מעקב משלוחים
          <img
            src={brandArrow}
            alt=""
            style={{ transform: "rotate(180deg)" }}
          />
        </button>
      ),
    },
    {
      title: "חברו את החנות שלכם",
      subTitle:
        "גישה טובה יותר לשווקים גלובליים ושיפור חווית הלקוח. OMS עבור Amazon, Ebay, Etsy, Shopify  ואחרים.",
      classesContent: "content-banner4",
      classesWrapper: "banner4-wrapper",
      // action: <button
      action: (
        <a
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          href="/contacts"
          // onClick={(event) => {
          //   globalThis.open('/contacts', (event?.ctrlKey || event?.metaKey) ? '_blank' : '_self')
          // }}
        >
          צור קשר
          <img
            src={brandArrow}
            alt=""
            style={{ transform: "rotate(180deg)" }}
          />
        </a>
      ),
      // {/* </button> */}
    },
  ];

  const banersFirst: any = {
    true: [
      DoorToDoorGlobalMobileImg,
      GetTheBestMobileImg,
      TrackingAndNotificationsMobileImg,
      ConnectYourStoreMobileImg,
    ],
    false: [
      DoorToDoorGlobalRtlImg,
      GetTheBestRtlImg,
      TrackingAndNotificationsRtlImg,
      ConnectYourStoreRtlImg,
    ],
  };

  const banersLastAlt: any = [
    {
      title: "משלוח הוא חופש!",
      subTitleHtml: (
        <>
          <p>
            קבלו 10% הנחה על המשלוח הראשן!
            <br />
            לקבלת הקוד, אנא צרו איתנו קשר דרך תיבת הצ'אט שלנו 😊
          </p>
          {/* <p>Get <span className={AgitationBannersHomePageStyles.contentBanner6SubTitle }>10%</span> off for your first shipping!</p>
        <p style={{ paddingTop: '20px' }}>For a promo code, please contact us in our chat &#128522;</p>, */}
        </>
      ),
      classesContent: "content-banner6",
      classesWrapper: "banner6-wrapper",
      // action: <a
      action: (
        <button
          className={AgitationBannersHomePageStyles.getPromoBtn}
          // href='/contacts'
          onClick={(event) => {
            // process.env.REACT_APP_INFOBIP && getPromoEventInfobip("GetPromo");
            globalThis.open(
              "/contacts",
              event?.ctrlKey || event?.metaKey ? "_blank" : "_self"
            );
          }}
        >
          קבלו הנחה
          <img
            src={brandArrowBlue}
            alt=""
            style={{ transform: "rotate(180deg)" }}
          />
        </button>
      ),
      // </a>
    },
  ];

  const banersLast: any = {
    true: [GetPromoMobileImg],
    false: [GetPromoImg],
  };

  const banner = (bannerBasics: any, bannersContent: any) => (
    <div className={AgitationBannersHomePageStyles.bannerCentering}>
      {bannerBasics[`${isMobileBunners}`].map((item: any, index: number) => (
        <div
          key={index}
          className={
            AgitationBannersHomePageStyles.contentBannerWrapper +
            ("classesWrapper" in bannersContent[index]
              ? ` ${AgitationBannersHomePageStyles[bannersContent[index].classesWrapper]}`
              : "")
          }
        >
          <div
            className={
              AgitationBannersHomePageStyles.contentBanner +
              " " +
              AgitationBannersHomePageStyles[
                bannersContent[index].classesContent
              ]
            }
          >
            <h2>{bannersContent[index].title}</h2>

            {"subTitleHtml" in bannersContent[index] ? (
              bannersContent[index].subTitleHtml
            ) : (
              <p>{bannersContent[index].subTitle}</p>
            )}

            {"action" in bannersContent[index] && bannersContent[index].action}
          </div>

          <img
            key={index}
            src={item}
            alt={bannersContent[index].title + " - Shipper Global"}
          />
        </div>
      ))}
    </div>
  );

  const banersVideoSubTitle = [
    "שלחו אל כל מקום בעולם וקבלו חבילות מכל העולם!",
    "אין צורך בידע לוגיסטי או הבנה בחוקי המיסוי!",
    "ואין עוד דאגות מכך שהחבילה שלכם תלך לאיבוד בדרך!",
  ];

  const opts: YouTubeProps["opts"] = {
    height: "360",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  };

  return (
    <div>
      <h2 className={AgitationBannersHomePageStyles.title}>
        השווה כדי לחסוך בעלויות המשלוח
      </h2>

      <p className={AgitationBannersHomePageStyles.subTitle}>
        בלחיצת כפתור השוו בין נותני השירותים ובחרו את השירות המועדף עליכם.
        <br />
        תוכלו לשלוח איתנו פלטים, חבילות ומסמכים בארץ ובעולם.
      </p>

      {banner(banersFirst, banersFirstAlt)}

      <div
        className={
          AgitationBannersHomePageStyles["agitation-homepage__banners__video"]
        }
      >
        <div
          className={
            AgitationBannersHomePageStyles[
              "agitation-homepage__banners__video__content"
            ]
          }
        >
          <div
            className={
              AgitationBannersHomePageStyles[
                "agitation-homepage__banners__video__content__text-wrapper"
              ]
            }
          >
            <h2
              className={
                AgitationBannersHomePageStyles[
                  "agitation-homepage__banners__video__content__text-wrapper__title"
                ]
              }
            >
              שיפר גלובל מביאה את העולם ישירות אליכם
            </h2>

            {banersVideoSubTitle.map((item) => (
              <p
                key={item}
                className={
                  AgitationBannersHomePageStyles[
                    "agitation-homepage__banners__video__content__text-wrapper__subtitle"
                  ]
                }
              >
                <img
                  className={
                    AgitationBannersHomePageStyles[
                      "agitation-homepage__banners__video__content__text-wrapper__subtitle__icon"
                    ]
                  }
                  src={arrowRedLeft}
                  alt=""
                />
                {item}
              </p>
            ))}
          </div>

          <div
            className={
              AgitationBannersHomePageStyles[
                "agitation-homepage__banners__video__content__video"
              ]
            }
          >
            <YouTube videoId="k_TfJx6rl48" opts={opts} />
          </div>
        </div>
      </div>

      <UnlockThePowerBanner isMobileBunners={isMobileBunners} />

      {/* <div style={{ height: '200px' }}></div>  разкомментировать, если будет скрыта секция с Промокодом */}

      {banner(banersLast, banersLastAlt)}
    </div>
  );
};
