import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../components/BaseIcon";
import { StyledTooltip } from "../../components/TooltipBase.styled";

export const SearchAddressPassportTooltip = () => {
  return (
    <StyledTooltip title="Enter the full address, including building number. Confirm and ensure the ZIP & address is correct.">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={32} />
      </IconButton>
    </StyledTooltip>
  );
};
