import { useEffect } from "react";
import { Name } from "./BaseIcon";
import { BaseInput } from "./BaseInput";

import useDebounce from "../services/useDebounce";
import commonStyles from "../css/common.scss";
import styles from "./SearchInput.scss";

interface SearchInputProps {
  className?: string;
  searchValue?: string;
  placeholder?: string;
  onChange(search: string): void;
  onSearch(): void;
}

export const SearchInput = ({
  className = "",
  searchValue,
  placeholder = "Search",
  onChange,
  onSearch,
}: SearchInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const debouncedSearch = useDebounce(searchValue, 300);

  useEffect(() => {
    (!searchValue || searchValue.length > 2) && onSearch();
  }, [debouncedSearch]);

  const handleClearSearchInput = () => {
    onChange("");
    onSearch();
  };

  return (
    <BaseInput
      className={`${className} ${commonStyles.commonCardThinBottom} ${styles.searchInputLayoutWithLeftMenu}`}
      rightIcon={searchValue ? Name.ThinClose : Name.Search}
      value={searchValue}
      placeholder={placeholder}
      withoutErrors
      withoutBorder
      onSuffixClick={handleClearSearchInput}
      onChange={handleChange}
    />
  );
};
