import * as React from "react";

import SelectUnstyled, { SelectUnstyledProps } from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { ClickAwayListener } from "@mui/material";

import { styled } from "@mui/system";

import { BaseIcon, Name } from "../BaseIcon";

import brandColors from "../../css/brandColors.scss";
import { BaseInput } from "../BaseInput";
// import { ErrorField } from '../BaseInput/BaseInput'

type StyledRootProps = {
  withoutHover?: boolean;
  bordered?: boolean;
  height?: string | number;
};

const StyledRoot = styled("button")<StyledRootProps>(
  ({ withoutHover, bordered, height }) => `
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${bordered ? "1px solid rgba(0, 0, 0, 0.42)" : "none"};
  padding: ${bordered ? "12px 0px" : "none"};
  font-size: inherit;
  // border-radius: ${bordered ? "8px" : "none"};
  cursor: pointer;
  min-height: ${height ? height : "32px"};
  box-shadow: none;
  background: ${withoutHover ? "none" : brandColors.whiteColor} ;
  transition: all 0.2s ease-in;
  position: relative;
  width: 100%;

  &::before {
    transition: all 0.2s ease-in;
  }

  &:hover {
    transition: all 0.2s ease-in;
    background-color: ${withoutHover ? "none" : "rgba(40, 68, 146, 0.05)"};
    border-radius: ${bordered ? "none" : "37px"};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`
);

const StyledMenu = styled("ul")(
  ({ padding }: any) => `
  background: ${brandColors.whiteColor};
  box-shadow: 0px 2px 10px rgba(64, 89, 140, 0.1);
  border-radius: 10px;
  padding: ${padding ? padding : "16px 10px"};
  width: ${({ fullWidthMenu }: any) => (fullWidthMenu ? "100%" : "auto")};
  max-width: 400px;
  position: sticky;
  margin-top: 16px;
  /* bottom: 0; */
  /* right: 0; */
  z-index: 1000;
  max-height: 320px;
  overflow-y: auto;

  &>* {
    margin-top: 6px;

    :first-of-type {
      margin-top: 0px;
    }
  }
`
);

const StyledOption = styled(OptionUnstyled)(
  () => `
   background-color: ${({ isFocused, isSelected }: any) =>
     isFocused || isSelected
       ? "rgba(40, 68, 146, 0.05)"
       : `${brandColors.whiteColor}`};
   border-radius: 30px;
   padding: 10px 14px;
   font-weight: 400;
   font-size: 16px;
   color: ${brandColors.mainBlue};
   white-space: nowrap;
   display: flex;
   align-items: center;
   gap: 12px;
   position: relative;

   &:hover {
     cursor: pointer;
     transition: all 0.2s ease-in;
     background: rgba(40, 68, 146, 0.05);
   }

   &:not(:last-child) {
     margin-bottom: 6px;
   }

  &.${optionUnstyledClasses.selected} {
  }

  &.${optionUnstyledClasses.highlighted} {
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
  }

  &.${optionUnstyledClasses.disabled} {
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
  }
  `
);

const StyledPopper: any = styled(PopperUnstyled)`
  z-index: 2;
`;

type StyledValueContainerProps = {
  isRtl?: boolean;
};

const StyledValueContainer = styled("div")<StyledValueContainerProps>(
  ({ isRtl }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // color: ${brandColors.mainBlue};
    flex-direction: ${isRtl ? "row-reverse" : "row"};
`
);

const StyledLeftIcon: any = styled(BaseIcon)(
  () => `
  cursor: inherit;
`
);

const StyledDropdown: any = styled(BaseIcon)(
  ({ expanded }: any) => `
  transform: rotate(${expanded ? "-180" : "0"}deg);
  transition: all 0.15s ease-in-out;
  cursor: inherit;
`
);

type StyledSelectProps = {
  padding?: string;
  components?: any;
  slots?: any;
};

const StyledSelect = styled(SelectUnstyled)<StyledSelectProps>(
  ({ padding }) => `
  padding: ${padding};
`
);

type OwnProps = {
  withoutHover?: boolean;
  padding?: string;
  isRtl?: boolean;
  bordered?: boolean;
  renderSelectedComponentWithIcon?: React.ReactNode;
  components?: any;
  slots?: any;
};

const CustomSelect = React.forwardRef(function CustomSelect(
  props: SelectUnstyledProps<any> & OwnProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const components: SelectUnstyledProps<any>["components"] = {
    // const components: SelectUnstyledProps<any>['slots'] = {
    Root: StyledRoot,
    Listbox: StyledMenu,
    Popper: StyledPopper,
    // root: StyledRoot,
    // listbox: StyledMenu,
    // popper: StyledPopper,
    ...props.components,
  };

  return <StyledSelect {...props} ref={ref} components={components} />;
  // return <StyledSelect {...props} ref={ref} slots={components} />
}) as (
  props: SelectUnstyledProps<any> &
    React.RefAttributes<HTMLButtonElement> &
    OwnProps
) => JSX.Element;

const BaseNewSelect = ({ isRtl, ...props }: any) => {
  const {
    renderComponent,
    withoutHover,
    renderSelectedComponentWithIcon,
    withoutLabelSelecting,
    isSearchable,
    multiple,
    options,
    value,
    padding,
    placeholder,
    disabled,
    ...rest
  } = props;
  const [expanded, setExpanded] = React.useState(props.expanded || false);

  const renderValue = (option: any) => {
    if (renderComponent) {
      return renderComponent;
    }

    const optionData = option?.label ?? value;

    return (
      <StyledValueContainer isRtl={isRtl}>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          {props.renderSelectedComponentWithIcon && optionData?.leftIcon && (
            <StyledLeftIcon
              name={optionData.leftIcon}
              color={optionData?.leftIconColor}
              size={20}
            />
          )}

          <span>
            {withoutLabelSelecting
              ? typeof value === "object"
                ? value.value
                : value
              : option
                ? optionData?.label
                : value?.label || placeholder || "Select..."}
          </span>

          {props.renderSelectedComponentWithIcon && optionData?.rightIcon && (
            <StyledLeftIcon
              name={optionData.leftIcon}
              color={optionData?.rightIconColor}
              size={20}
            />
          )}
        </div>

        <StyledDropdown name={Name.DropdownArrow} expanded={expanded} />
      </StyledValueContainer>
    );
  };

  const handleExpand = (): void => setExpanded(!expanded);
  const handleClickAway = (): void => setExpanded(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div onClick={handleExpand} {...rest}>
        <CustomSelect
          renderValue={renderValue}
          defaultValue={props.defaultValue}
          withoutHover={withoutHover}
          renderSelectedComponentWithIcon={renderSelectedComponentWithIcon}
          padding={padding}
          disabled={disabled}
          bordered={props.bordered}
        >
          {props.children ? (
            props.children
          ) : (
            <>
              {isSearchable && <BaseInput />}

              {options.map((option: any) => (
                <div
                  key={option.value}
                  onClick={(event) => props.onChange(option, event)}
                >
                  <StyledOption value={option.value} label={option}>
                    {option?.leftIcon && (
                      <StyledLeftIcon
                        name={option.leftIcon}
                        color={option.leftIconColor}
                        size={20}
                      />
                    )}

                    {option.label}
                  </StyledOption>
                </div>
              ))}
            </>
          )}
        </CustomSelect>

        {/* <ErrorField error={props.error} /> */}
      </div>
    </ClickAwayListener>
  );
};

export default BaseNewSelect;
