export const AppSuccessPaymentMiddleware = () => {
  console.log("=AppSuccessPaymentMiddleware=");

  globalThis.parent.postMessage(
    {
      key: "icredit_response",
      status: "success",
      icredit_response: "success",
    },
    "*",
  );

  return <></>;
};
