import API from "../../../../../../../api";
import { getErrorMessage } from "../../../../../../../services/getErrorMessage";
import { Notify } from "../../../../../../../services/notifications";

export const getCommodityCodes = async (
  country_code: string,
  search: string,
) => {
  const params = new URLSearchParams();

  params.append("country_code", country_code);
  params.append("search", search);

  try {
    const res = await API.get("/commodity-code/codes", { params });
    // console.log(res);

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

export const getCommodityCategories = async ({
  countryCode,
  search,
  parentId,
}: any) => {
  const params = new URLSearchParams();

  params.append("country_code", countryCode);
  // search && params.append('search', search)
  parentId && params.append("parent_id", parentId);

  try {
    const res = await API.get("/commodity-code/categories", { params });
    // console.log(res);

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

export const getCodesByCategory = async (
  country_code: string,
  categoryId: string,
) => {
  const params = new URLSearchParams();

  params.append("country_code", country_code);

  try {
    const res = await API.get(
      `/commodity-code/categories/${categoryId}/codes`,
      { params },
    );
    // console.log(res);

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};
