import type { Location } from "@remix-run/router";
import { history } from "../../../services/history";
import { checkIsVueRoute } from "../../../integrationWithVue/checkIsVueRoute";
import { goToVue } from "../../../integrationWithVue/goToVue";

export default function redirectAfterSuccessAuth(
  location: Location,
  from: string | null,
  rtl: boolean,
  locale: string,
): void {
  // console.log("=redirectAfterSuccessAuth=", location, from, rtl, locale);
  // TODO: желательно сделать так, что бы location и from не передавались как аргументы, а брались внутри функции
  const redirectUrl =
    location?.state?.from?.pathname + location?.state?.from?.search ||
    from ||
    (locale !== "en" ? `/${locale}` : "/");

  // console.log("=redirectUrl=", redirectUrl, checkIsVueRoute(redirectUrl));

  if (checkIsVueRoute(redirectUrl)) {
    goToVue(redirectUrl, true);
  } else {
    history.replace(redirectUrl);
  }
}
