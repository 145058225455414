import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMenuItem } from "../constants";

import { signOut } from "../scenes/auth/data/actions";

export const useGetMenuActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getMenuActions(event: any, menuItem: IMenuItem): void {
    if (menuItem?.to) {
      navigate(menuItem?.to);
    } else if (menuItem?.goTo) {
      menuItem.goTo(event);
    } else {
      switch (menuItem?.value) {
        case "logOut":
          dispatch(signOut());
      }
    }
  }

  return { getMenuActions };
};
