import API from "../../api";
import { getErrorMessage } from "../../services/getErrorMessage";
import { Notify } from "../../services/notifications";

export const getAddressFromBook = async (id: string) => {
  try {
    const res = await API.get(`/address-book/addresses/${id}`);

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

export const updateAddressInBook = async (address: any) => {
  try {
    const res = await API.put(
      `/address-book/addresses/${address.object_id}`,
      address,
    );

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};
