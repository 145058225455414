import API from "../../../api";
import { VerificationType } from "../interfaces";

class ProfileRequests {
  static updateProfile = async (data: any) => {
    const response: any = await API.patch("/profile", data);

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };

  static verifyProfile = async (type: VerificationType) => {
    const response = await API.get(`/profile/verify?type=${type}`);

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };

  static confirmVerifyProfile = async (confirmation_code: string) => {
    const response = await API.post("/profile/verify", { confirmation_code });

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };

  static updatePassword = async (formValues: any) => {
    const response = await API.patch("/profile/password", { ...formValues });

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };
}

export default ProfileRequests;
