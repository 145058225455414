import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../../../components/BaseIcon";
import { StyledTooltip } from "../../../../../../../components/TooltipBase.styled";

export const WeightTooltip = () => {
  return (
    <StyledTooltip title="Please enter the weight of a single item">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={32} />
      </IconButton>
    </StyledTooltip>
  );
};
