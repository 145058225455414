import { styled } from "@mui/system";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { BaseRadio } from "../../../../../../../../components/BaseRadio";

import brandColors from "../../../../../../../../css/brandColors.scss";
import { Breakpoints, media } from "../../../../../../../../css/breakpoints";

export const StyledCalendar = styled((props: any) => (
  <CalendarPicker {...props} />
))(({ theme }) => ({
  color: `${brandColors.mainBlue} !important`,

  "& .MuiPickersCalendarHeader-root": {
    justifyContent: "center",
  },
  "& .MuiCalendarPicker-root": {
    border: "1px solid #EEF0F6",
    borderRadius: "16px",
    maxHeight: "360px",
    margin: "0",
    width: "100%",
  },
  "& .Mui-selected": {
    backgroundColor: `${brandColors.lightestBlue} !important`,
    color: `${brandColors.mainBlue} !important`,
  },
  "& .MuiPickersDay-today": {
    borderColor: `${brandColors.lightestBlue} !important`,
  },
  "& .MuiPickersDay-root": {
    color: `${brandColors.mainBlue}`,
    fontSize: "16px",

    [`@media ${media(Breakpoints.Mobile)}`]: {
      fontSize: "12px",
    },
  },
  "& .MuiTypography-root": {
    color: `${brandColors.mainBlue} !important`,
    fontSize: "16px",

    [`@media ${media(Breakpoints.Mobile)}`]: {
      fontSize: "12px",
    },
  },
  "& .MuiPickersArrowSwitcher-button": {
    color: "#A8B6D2 !important",
  },
  // Скрывает селект выбора года
  // '& .MuiPickersCalendarHeader-labelContainer': {
  //   display: 'none',
  // }
}));

export const PickupDateContent = styled("div")`
  display: flex;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    flex-direction: column;
  }
`;

export const StyledRightColumn = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const StyledBaseRadio = styled(BaseRadio)(
  ({ checked }) => `
 width: 100%;
 border: 1px solid ${brandColors.lightestBlue};
 border-radius: 16px;
 margin-bottom: 8px;
 background: ${checked ? brandColors.lightestBlue : brandColors.whiteColor};
 padding: 3px 14px;

 @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
   margin-right: 0;
 }
`,
);
