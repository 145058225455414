import React, { useState } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/system";

import { BaseButton } from "../../../../components/BaseButton";
import { Name } from "../../../../components/BaseIcon";
import { BaseOtp } from "../../../../components/BaseOtp";

import { selectPhone } from "../../../../data/selectors";

import brandColors from "../../../../css/brandColors.scss";
import styles from "../../styles/Profile.scss";

const StyledRoot = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`,
);

const StyledDescription = styled("div")(
  () => `
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${brandColors.lightBlueColor};
  margin-bottom: 32px;
`,
);

const StyledNumber = styled("span")(
  () => `
  color: ${brandColors.mainBlue};
`,
);

type Props = {
  onClose(): void;
  onConfirm(otp: string): void;
};

const ConfirmPhoneSidebar: React.FC<Props> = (props) => {
  const [otp, setOtp] = useState("");
  // const loading = useSelector(selectIsLoading)
  const phone = useSelector(selectPhone);
  const onChange = (value: string) => setOtp(value);

  const valueLength = 4;

  const isConfirmBtnDisabled = (): boolean => otp.length === valueLength;

  return (
    <StyledRoot>
      <div>
        <BaseButton
          onClick={props.onClose}
          className={styles.btnBack}
          leftIcon={Name.LeftArrow}
          clear
        >
          Confirm phone
        </BaseButton>

        <StyledDescription>
          Enter the password that will be sent to your phone number{" "}
          <StyledNumber>{phone}</StyledNumber>
        </StyledDescription>

        <BaseOtp valueLength={valueLength} value={otp} onChange={onChange} />
      </div>

      <BaseButton
        onClick={() => props.onConfirm(otp)}
        // loading={loading}
        disabled={!isConfirmBtnDisabled()}
      >
        Confirm
      </BaseButton>
    </StyledRoot>
  );
};

export default ConfirmPhoneSidebar;
