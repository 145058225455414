import { takeEvery, put, call } from "redux-saga/effects";
import { RootRequests, ShipmentAPI } from "../../api";
import { getErrorMessage } from "../../services/getErrorMessage";
import { Notify } from "../../services/notifications";
import { hideLoader, setUser, showLoader } from "../actions";
import * as actions from "../actions";
import { checkFormErrors } from "../../services/formErrors/checkFormErrors";
import {
  setCheckoutLoading,
  setShipment,
  updateShipment,
} from "../../scenes/checkout/data/actions";
import { setDomesticShipping } from "../../data/actions";
import { history } from "../../services/history";
// import {
//   setPersonInfobip,
//   updatePersonInfobip,
// } from "../../plugins/infobip/infobip";

const tomorrow = new Date();
tomorrow.setDate(new Date().getDate() + 1);

export function* getUser(): any {
  yield put(showLoader());

  try {
    const response: any = yield call(RootRequests.getProfile);

    // if (process.env.REACT_APP_INFOBIP) {
    //   setPersonInfobip({ email: response.data.data.email }, 2000);

    //   const data: any = {
    //     firstName: response.data.data.first_name,
    //     lastName: response.data.data.last_name,
    //   };
    //   response.data.data.phone &&
    //     (data.contactInformation = {
    //       phone: [{ number: response.data.data.phone }],
    //     });

    //   updatePersonInfobip(data);
    // }

    yield put(setUser(response.data.data));
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
    throw new Error(error.message);
  } finally {
    yield put(hideLoader());
  }
}

function* getShipmentAdditionalPayments(action: any): any {
  yield put(showLoader());

  try {
    const response: any = yield call(
      ShipmentAPI.getShipmentAdditionalPayments,
      action.payload
    );

    yield checkFormErrors({ response });

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* sendCoupon(action: any): any {
  yield put(actions.setCouponLoading(true));

  try {
    const response = yield call(ShipmentAPI.sendCoupon, action.payload);

    if (response.status === 200) {
      action.payload.resolve();
      yield put(
        updateShipment({
          coupon_code: action.payload.code,
          additional_payments: response.data.data,
        })
      );

      return response.data.data;
    } else {
      yield checkFormErrors({
        response,
        withoutFormErrors: true,
        reject: action.payload.reject,
      });
    }
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(actions.setCouponLoading(false));
  }
}

function* deleteCoupon(action: any): any {
  yield put(actions.setCouponLoading(true));

  try {
    const response = yield call(
      ShipmentAPI.deleteCoupon,
      action.payload.shipmentId
    );

    yield checkFormErrors({ response });

    yield put(
      updateShipment({
        coupon_code: null,
        additional_payments: response.data.data,
      })
    );

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(actions.setCouponLoading(false));

    action.payload.resolve();
  }
}

function* getShipment(action: any): any {
  try {
    yield put(setCheckoutLoading(true));

    const shipmentResponse: any = yield call(
      ShipmentAPI.getShipment,
      action.payload
    );

    if (!shipmentResponse?.is_editable) {
      history.push(`/error404`);
    }

    if (!shipmentResponse?.chosen_rate) {
      history.push(`/shipments/${action.payload}`);
    }

    yield put(setShipment(shipmentResponse));
    yield put(
      setDomesticShipping(
        shipmentResponse.address_from.country_code ===
          shipmentResponse.address_to.country_code
      )
    );

    const pickupCalendarResponse = yield call(
      ShipmentAPI.getPickupCalendar,
      action.payload
    );

    yield put(
      updateShipment({
        pickup_details: {
          ...shipmentResponse.pickup_details,
          calendar: [...pickupCalendarResponse],
        },
      })
    );
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(setCheckoutLoading(false));
  }
}

export function* rootSaga() {
  yield takeEvery(actions.getUser, getUser);
  yield takeEvery(actions.sendCoupon, sendCoupon);
  yield takeEvery(actions.deleteCoupon, deleteCoupon);
  yield takeEvery(
    actions.getShipmentAdditionalPayments,
    getShipmentAdditionalPayments
  );
  yield takeEvery(actions.getShipment, getShipment);
}
