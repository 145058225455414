import { styled } from "@mui/system";
import brandColors from "../../../../../../css/brandColors.scss";

export const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(64, 89, 140, 0.1);
  border-radius: 16px;
  background: ${brandColors.whiteColor};
  max-width: 367px;
  min-width: ${320 - 16 * 2}px;
  transition: all 0.1s ease-in;
  padding: 20px 20px 40px 20px;
  flex: 1 1 0;

  &:hover {
    box-shadow: 0px 2px 20px rgba(8, 20, 45, 0.24);
    transform: translateY(-10px);
  }
`;
