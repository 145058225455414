import React, { useMemo } from "react";
import { BaseInputForPhoneInput } from "../BaseInputForPhoneInput";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { BaseNewSelect } from "../BaseNewSelect";
import { ISelectOption } from "../../interfaces";

import styles from "./BasePhoneInput.scss";

function CountrySelectWithIcon({
  value,
  options,
  className,
  iconComponent: Icon,
  getIconAspectRatio,
  unicodeFlags,
  onChange,
  ...rest
}: any) {
  const selectedOption = useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  return (
    <>
      {rest.isDomesticShipping ? (
        <></>
      ) : (
        <BaseNewSelect
          className={styles.selectIcon}
          {...rest}
          value={value}
          options={options}
          onChange={(value: ISelectOption) => onChange(value.value)}
          renderComponent={
            <Icon
              aria-hidden
              country={value}
              label={selectedOption && selectedOption.label}
              aspectRatio={unicodeFlags ? 1 : undefined}
            />
          }
          withoutHover
        />
      )}
    </>
  );
}

CountrySelectWithIcon.defaultProps = {
  arrowComponent: () => <div className="PhoneInputCountrySelectArrow" />,
};

function getSelectedOption(options: any, value: any) {
  for (const option of options) {
    if (!option.divider && option.value === value) {
      return option;
    }
  }
}

type BasePhoneInputProps = {
  isDomesticShipping?: boolean;
  required?: boolean;
  value: string;
  name?: string;
  className?: string;
  defaultCountry?: string;
  error?: any;

  onChange(value: string): void;
};

const BasePhoneInput: React.FC<BasePhoneInputProps> = (props) => {
  const innerDefaultCountry: any = props.defaultCountry || "US";
  const international = true;
  const label = international
    ? "Phone (use international format, mobile preferred)"
    : "Phone number";

  let classes = props.className ?? "";
  !props.isDomesticShipping && (classes += ` ${styles.labelOffset}`);

  return (
    <PhoneInput
      className={classes}
      international={international}
      withCountryCallingCode
      value={props.value}
      name={props.name}
      required={props.required}
      label={label}
      country={props.isDomesticShipping ? innerDefaultCountry : undefined}
      defaultCountry={innerDefaultCountry}
      countrySelectComponent={CountrySelectWithIcon}
      countrySelectProps={{
        isDomesticShipping: !!props.isDomesticShipping,
      }}
      inputComponent={BaseInputForPhoneInput}
      error={
        props.error
          ? props.error
          : props.value
            ? isValidPhoneNumber(props.value)
              ? false
              : "Invalid phone number"
            : false
      }
      onChange={props.onChange}
    />
  );
};

export default BasePhoneInput;
