import { handleActions } from "redux-actions";
import { convertFormErrors } from "../../../services/formErrors/convertFormErrors";

import * as actions from "./actions";

import { REDUCER_NAME } from "./constants";
import { getDefaultFormValues } from "../components/AccordionItems/PackageDetails/services/getDefaultFormValues";

const initialState = {
  // loading: false, // Если не будет выявлено ошибок, то удалить.
  loading: true,
  shipment: {
    pickup_details: {
      calendar: [],
    },
    time: null,
    address_from: {
      formErrors: [],
    },
    address_to: {
      formErrors: [],
    },
    parcels: null,
    customs_items: [],
    billing_address: {
      formErrors: [],
    },
    total_value: 0,
    insurance: false,
  },
  isShowPaymentBtn: false,
};

const getCustomsDeclarationOriginal = (state: any, payload: any) => {
  (payload.customs_declaration ||
    !state.shipment.customs_declaration_original) &&
    (payload.customs_declaration_original = getDefaultFormValues(payload));
};

const reducer = {
  [actions.setShipment.toString()]: (state: any, { payload }: any) => {
    getCustomsDeclarationOriginal(state, payload);

    return {
      ...state,
      shipment: payload,
    };
  },
  [actions.updateShipment.toString()]: (state: any, { payload }: any) => {
    getCustomsDeclarationOriginal(state, payload);

    return { ...state, shipment: { ...state.shipment, ...payload } };
  },
  [actions.setIsShowPaymentBtn.toString()]: (state: any, { payload }: any) => {
    return { ...state, isShowPaymentBtn: payload };
  },
  [actions.setCheckoutLoading.toString()]: (state: any, { payload }: any) => {
    return { ...state, loading: payload };
  },
  [actions.setAddressFromFormErrors.toString()]: (
    state: any,
    { payload }: any,
  ) => ({
    ...state,
    shipment: {
      ...state.shipment,
      address_from: {
        ...state.shipment.address_from,
        formErrors: convertFormErrors(payload.details),
      },
    },
  }),
  [actions.setHasSkippedValidationToAddressFrom.toString()]: (
    state: any,
    { payload }: any,
  ) => ({
    ...state,
    shipment: {
      ...state.shipment,
      address_from: {
        ...state.shipment.address_from,
        has_skipped_validation: payload,
      },
    },
  }),
  [actions.setAddressToFormErrors.toString()]: (
    state: any,
    { payload }: any,
  ) => ({
    ...state,
    shipment: {
      ...state.shipment,
      address_to: {
        ...state.shipment.address_to,
        formErrors: convertFormErrors(payload.details),
      },
    },
  }),
  [actions.setHasSkippedValidationToAddressTo.toString()]: (
    state: any,
    { payload }: any,
  ) => ({
    ...state,
    shipment: {
      ...state.shipment,
      address_to: {
        ...state.shipment.address_to,
        has_skipped_validation: payload,
      },
    },
  }),
  [actions.setBillingAddressFormErrors.toString()]: (
    state: any,
    { payload }: any,
  ) => ({
    ...state,
    shipment: {
      ...state.shipment,
      billing_address: {
        ...state.shipment.billing_address,
        formErrors: convertFormErrors(payload.details),
      },
    },
  }),
  [actions.setHasSkippedValidationToBillingAddress.toString()]: (
    state: any,
    { payload }: any,
  ) => ({
    ...state,
    shipment: {
      ...state.shipment,
      billing_address: {
        ...state.shipment.billing_address,
        has_skipped_validation: payload,
      },
    },
  }),
};

export const checkoutReducer = {
  [REDUCER_NAME]: handleActions(reducer, initialState),
};
