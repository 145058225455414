import React, { useEffect, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import AgitationBannersHomePageStyles from "./AgitationBannersHomePage.scss";
import DoorToDoorGlobalImg from "../../../../assets//home-page/Door-to-Door-Global.png";
import DoorToDoorGlobalMobileImg from "../../../../assets//home-page/Door-to-Door-Global-Mobile.png";
import GetTheBestImg from "../../../../assets//home-page/Get-the-Best.png";
import GetTheBestMobileImg from "../../../../assets//home-page/Get-the-Best-Mobile.png";
import TrackingAndNotificationsImg from "../../../../assets//home-page/Tracking-and-Notifications.png";
import TrackingAndNotificationsMobileImg from "../../../../assets//home-page/Tracking-and-Notifications-Mobile.png";
import ConnectYourStoreImg from "../../../../assets//home-page/Connect-Your-Store.png";
import ConnectYourStoreMobileImg from "../../../../assets//home-page/Connect-Your-Store-Mobile.png";
import GetPromoImg from "../../../../assets//home-page/Get-promo.svg";
import GetPromoMobileImg from "../../../../assets//home-page/Get-promo-Mobile.svg";
import { useIsResponsive } from "../../../../services/breakpoint/useIsResponsive";
// import * as rdd from 'react-device-detect';
import arrowRed from "../../../../assets/icons/arrow-red.svg";
import brandArrowBlue from "../../../../assets/icons/brand-arrow-blue.svg";
import brandArrow from "../../../../assets/icons/brand-arrow.svg";
import { UnlockThePowerBanner } from "./UnlockThePowerBanner";
import { goToVue } from "../../../../integrationWithVue/goToVue";
// import getPromoEventInfobip from "../../../../plugins/infobip/getPromoEventInfobip";

export const AgitationBannersHomePage = () => {
  const { innerWidth } = useIsResponsive();

  const scheckIsMobileBunners = () => {
    // return innerWidth < 850
    return innerWidth < 1080;
  };

  const [isMobileBunners, setIsMobileBunners] = useState(
    scheckIsMobileBunners()
  );

  useEffect(() => {
    setIsMobileBunners(scheckIsMobileBunners());
  }, [innerWidth]);

  const banersFirstAlt: any = [
    {
      title: "Door to Door Global Shipping in more than 220 countries",
      subTitle:
        "Order pick up from anywhere & anytime. No need for logistics or tax knowledge.",
      classesContent: "content-banner1",
      classesWrapper: "banner1-wrapper",
      action: (
        <button
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          onClick={() => {
            globalThis.scrollTo(0, 0);
          }}
        >
          Fast Shipping
          <img src={brandArrow} alt="" />
        </button>
      ),
    },
    {
      title: "Get the Best Shipping Rates, Instantly",
      subTitle:
        "The lowest-possible rates from our international and domestic partners like DHL, FedEx, UPS, Aramex and Israel Post in 220 countries worldwide.",
      classesContent: "content-banner2",
      classesWrapper: "banner2-wrapper",
      action: (
        <button
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          onClick={() => {
            globalThis.scrollTo(0, 0);
          }}
        >
          Compare Rates
          <img src={brandArrow} alt="" />
        </button>
      ),
    },
    {
      title: "Tracking and Notifications",
      subTitle: "Keep you and your customers up-to-date.",
      classesContent: "content-banner3",
      classesWrapper: "banner3-wrapper",
      action: (
        <button
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          onClick={(event) => goToVue("/tracking", event)}
        >
          Track Order
          <img src={brandArrow} alt="" />
        </button>
      ),
    },
    {
      title: "Connect Your Store",
      subTitle:
        "Better access to global markets and improved customer experience. OMS for Amazon, Ebay, Etsy, Shopify, WordPress, Magento and others.",
      classesContent: "content-banner4",
      classesWrapper: "banner4-wrapper",
      // action: <button
      action: (
        <a
          className={AgitationBannersHomePageStyles.linkBannerBtn}
          href="/contacts"
          // onClick={(event) => {
          //   globalThis.open('/contacts', (event?.ctrlKey || event?.metaKey) ? '_blank' : '_self')
          // }}
        >
          Contact Us
          <img src={brandArrow} alt="" />
        </a>
      ),
      // </button>
    },
  ];

  const banersFirst: any = {
    true: [
      DoorToDoorGlobalMobileImg,
      GetTheBestMobileImg,
      TrackingAndNotificationsMobileImg,
      ConnectYourStoreMobileImg,
    ],
    false: [
      DoorToDoorGlobalImg,
      GetTheBestImg,
      TrackingAndNotificationsImg,
      ConnectYourStoreImg,
    ],
  };

  const banersLastAlt: any = [
    {
      title: "Shipping is all about Freedom",
      subTitleHtml: (
        <>
          <p>
            Get 10% off for your first shipping order!
            <br />
            For a promo code, please contact us through our chat 😊
          </p>
          {/* <p>Get <span className={AgitationBannersHomePageStyles.contentBanner6SubTitle }>10%</span> off for your first shipping!</p>
        <p style={{ paddingTop: '20px' }}>For a promo code, please contact us in our chat &#128522;</p>, */}
        </>
      ),
      classesContent: "content-banner6",
      classesWrapper: "banner6-wrapper",
      // action: <a
      action: (
        <button
          className={AgitationBannersHomePageStyles.getPromoBtn}
          // href='/contacts'
          onClick={(event) => {
            // process.env.REACT_APP_INFOBIP && getPromoEventInfobip("GetPromo");
            globalThis.open(
              "/contacts",
              event?.ctrlKey || event?.metaKey ? "_blank" : "_self"
            );
          }}
        >
          GET PROMO
          <img src={brandArrowBlue} alt="" />
        </button>
      ),
      // </a>
    },
  ];

  const banersLast: any = {
    true: [GetPromoMobileImg],
    false: [GetPromoImg],
  };

  const banner = (bannerBasics: any, bannersContent: any) => (
    <div className={AgitationBannersHomePageStyles.bannerCentering}>
      {bannerBasics[`${isMobileBunners}`].map((item: any, index: number) => (
        <div
          key={index}
          className={
            AgitationBannersHomePageStyles.contentBannerWrapper +
            ("classesWrapper" in bannersContent[index]
              ? ` ${AgitationBannersHomePageStyles[bannersContent[index].classesWrapper]}`
              : "")
          }
        >
          <div
            className={
              AgitationBannersHomePageStyles.contentBanner +
              " " +
              AgitationBannersHomePageStyles[
                bannersContent[index].classesContent
              ]
            }
          >
            <h2>{bannersContent[index].title}</h2>

            {"subTitleHtml" in bannersContent[index] ? (
              bannersContent[index].subTitleHtml
            ) : (
              <p>{bannersContent[index].subTitle}</p>
            )}

            {"action" in bannersContent[index] && bannersContent[index].action}
          </div>

          <img
            key={index}
            src={item}
            alt={bannersContent[index].title + " - Shipper Global"}
          />
        </div>
      ))}
    </div>
  );

  const banersVideoSubTitle = [
    "Send and receive packages from all over the world!",
    "No need for logistics or tax knowledge at all!",
    "And, no more worries about a package going astray!",
  ];

  const opts: YouTubeProps["opts"] = {
    height: "360",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  };

  return (
    <div className={AgitationBannersHomePageStyles.MetricSystemBtns}>
      <h2 className={AgitationBannersHomePageStyles.title}>
        Compare and Save on Shipping Costs
      </h2>

      <p className={AgitationBannersHomePageStyles.subTitle}>
        Send, Receive & Track Parcels, Pallets & Documents Domestic & Globally.
      </p>

      {banner(banersFirst, banersFirstAlt)}

      <div
        className={
          AgitationBannersHomePageStyles["agitation-homepage__banners__video"]
        }
      >
        <div
          className={
            AgitationBannersHomePageStyles[
              "agitation-homepage__banners__video__content"
            ]
          }
        >
          <div
            className={
              AgitationBannersHomePageStyles[
                "agitation-homepage__banners__video__content__text-wrapper"
              ]
            }
          >
            <h2
              className={
                AgitationBannersHomePageStyles[
                  "agitation-homepage__banners__video__content__text-wrapper__title"
                ]
              }
            >
              Shipper Global Brings the World to You!
            </h2>

            {banersVideoSubTitle.map((item) => (
              <p
                key={item}
                className={
                  AgitationBannersHomePageStyles[
                    "agitation-homepage__banners__video__content__text-wrapper__subtitle"
                  ]
                }
              >
                <img
                  className={
                    AgitationBannersHomePageStyles[
                      "agitation-homepage__banners__video__content__text-wrapper__subtitle__icon"
                    ]
                  }
                  src={arrowRed}
                  alt=""
                />
                {item}
              </p>
            ))}
          </div>

          <div
            className={
              AgitationBannersHomePageStyles[
                "agitation-homepage__banners__video__content__video"
              ]
            }
          >
            <YouTube videoId="k_TfJx6rl48" opts={opts} />
          </div>
        </div>
      </div>

      <UnlockThePowerBanner isMobileBunners={isMobileBunners} />

      {/* <div style={{ height: '200px' }}></div>  раскомментировать, если будет скрыта секция с Промокодом */}

      {banner(banersLast, banersLastAlt)}
    </div>
  );
};
