export const getBase64FromFile = async (
  file: any,
): Promise<string | undefined> => {
  return new Promise<string | undefined>((resolve, reject): void => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void =>
      resolve(
        reader.result?.toString().replace("data:", "").replace(/^.+,/, ""),
      );
    reader.onerror = (error) => reject(error);
  });
};
