import { styled } from "@mui/system";

import { BaseNewSelect } from "../../../../../../components/BaseNewSelect";

import { Breakpoints, media } from "../../../../../../css/breakpoints";

export const StyledRoot = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-width: 215px;

  @media ${media(Breakpoints.Tablet, Breakpoints.Tablet)} {
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
  }
`;

export const StyledNumber = styled("div")<{ isVisible: boolean }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 0;
  color: rgba(0, 0, 0, 0.44);
  margin-top: 13px;
  background: #ffffff;
  padding: 5px;
  border-radius: 9999px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    margin-top: 0;
    width: 20px;
    height: 20px;
  }

  @media ${media(Breakpoints.Mobile)} {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 22px 16px;
  justify-content: center;

  @media ${media(Breakpoints.Tablet, Breakpoints.Tablet)} {
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    align-items: flex-start;
  }

  @media ${media(Breakpoints.Mobile)} {
    gap: 6px;
    margin-top: 36px;
    flex-wrap: wrap;
  }
`;
export const StyledEnvelopeSizeSelect = styled(BaseNewSelect)`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 19px;
  position: relative;
  min-width: 222px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  transition: all 0.2s ease-in;

  & > * {
    & > * {
      padding-left: 10px !important;
    }
  }

  @media ${media(Breakpoints.Tablet, Breakpoints.Tablet)} {
    width: 100%;
  }

  @media ${media(Breakpoints.Mobile)} {
    flex: 1 0 100%;
    margin-bottom: 6px;
  }
`;

export const StyledSizeAndWeightSeparator = styled("div")`
  margin-top: 34px;
  color: #000000;
`;
