import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { RadioGroup } from "@mui/material";

import { useSelector } from "react-redux";
import { BaseRadio } from "../../../../../../../../components/BaseRadio";
import { ContactForm } from "../../../../../ContactForm/ContactForm";
import { BaseCheckbox } from "../../../../../../../../components/BaseCheckbox";
import {
  Accordion,
  AccordionCard,
  AccordionDetails,
  AccordionSummary,
} from "../../../../../AccordionItems/Accordion";
import { StyledActionFooter } from "../../../AccordionItems/styles/AccordionItems.styled";

import {
  selectBillingAddressFormErrors,
  selectShipment,
  selectHasSkippedValidationFromBillingAddress,
  selectPostalCodeFromBillingAddress,
} from "../../../../../../data/selectors";
import formStyles from "../../../../../Form/Form.scss";
import styles from "./BillingInfoCard.scss";
import { setFormErrors } from "../../../../../../../../services/formErrors/setFormErrors";
import { useDispatch } from "react-redux";
import {
  saveAddressForm,
  setBillingAddressFormErrors,
  updateShipment,
  setHasSkippedValidationToBillingAddress,
} from "../../../../../../data/actions";
import { BaseButton } from "../../../../../../../../components/BaseButton";
import { getTransformedAddressFormValesToSubmit } from "../../../../../../services/getTransformedAddressFormValesToSubmit";
import {
  selectIsLoading,
  selectUser,
} from "../../../../../../../../data/selectors";
import { ShipmentFormNames } from "../../../../../../data/constants";
import { getDefaultFormValues } from "../../services/getDefaultFormValues";
import { createAddressToBook } from "../../../../../../../../api/commonRequests";
import { submitToGoogleAnalyticsOnSubmitStep } from "../../../../submitToGoogleAnalytics.FormCheckout";
import { countriesWithStates } from "../../../../../../../../constants";
import { getCountryName } from "../../../../../../../../services/getCountryName";
// import nextStepInCheckoutEventInfobip from "../../../../../../../../plugins/infobip/nextStepInCheckoutEventInfobip";
// import getShortShipmentIdInfobip from "../../../../../../../../plugins/infobip/getShortShipmentIdInfobip";

const BillingInfoCard: React.FC<any> = (props) => {
  const [isSaveAddress, setIsSaveAddress] = useState<boolean>(false);
  const shipment = useSelector(selectShipment);
  const user = useSelector(selectUser);

  const isHaveDefaultBillingAddressBookAddress =
    !!user?.default_billing_address_book_address;

  const getDefaultFormName = (): string => {
    const billingAddressId = shipment.billing_address?.object_id;

    const isAddressFromIdEqualBillingAddressId =
      shipment.address_from?.object_id === billingAddressId;

    if (isAddressFromIdEqualBillingAddressId) {
      return ShipmentFormNames.AddressFrom;
    }

    const isAddressToIdEqualBillingAddressId =
      shipment.address_to?.object_id === billingAddressId;

    if (isAddressToIdEqualBillingAddressId) {
      return ShipmentFormNames.AddressTo;
    }

    if (
      !billingAddressId ||
      (isHaveDefaultBillingAddressBookAddress &&
        user?.default_billing_address_book_address?.object_id ===
          billingAddressId)
    ) {
      return ShipmentFormNames.DefaultBillingAddress;
    }

    return ShipmentFormNames.BillingAddress;
  };

  const [formName, setFormName] = useState(getDefaultFormName());

  const handleChangeFormName = (e: any): void => {
    setFormName(e.target.value);
  };
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: getDefaultFormValues(props.shipment),
  });
  const formErrors = useSelector(selectBillingAddressFormErrors);
  const postalCodeValidation = useSelector(
    selectHasSkippedValidationFromBillingAddress
  );
  const loading = useSelector(selectIsLoading);
  const postalCode = useSelector(selectPostalCodeFromBillingAddress);

  const [
    showPostalCodeValidationCheckbox,
    setShowPostalCodeValidationCheckbox,
  ] = useState(postalCodeValidation);

  useEffect(() => {
    if (formName === ShipmentFormNames.DefaultBillingAddress) {
      methods.setValue(
        "billing_address",
        user?.default_billing_address_book_address
      );
      methods.setValue(
        "billing_address.is_company",
        !!user?.default_billing_address_book_address?.company_name
      );
    } else if (formName === ShipmentFormNames.AddressFrom) {
      methods.setValue("billing_address", shipment?.address_from?.object_id);
    } else if (formName === ShipmentFormNames.AddressTo) {
      methods.setValue("billing_address", shipment?.address_to?.object_id);
    } else if (formName === ShipmentFormNames.BillingAddress) {
      methods.setValue(
        "billing_address",
        getDefaultFormValues(shipment).billing_address
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName]);

  const postalCodeError = formErrors?.find(
    (el: any) => el.fieldName === "billing_address.postal_code"
  );

  useEffect(() => {
    setFormErrors({
      formErrors,
      methods,
      formName: ShipmentFormNames.BillingAddress,
    });

    if (postalCodeError) {
      setShowPostalCodeValidationCheckbox(true);
    }
  }, [formErrors]);

  useEffect(() => {
    postalCode !== methods.watch("billing_address.postal_code") &&
      dispatch(setHasSkippedValidationToBillingAddress(false));
  }, [methods.watch("billing_address.postal_code")]);

  useEffect(() => {
    if (postalCodeValidation) {
      methods.clearErrors("billing_address.postal_code");
    } else {
      setFormErrors({
        formErrors,
        methods,
        formName: ShipmentFormNames.BillingAddress,
      });
    }
  }, [postalCodeValidation]);

  const isCompanyChecked = methods.watch("billing_address.is_company");

  const saveAddressFormFn = () =>
    new Promise((resolve, reject) =>
      dispatch(
        saveAddressForm({
          shipmentId: props.shipmentId,
          addressForm:
            formName === ShipmentFormNames.BillingAddress
              ? getTransformedAddressFormValesToSubmit({
                  methods,
                  formName,
                })
              : {
                  address_type: ShipmentFormNames.BillingAddress,
                  address: methods.watch("billing_address"),
                },
          skipValidation: postalCodeValidation,
          setFormErrorAction: setBillingAddressFormErrors,
          resolve,
          reject,
        })
      )
    );

  const onSubmit = (formValues: any) => {
    // console.log('=onSubmit=', formValues);
    let requests = [saveAddressFormFn()];

    isSaveAddress &&
      requests.push(createAddressToBook(methods.getValues("billing_address")));

    Promise.all(requests).then(() => {
      dispatch(
        updateShipment({
          ...formValues,
          billing_address: methods.watch("billing_address"),
        })
      );

      setIsSaveAddress(false);

      let var_billing;

      if (formName === ShipmentFormNames.AddressFrom) {
        var_billing = "Same as Pick up";
      } else if (formName === ShipmentFormNames.AddressTo) {
        var_billing = "Same as Drop off";
      } else {
        var_billing = "Other";
      }

      process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
        submitToGoogleAnalyticsOnSubmitStep({
          shipment: props.shipment,
          isCompany: formValues.billing_address.is_company,
          event: "begin_checkout",
          var_step: "Billing address",
          var_billing,
        });

      props.onNextStep();
      // process.env.REACT_APP_INFOBIP &&
      //   nextStepInCheckoutEventInfobip(
      //     "billing_address",
      //     4,
      //     getShortShipmentIdInfobip(props.shipment.object_id)
      //   );
    });
  };

  const disabledNextBtn = (): boolean => {
    // console.log("=disabledNextBtn=", formName === ShipmentFormNames.BillingAddress);

    if (formName === ShipmentFormNames.BillingAddress) {
      if (
        methods.watch("billing_address.first_name") &&
        methods.watch("billing_address.last_name") &&
        methods.getValues("billing_address.country_code") &&
        methods.getValues("billing_address.city_name") &&
        methods.getValues("billing_address.street_1") &&
        methods.watch("billing_address.postal_code") &&
        // methods.watch('billing_address.street_2') &&
        methods.watch("billing_address.phone") &&
        // methods.getValues('billing_address.email') &&
        (methods.watch("billing_address.is_company")
          ? methods.watch("billing_address.company_name") &&
            methods.watch("billing_address.company_ein")
          : methods.watch("billing_address.passport"))
      ) {
        // console.log("=staep 2=", countriesWithStates.includes(methods.getValues('billing_address.country_code')));

        if (
          countriesWithStates.includes(
            methods.getValues("billing_address.country_code")
          )
        ) {
          return methods.getValues("billing_address.state");
        }

        return true;
      }

      return false;
    }

    return methods.watch("billing_address");
  };

  const handleSelectAddressBook = (address: any) => {
    methods.setValue("billing_address.first_name", address.first_name);
    methods.setValue("billing_address.last_name", address.last_name);
    methods.setValue("billing_address.phone", address.phone);
    methods.setValue("billing_address.email", address.email);
    methods.setValue("billing_address.passport", address.passport);
    methods.setValue("billing_address.company_name", address.company_name);
    methods.setValue("billing_address.company_ein", address.company_ein);
    methods.setValue("billing_address.country_code", address.country_code);
    methods.setValue("billing_address.state", address.state);
    methods.setValue("billing_address.city_name", address.city_name);
    methods.setValue("billing_address.street_1", address.street_1);
    methods.setValue("billing_address.street_2", address.street_2);
    methods.setValue("billing_address.postal_code", address.postal_code);

    methods.setValue("billing_address.is_company", !!address.company_name);
    methods.setValue(
      "billing_address.country",
      getCountryName(address.country_code)
    );
  };

  const formIsActive = props.expanded === props.name;

  return (
    <AccordionCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={formIsActive}
            onChange={props.onChangeAccordion(props.name)}
            disabled={props.disabled}
          >
            <AccordionSummary disabled={props.disabled} expanded={formIsActive}>
              Billing address
            </AccordionSummary>

            <AccordionDetails>
              <RadioGroup
                row
                name="billingInfo"
                className={styles.formRadioGroup}
                onChange={handleChangeFormName}
                value={formName}
              >
                {isHaveDefaultBillingAddressBookAddress && (
                  <BaseRadio
                    name="billingInfo"
                    label="My default billing address"
                    value={ShipmentFormNames.DefaultBillingAddress}
                  />
                )}
                <BaseRadio
                  name="billingInfo"
                  label="Same as Pick up"
                  value="address_from"
                />
                <BaseRadio
                  name="billingInfo"
                  label="Same as Drop off"
                  value="address_to"
                />
                <BaseRadio
                  name="billingInfo"
                  label="Other"
                  value="billing_address"
                />
              </RadioGroup>

              {formName === ShipmentFormNames.BillingAddress && (
                <>
                  <ContactForm
                    formValues={methods.watch()}
                    methods={methods}
                    formIsActive={formIsActive}
                    change={(fieldName: any, value: any) => {
                      methods.setValue(fieldName, value);
                    }}
                    isCompanyChecked={isCompanyChecked}
                    formName="billing_address"
                    onSelectAddressBook={handleSelectAddressBook}
                  />
                </>
              )}

              <StyledActionFooter>
                {formName === ShipmentFormNames.BillingAddress && (
                  <>
                    <div>
                      <BaseCheckbox
                        style={{ marginRight: "0" }}
                        checked={isSaveAddress}
                        onChange={(e: any) => {
                          setIsSaveAddress(e.target.checked);
                        }}
                      />

                      <span>
                        Save this address in the{" "}
                        <a
                          style={{
                            color: "#284492",
                            fontWeight: "600",
                            textDecoration: "none",
                          }}
                          href="/address-book"
                        >
                          address book
                        </a>
                      </span>
                    </div>

                    {showPostalCodeValidationCheckbox && (
                      <BaseCheckbox
                        className={formStyles.formCheckboxWrapper}
                        checked={postalCodeValidation}
                        onChange={(e: any) =>
                          dispatch(
                            setHasSkippedValidationToBillingAddress(
                              !postalCodeValidation
                            )
                          )
                        }
                        label="The postal code of this address cannot be validated, but I know it is correct. Please use this address to call the courier."
                      />
                    )}
                  </>
                )}

                <BaseButton
                  className={styles.submitBtn}
                  loading={loading}
                  type="submit"
                  disabled={!disabledNextBtn()}
                >
                  NEXT STEP
                </BaseButton>
              </StyledActionFooter>

              {/* <DevTool control={methods.control} /> */}
            </AccordionDetails>
          </Accordion>
        </form>
      </FormProvider>
    </AccordionCard>
  );
};

export default BillingInfoCard;
