import * as React from "react";

export const Envelope: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M4 4.78c-.669 0-1.22.55-1.22 1.22v12c0 .669.551 1.22 1.22 1.22h16c.67 0 1.22-.551 1.22-1.22V6c0-.67-.55-1.22-1.22-1.22H4ZM1.22 6c0-1.531 1.25-2.78 2.78-2.78h16c1.532 0 2.78 1.249 2.78 2.78v12c0 1.53-1.248 2.78-2.78 2.78H4c-1.53 0-2.78-1.25-2.78-2.78V6Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M1.362 5.552a.78.78 0 0 1 1.086-.191l9.553 6.687 9.553-6.687a.78.78 0 0 1 .894 1.278l-10 7a.78.78 0 0 1-.895 0l-10-7a.78.78 0 0 1-.191-1.087Z"
      clipRule="evenodd"
    />
    <path
      fill="#FF5C5C"
      d="m8.957 12.643 3.128-3.128 3.127 3.128-3.127 3.127z"
    />
  </svg>
);
