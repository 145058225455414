import { CardWrapper } from "../CardWrapper";
import { EditedInput } from "../EditedInput";
import { InfoRow } from "../InfoRow";

type Props = {
  className?: string;
  onOpenSidebar(value: string): void;
};

const Password: React.FC<Props> = (props) => {
  return (
    <CardWrapper className={props.className} title="Password">
      <InfoRow
        label="Password"
        inputField={
          <EditedInput
            value="**********"
            fieldName="password"
            type="password"
            onEditIconClick={() => props.onOpenSidebar("password")}
          />
        }
      />
    </CardWrapper>
  );
};

export default Password;
