import { REDUCER_NAME } from "../constants";

export interface IModuleState {
  [REDUCER_NAME]: any;
}

export const selectCheckoutModule = (state: IModuleState) =>
  state[REDUCER_NAME];

export const selectCheckoutLoading = (state: IModuleState) =>
  selectCheckoutModule(state).loading;

export const selectShipment = (state: IModuleState) =>
  selectCheckoutModule(state).shipment;

export const selectBillingAddressForm = (state: IModuleState) =>
  selectShipment(state)?.billing_address;

export const selectBillingAddressFormErrors = (state: IModuleState) =>
  selectShipment(state)?.billing_address?.formErrors;

export const selectAddressFromFormErrors = (state: IModuleState) =>
  selectShipment(state)?.address_from?.formErrors;

export const selectAddressToFormErrors = (state: IModuleState) =>
  selectShipment(state)?.address_to?.formErrors;

export const selectAdditionalPayments = (state: IModuleState) =>
  selectShipment(state)?.additional_payments;

export const selectHasSkippedValidationFromAddressFrom = (
  state: IModuleState,
) =>
  selectCheckoutModule(state)?.shipment?.address_from?.has_skipped_validation;

export const selectPostalCodeFromAddressFrom = (state: IModuleState) =>
  selectCheckoutModule(state)?.shipment?.address_from?.postal_code;

export const selectHasSkippedValidationFromAddressTo = (state: IModuleState) =>
  selectCheckoutModule(state)?.shipment?.address_to?.has_skipped_validation;

export const selectPostalCodeFromAddressTo = (state: IModuleState) =>
  selectCheckoutModule(state)?.shipment?.address_to?.postal_code;

export const selectHasSkippedValidationFromBillingAddress = (
  state: IModuleState,
) =>
  selectCheckoutModule(state)?.shipment?.billing_address
    ?.has_skipped_validation;

export const selectPostalCodeFromBillingAddress = (state: IModuleState) =>
  selectCheckoutModule(state)?.shipment?.billing_address?.postal_code;

export const selectShipmentUnitOfMeasurement = (state: IModuleState) =>
  selectShipment(state)?.unit_of_measurement;

export const selectShipmentCurrency = (state: IModuleState) =>
  selectShipment(state)?.customs_declaration?.currency;

export const selectIsShowPaymentBtn = (state: IModuleState) =>
  selectCheckoutModule(state)?.isShowPaymentBtn;

export const selectShipmentCalendar = (state: IModuleState) =>
  selectShipment(state)?.pickup_details?.calendar;
