import * as React from "react";

export const Search: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M10.5 2.75a7.75 7.75 0 1 0 0 15.5 7.75 7.75 0 0 0 0-15.5ZM3.96 3.96a9.25 9.25 0 0 1 13.593 12.525l3.978 3.985-1.062 1.06-3.976-3.984A9.25 9.25 0 0 1 3.959 3.96Z"
      clipRule="evenodd"
    />
  </svg>
);
