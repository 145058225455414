import { styled } from "@mui/system";

import { BaseButton } from "../../../../components/BaseButton";
import { BaseIcon, Name } from "../../../../components/BaseIcon";
import BrandArrow from "../../../../assets/icons/brand-arrow.svg";

import { Breakpoints, media } from "../../../../css/breakpoints";
import brandColors from "../../../../css/brandColors.scss";
import styles from "./InfoBlock.scss";

const StyledRoot = styled("div")(
  () => `
  background: ${brandColors.mainBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${brandColors.whiteColor};
  height: 100%;
  overflow: hidden;
`,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 22px;
`,
);

const StyledDescription = styled("div")(
  () => `
  margin-bottom: 20px;
`,
);

const StyledList = styled("ul")(
  () => `
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`,
);

const StyledListItem = styled("li")(
  () => `
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`,
);

const StyledBrandArrowUp = styled("img")(
  () => `
  position: absolute;
  top: 0;
  right: -40px;
  width: 180px;

  @media ${media(Breakpoints.Tablet)} {
    display: none;
  }
`,
);

const StyledBrandArrowDown = styled("img")(
  () => `
  position: absolute;
  bottom: 0;
  left: -30px;
  width: 180px;

  @media ${media(Breakpoints.Tablet)}  {
    display: none;
  }
`,
);

type IProps = {
  onChangeAuthType: () => void;
  isSignUp: boolean;
  className?: string;
};

const InfoBlock = (props: IProps) => {
  return (
    <StyledRoot className={props.className}>
      <div>
        <StyledTitle>We take your parcel home!</StyledTitle>
        <StyledDescription>Authorized User Benefits:</StyledDescription>

        <StyledList>
          <StyledListItem>
            <BaseIcon
              className={styles.icon}
              size={20}
              name={Name.RedSquareInCircle}
            />
            <p>You will be the first to know about news</p>
          </StyledListItem>
          <StyledListItem>
            <BaseIcon
              className={styles.icon}
              size={20}
              name={Name.WhiteRhombusInRedCircle}
            />
            <p>Permanent bonuses</p>
          </StyledListItem>
          <StyledListItem>
            <BaseIcon
              className={styles.icon}
              size={20}
              name={Name.BlueSquareInCircle}
            />
            <p>Booking history in your account</p>
          </StyledListItem>
        </StyledList>

        <BaseButton
          className={styles.submitBtn}
          onClick={props.onChangeAuthType}
          secondaryBtn
        >
          {props.isSignUp ? "Sign in" : "Sign up"}
        </BaseButton>
      </div>

      <StyledBrandArrowUp src={BrandArrow} alt="" />
      <StyledBrandArrowDown src={BrandArrow} alt="" />
    </StyledRoot>
  );
};

export default InfoBlock;
