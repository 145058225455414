import * as React from "react";

export const Box: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M10.612 1.594a2.78 2.78 0 0 1 2.777 0h.002l7 4A2.78 2.78 0 0 1 21.78 8V16a2.783 2.783 0 0 1-1.39 2.406l-.003.001-6.997 3.999h-.002a2.78 2.78 0 0 1-2.777 0h-.001l-6.997-3.999-.003-.001A2.78 2.78 0 0 1 2.22 16V8a2.783 2.783 0 0 1 1.39-2.406l.003-.002 3.282-1.876a.78.78 0 0 1 .487-.278l3.228-1.844.001-.001ZM7.552 5.14l8.56 4.936a.78.78 0 0 0 .778-1.352l-7.77-4.48 2.268-1.297.003-.001a1.22 1.22 0 0 1 1.22 0l.003.001 6.997 3.999h.001a1.22 1.22 0 0 1 .609 1.055v7.998a1.22 1.22 0 0 1-.609 1.055h-.001l-6.997 3.999-.003.002a1.22 1.22 0 0 1-1.22 0l-7-4-.002-.001A1.22 1.22 0 0 1 3.781 16V8a1.22 1.22 0 0 1 .608-1.054h.002L7.55 5.14Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.723 4.415a.78.78 0 0 1 1.063-.293l8.1 4.6a.78.78 0 0 1-.77 1.356l-8.1-4.6a.78.78 0 0 1-.293-1.063Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M11.615 1.322a.78.78 0 0 1 .77 0l8.73 4.96a.78.78 0 0 1 .006 1.353l-8.34 4.825v9.62a.78.78 0 1 1-1.56 0v-9.62L2.88 7.635a.78.78 0 0 1 .005-1.353l8.73-4.96ZM12 11.109l7.162-4.143L12 2.897l-7.162 4.07L12 11.108Z"
      clipRule="evenodd"
    />
  </svg>
);
