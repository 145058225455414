import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

import brandColors from "../../../../../../../../../../../css/brandColors.scss";

export const StyledHeaderPromoCode = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCouponWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCoupon = styled("div")`
  max-width: 296px;
  width: 100%;
  border: 1px dashed #4ab6bd;
  border-radius: 8px;
  padding: 10px 16px;
  line-height: 130%;
  text-transform: capitalize;
  color: ${brandColors.mainBlue};
`;

export const StyledSpinner = styled(CircularProgress)`
  color: ${brandColors.mainBlue};
  width: 16px !important;
  height: 16px !important;
`;
