import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import clsx from "clsx";
import CloseIcon from "../../assets/icons/close.svg";
import { selectRtl } from "../../data/selectors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  padding: "40px",
  borderRadius: "24px",
  outline: "none",
  maxHeight: "calc(100vh - 40px)",
  display: "flex",
  flexDirection: "column",
};

const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 99999; */
  position: fixed;
  background: rgba(40, 68, 146, 0.4) !important;
  backdrop-filter: blur(8px);
`;

const Backdrop: any = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background: rgba(40, 68, 146, 0.4) !important;
  backdrop-filter: blur(8px);
`;

const StyledCloseIcon = styled("img")`
  position: absolute;
  /* right: 14px; */
  top: 14px;
  cursor: pointer;
`;

const BaseModal = ({ className, ...props }: any) => {
  let rtl = useSelector(selectRtl);

  return (
    // <Modal open={props.open} onClose={props.onClose} components={{ Backdrop }}>
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style} className={className}>
        <StyledCloseIcon
          style={{
            right: rtl ? "initial" : "14px",
            left: rtl ? "14px" : "initial",
          }}
          onClick={props.onClose}
          src={CloseIcon}
          alt=""
        />

        <div style={{ display: "contents" }}>
          <div style={{ overflowY: "auto", overflowX: "hidden" }}>
            {props.children}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default BaseModal;
