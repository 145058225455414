import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DevTool } from "@hookform/devtools";
import { setFormErrors } from "../../../../services/formErrors/setFormErrors";

import { styled } from "@mui/system";
import { Grid } from "@mui/material";

import { BaseButton } from "../../../../components/BaseButton";
import { HF } from "../../../../components";
import { AdditionalSignInBlock } from "../AdditionalSignInBlock";
import { AlreadySignBlock } from "../AlreadySignBlock";

import { SIGN_IN_TYPE, SIGN_UP_TYPE } from "../../data/constants";
import { selectFormErrors } from "../../../../data/selectors";

import { Breakpoints, media } from "../../../../css/breakpoints";
import brandColors from "../../../../css/brandColors.scss";
import { validationSchema } from "./services/validation";

import styles from "./SignUpForm.scss";

const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    align-items: flex-start;
  }
`;

const StyledPasswordNote = styled("div")`
  font-size: 12px;
  line-height: 18px;
  color: ${brandColors.mainBlue};
  width: 100%;
`;

const SignUpForm: React.FC<any> = ({ className, ...props }: any) => {
  const formErrors = useSelector(selectFormErrors);

  const handleChangeAuthType = () => {
    props.onChangeAuthType(SIGN_IN_TYPE);
  };

  const methods = useForm({
    mode: "all",
    resolver: validationSchema,
  });

  useEffect(() => {
    setFormErrors({ formErrors, methods });
  }, [formErrors]);

  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={methods.handleSubmit(props.onSubmit)}
        className={className}
      >
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={12} md={6}>
            <HF.Input name="first_name" label="First name" />
          </Grid>
          <Grid item xs={12} md={6}>
            <HF.Input name="last_name" label="Last name" />
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={12} md={6}>
            <HF.Input name="email" label="E-mail" type="email" />
          </Grid>
          <Grid item xs={12} md={6}>
            <HF.PhoneInput name="phone" />
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={12} md={6}>
            <HF.Input name="password" label="Password" type="password" />
          </Grid>

          <Grid item xs={12} md={6}>
            <HF.Input
              name="password_confirmation"
              label="Confirm Password"
              type="password"
            />
          </Grid>
        </Grid>

        <StyledPasswordNote>
          The password must be at least 6 characters, upper and lower case, and
          includes a number and a symbol.
        </StyledPasswordNote>

        <BaseButton
          loading={props.loading}
          className={styles.submitBtn}
          type="submit"
        >
          Sign up
        </BaseButton>

        <AlreadySignBlock
          type={SIGN_UP_TYPE}
          onChangeAuthType={() => handleChangeAuthType()}
        />

        <AdditionalSignInBlock type={SIGN_UP_TYPE} />

        <DevTool control={methods?.control} />
      </StyledForm>
    </FormProvider>
  );
};

export default SignUpForm;
