import { Name } from "../components/BaseIcon";

import { IMenuItem } from ".";
import { goToVue } from "../integrationWithVue/goToVue";

export const UNAUTH_MENU_ITEMS: IMenuItem[] = [
  // {
  //   label: 'About',
  //   to: '',
  //   icon: Name.OutlinedBrandBox
  // },
  // {
  //   label: 'FAQ',
  //   to: '',
  //   icon: Name.Rocket
  // },
  {
    label: {
      true: "בלוג",
      false: "Blog",
    },
    icon: Name.Blog,
    goTo: (event: any) => goToVue("/blog", event),
  },
  {
    label: {
      true: "עלינו",
      false: "About us",
    },
    icon: Name.AboutUs,
    goTo: (event: any) => goToVue("/about-us", event),
  },
  {
    label: {
      true: "יצירת קשר",
      false: "Contacts",
    },
    to: "/contacts",
    icon: Name.Phone,
  },
];
