import * as React from "react";

export const PDF: React.FC = () => (
  <svg
    viewBox="0 0 48 48"
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.14 6.222C29.21 5.278 28.55 5 27.233 5h-16.68C8.72 5 8 5.731 8 7.593v33.814C8 43.269 8.72 44 10.553 44h26.894C39.28 44 40 43.269 40 41.407V17.964c0-1.337-.273-2.006-1.203-2.95L30.14 6.222Zm7.626 34.82c0 .495-.192.69-.682.69H10.912c-.488 0-.681-.195-.681-.69l.001-8.39h27.534v8.39Zm-23.22-18.008v7.561h1.693v-2.74c.157.022.359.034.582.034 1.007 0 1.87-.25 2.452-.808.447-.431.693-1.07.693-1.82s-.325-1.387-.806-1.773c-.506-.408-1.256-.613-2.307-.613-1.042 0-1.78.068-2.307.16Zm6.504 7.527v-7.527c.627-.101 1.443-.159 2.307-.159 1.433 0 2.361.262 3.09.82.783.59 1.276 1.535 1.276 2.887 0 1.466-.526 2.478-1.253 3.104-.795.67-2.003.989-3.482.989-.885 0-1.513-.058-1.938-.113Zm12.216-3.048v-1.41.002h-2.71v-1.75h2.9v-1.422h-4.614v7.663h1.714v-3.083h2.71Zm4.502-6.615H10.235V7.96c0-.496.191-.69.681-.69h17.002c.244 0 .34.098.34.345v6.722c0 1.862.72 2.593 2.553 2.593h6.619c.245 0 .34.098.34.345l-.002 3.624Zm-19.493 4.41c0-.706-.481-1.126-1.331-1.126-.347 0-.582.034-.706.067v2.264c.146.033.326.045.572.045.905 0 1.465-.465 1.465-1.25Zm5.26-1.103c-.382 0-.627.034-.773.068l.001 5.015c.145.034.38.034.594.034 1.545.012 2.551-.853 2.551-2.684.012-1.592-.906-2.433-2.373-2.433Z"
    />
  </svg>
);
