import { BaseInput } from "../../../../../../../../components/BaseInput";
import { styled } from "@mui/system";

import styles from "./MoneyInputWithLeftLabel.scss";
import { digitsAndDecimal } from "../../../../../../../../components/BaseInput/BaseInput";

import brandColors from "../../../../../../../../css/brandColors.scss";
import { Breakpoints, media } from "../../../../../../../../css/breakpoints";

const Root = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  gap: 4px 8px;
  width: 100%;

  @media ${media(Breakpoints.Mobile)} {
    flex-direction: column;
    flex: 1 1 0;

    input {
      font-size: 16px !important;
      padding: 0px !important;
    }
  }
`;

const Label = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-top: 12px;

  @media ${media(Breakpoints.Mobile)} {
    margin-top: 0px;
  }
`;

const StyledUnit = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${brandColors.lightBlueColor};
`;

const MoneyInputWithLeftLabel = (props: any) => {
  const decimalScale = 2;

  return (
    <Root>
      <Label>{props.label}</Label>

      <BaseInput
        className={styles.moneyInput}
        moneyInput
        {...props}
        hint={props.hint}
        name={props.name}
        normalize={digitsAndDecimal(decimalScale, 99)}
        register={props.register}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        error={props.error}
        onBlur={props.onBlur}
        suffix={<StyledUnit>{props.unit}</StyledUnit>}
        disabled={props.disabled}
      />
      {/* <HF.Input
        className={styles.moneyInput}
        moneyInput
        name={props.name}
        hint={props.hint}
        maxLength={4}
      /> */}
    </Root>
  );
};

export default MoneyInputWithLeftLabel;
