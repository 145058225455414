import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../../../../components/BaseIcon";
import { StyledTooltip } from "../../../../../../../../components/TooltipBase.styled";

export const MessageProhibitedItemsTooltip = () => {
  return (
    <StyledTooltip title="It is your responsibility to make sure the items you send comply with the import regulations in the destination country, and that items are not in the list of prohibited and restricted items">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={24} />
      </IconButton>
    </StyledTooltip>
  );
};
