// import { createSelector } from 'reselect';

import { IAuth } from "../../interfaces";
import { REDUCER_NAME } from "../constants";

export interface IModuleState {
  [REDUCER_NAME]: IAuth;
}

export const selectAuthModule = (state: IModuleState) => state[REDUCER_NAME];

export const selectIsAuth = (state: IModuleState) =>
  selectAuthModule(state).isAuth;

export const selectIsResetTokenValid = (state: IModuleState) =>
  selectAuthModule(state).isResetTokenValid;
