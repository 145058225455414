import * as React from "react";

export const Close: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.423 11.484 6 15.906l1.591 1.591 4.423-4.422 4.423 4.423 1.591-1.591-4.423-4.423 3.893-3.894L15.907 6l-3.893 3.893L8.12 6l-1.59 1.59 3.892 3.894Z"
      fill="currentColor"
    />
  </svg>
);
