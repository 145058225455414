import { createAction } from "redux-actions";

import { REDUCER_NAME } from "./constants";

export const showLoader = createAction(`${REDUCER_NAME}/SHOW_LOADER`);

export const hideLoader = createAction(`${REDUCER_NAME}/HIDE_LOADER`);

export const setUser = createAction(`${REDUCER_NAME}/SET_USER`);

export const getUser = createAction(`${REDUCER_NAME}/GET_USER`);

export const setFormErrors = createAction(`${REDUCER_NAME}/SET_FORM_ERRORS`);

export const clearFormErrors = createAction(
  `${REDUCER_NAME}/CLEAR_FORM_ERRORS`,
);

export const changeUnitSystem = createAction(
  `${REDUCER_NAME}/CHANGE_UNIT_SYSTEM`,
);

export const getShipmentAdditionalPayments = createAction(
  `${REDUCER_NAME}/GET_SHIPMENT_ADDITIONAL_PAYMENTS`,
);

export const sendCoupon = createAction(`${REDUCER_NAME}/SEND_COUPON`);

export const deleteCoupon = createAction(`${REDUCER_NAME}/DELETE_COUPON`);

export const setCouponLoading = createAction(
  `${REDUCER_NAME}/SET_COUPON_LOADING`,
);

export const getShipment = createAction(`${REDUCER_NAME}/GET_SHIPMENT`);

export const setDomesticShipping = createAction(
  `${REDUCER_NAME}/SET_DOMESTIC_SHIPPING`,
);

export const setRtl = createAction(`${REDUCER_NAME}/SET_RTL`);

export const setLocale = createAction(`${REDUCER_NAME}/SET_LOCALE`);
