import { BaseInput } from "../BaseInput";
import { styled } from "@mui/system";
import styles from "./MoneyInputWithLeftLabel.scss";
import { digitsAndDecimal } from "../BaseInput/BaseInput";
import brandColors from "../../css/brandColors.scss";
import { Breakpoints, media } from "../../css/breakpoints";

const Root = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  gap: 8px;

  @media ${media(Breakpoints.Mobile)} {
    width: 100%;
  }
`;

const Label = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;

  @media ${media(Breakpoints.Mobile)} {
    margin-top: 0px;
  }
`;

const StyledUnit = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${brandColors.lightBlueColor};
`;

export const MoneyInputWithLeftLabel = (props: any) => {
  const decimalScale = 2;

  return (
    <Root>
      <Label>{props.label}</Label>

      <BaseInput
        className={styles.moneyInput}
        moneyInput
        {...props}
        hint={props.hint}
        name={props.name}
        normalize={digitsAndDecimal(decimalScale, 99)}
        register={props.register}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        error={props.error}
        onBlur={props.onBlur}
        suffix={<StyledUnit>{props.unit}</StyledUnit>}
        disabled={props.disabled}
      />
    </Root>
  );
};
