import { Breakpoints } from "../../css/breakpoints";

export const PASSWORD_LENGTH = 6;

export const SPECIAL_CHARS = "!@#$%^&*+=";

export const PASSWORD_REG_EXP = new RegExp(
  `(?=.*[0-9])(?=.*[${SPECIAL_CHARS}])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z${SPECIAL_CHARS}]{${PASSWORD_LENGTH},}`,
);

export const isValidPassword = (value?: string): boolean =>
  PASSWORD_REG_EXP.test(value ? value.trim() : "");

export const getMinValidationMessage = (value: number): string => {
  const currentBreakpoint =
    "getUiBreakpoint" in globalThis ? globalThis.getUiBreakpoint() : "";

  return (
    (currentBreakpoint === Breakpoints.Mobile ? "Min-" : "Min value is ") +
    value
  );
};

export const getMaxValidationMessage = (
  value: number,
  isRtl?: boolean,
): string => {
  const currentBreakpoint =
    "getUiBreakpoint" in globalThis ? globalThis.getUiBreakpoint() : "";

  return currentBreakpoint === Breakpoints.Mobile
    ? isRtl
      ? "עד "
      : "Max-"
    : (isRtl ? "עד " : "Max value is ") + value;
};
