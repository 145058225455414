import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectRtl } from "../../../../data/selectors";

import { Container } from "@mui/material";
import styles from "./MobileMenu.scss";

import { BaseLink } from "../../../BaseLink";
import { Localization } from "../../../localization/Localization";

import { FOOTER_LINKS } from "../../../../constants/FooterLinks";
import { IMenuItem } from "../../../../constants";
import { AUTH_MENU_ITEMS } from "../../../../constants/AuthMenuLinks";
import { UNAUTH_MENU_ITEMS } from "../../../../constants/UnauthMenuLinks";
import { useGetMenuActions } from "../../../../services/useGetMenuActions";

import ManInCircleImage from "../../../../assets/images/man-in-circle.svg";
import BrandIcon from "../../../../assets/icons/brand-arrow.svg";
import {
  StyledAvatar,
  StyledAvatarIcon,
  StyledNameAndProfile,
  StyledAvatarText,
  StyledProfileBtn,
  StyledAvatarWrapper,
  StyledFooterLink,
  StyledMenuItem,
  StyledMenuItemIcon,
  StyledMenuItemText,
  StyledMenuList,
  StyledMenuListLine,
  StyledMobileMenu,
} from "./styles/MobileMenu.styled";
import { Name } from "../../../../components/BaseIcon";
import { useIsResponsive } from "../../../../services/breakpoint/useIsResponsive";

// const editProfile = {
//   he: 'ערוך פרופיל',
//   en: 'Edit profile',
// }

const MobileMenu = (props: any) => {
  let rtl = useSelector(selectRtl);
  if (process.env.REACT_APP_IS_MOBILE_APP === "true") {
    if (!globalThis.location.hash.startsWith("#/he")) {
      rtl = false;
    }
  } else if (
    globalThis.location.pathname !== "/" &&
    !globalThis.location.pathname.startsWith("/he")
  ) {
    rtl = false;
  }
  // console.log(
  //   "=globalThis.location.pathname=",
  //   rtl,
  //   globalThis.location.pathname,
  // );

  const location = useLocation();

  const { getMenuActions } = useGetMenuActions();

  const { isMobile } = useIsResponsive();

  const getMenuItems = () => {
    const items: any = [];

    if (props.isAuth) {
      items.push(AUTH_MENU_ITEMS);
    }

    items.push(UNAUTH_MENU_ITEMS);
    process.env.REACT_APP_USERWAY_WIDGET &&
      isMobile &&
      items.push([
        {
          label: {
            true: "נְגִישׁוּת",
            false: "Accessibility",
          },
          icon: Name.Accessibility,
          id: "accessibility-widget",
          color: "rgb(40, 68, 146)",
        },
      ]);

    // console.log("==", items);

    return items;
  };

  const handleMenuItemClick = (event: any, menuItem: IMenuItem): void => {
    getMenuActions(event, menuItem);

    handleCloseMenu();
  };

  const handleCloseMenu = (): void => {
    props.onClose();
  };

  const filteredFooterLinks: any = FOOTER_LINKS.filter(
    (link: any) => link.to !== "/contacts"
  );

  const isShowLanguage =
    (location.pathname.length === 3 && location.pathname.startsWith("/he")) ||
    (location.pathname.length === 3 && location.pathname.startsWith("/tr")) ||
    (location.pathname.length === 4 && location.pathname.startsWith("/he/")) ||
    (location.pathname.length === 4 && location.pathname.startsWith("/tr/")) ||
    location.pathname.length === 1;
  // console.log("=isShowLanguage=", isShowLanguage);

  return (
    <StyledMobileMenu isOpen={props.isOpen}>
      <StyledAvatarWrapper>
        <BaseLink
          onClick={handleCloseMenu}
          to={props.isAuth ? "/profile" : "/auth"}
        >
          <Container
            sx={{ display: "flex", alignItems: "center", gap: "16px" }}
          >
            <StyledAvatar isAuth={!!props.userName}>
              <StyledAvatarIcon
                src={props.userName ? ManInCircleImage : BrandIcon}
                isAuth={!!props.userName}
                alt=""
              />
            </StyledAvatar>

            <StyledNameAndProfile>
              <StyledAvatarText>
                {props.isAuth ? props.userName : rtl ? "התחברות" : "Log in"}
              </StyledAvatarText>

              {props.isAuth && (
                <StyledProfileBtn>
                  {/* {editProfile[innerLocale]} */}
                  {rtl ? "ערוך פרופיל" : "Edit profile"}
                </StyledProfileBtn>
              )}
            </StyledNameAndProfile>
          </Container>
        </BaseLink>
      </StyledAvatarWrapper>

      {getMenuItems().map((menuItems: any, index: number) => (
        <StyledMenuList key={index}>
          {menuItems.map((menuItem: any) => (
            <StyledMenuItem
              onClick={(event) => handleMenuItemClick(event, menuItem)}
              key={menuItem.to}
            >
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "13px 0",
                  gap: "24px",
                }}
                id={menuItem.id}
              >
                <StyledMenuItemIcon
                  name={menuItem.icon}
                  color={menuItem?.color}
                />
                <StyledMenuItemText>
                  {menuItem.label[String(rtl)]}
                </StyledMenuItemText>
              </Container>
            </StyledMenuItem>
          ))}

          <Container>
            <StyledMenuListLine />
          </Container>
        </StyledMenuList>
      ))}

      {isShowLanguage && (
        <Container>
          <StyledFooterLink>
            <Localization
              className={styles.localization}
              onCloseMenu={handleCloseMenu}
            />
          </StyledFooterLink>
        </Container>
      )}

      {filteredFooterLinks.map((link: any, index: number) => (
        <Container key={index}>
          <BaseLink onClick={handleCloseMenu} to={link.to}>
            <div dir={link.noRtl ? "ltr" : ""}>
              <StyledFooterLink
                style={{ textAlign: rtl ? "right" : "initial" }}
              >
                {link.label[String(rtl)]}
              </StyledFooterLink>
            </div>
          </BaseLink>
        </Container>
      ))}
    </StyledMobileMenu>
  );
};

export default MobileMenu;
