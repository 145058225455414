import React from "react";
import { useDispatch } from "react-redux";
import { isDate } from "date-fns";

import styles from "./OrderDataCard.scss";
import {
  StyledDividerLine,
  StyledLabel,
  StyledListDescriptionWrapper,
  StyledListItemLabel,
  StyledListItemValue,
  StyledListTitle,
  StyledListTitleWrapper,
} from "./styles/OrderDataCard.styled";
import { useSelector } from "react-redux";
import { selectShipment } from "../../../../../../data/selectors";
import { BaseCard } from "../../../../../../../../components/BaseCard";
import { BaseIcon, Name } from "../../../../../../../../components/BaseIcon";
import cn from "classnames";
import { getTotalPackagesWeight } from "../../../../../../../../services/getTotalPackagesWeight";
import { selectShipmentUnitOfMeasurement } from "../../../../../../data/selectors";
import { METRIC_SYSTEM_TYPE } from "../../../../../../../home/data/constants";
import { format } from "date-fns";
import { getShipment } from "../../../../../../../../data/actions";
import { parcelTypes } from "../../../../../../../home/data/constants";
import { selectIsDomesticShipping } from "../../../../../../../../data/selectors";
import { addToStringViaCharacter } from "../../../../../../../../services/addToStringViaCharacter";
import { getProhibitedItems } from "../../../../../../../../services/getProhibitedItems";
import { MessageProhibitedItems } from "./MessageProhibitedItems";
import { useIsResponsive } from "../../../../../../../../services/breakpoint/useIsResponsive";
import { parcelEditingCard } from "../../../../Form";

type Props = {
  className: string;
  roundingRankOfItemWeightInParcelInPackageDetails: number;
  onPrevStep(cardName: string): void;
};

const OrderDataCard: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const shipment = useSelector(selectShipment);

  const unitSystem = useSelector(selectShipmentUnitOfMeasurement);

  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  const prohibitedItemsLink = getProhibitedItems(
    shipment?.chosen_rate?.provider?.id,
    shipment?.address_from?.country_code,
    shipment?.address_to?.country_code,
  );

  const { isMobileDevice } = useIsResponsive();

  const getWeightDimension = () =>
    unitSystem === METRIC_SYSTEM_TYPE ? "kg" : "lb";

  const getContactLabel: any = (formValues: any) => {
    let contactLabel = `${formValues?.last_name} ${formValues?.first_name}, ${formValues?.phone}`;
    formValues?.passport && (contactLabel += `, ${formValues.passport}`);
    formValues?.company_name &&
      (contactLabel += `, ${formValues.company_name}`);

    return contactLabel;
  };

  const getAddressLabel: any = (formValues: any) => {
    let addressLabel = addToStringViaCharacter("", formValues.street_1);

    addressLabel = addToStringViaCharacter(addressLabel, formValues.street_2);
    addressLabel = addToStringViaCharacter(
      addressLabel,
      formValues.postal_code,
    );
    addressLabel = addToStringViaCharacter(addressLabel, formValues.city_name);
    addressLabel = addToStringViaCharacter(addressLabel, formValues.state);
    addressLabel = addToStringViaCharacter(addressLabel, formValues.country);

    return addressLabel;
  };

  const addressesSections = [
    {
      title: "Pickup details",
      fieldKey: "address_from",
      returnName: "pickupDetailsCard",
    },
    {
      title: "Drop off details",
      fieldKey: "address_to",
      returnName: "dropOffDetailsCard",
    },
    {
      title: "Billing details",
      fieldKey: "billing_address",
      returnName: "billingAddress",
    },
  ];

  return (
    <BaseCard className={cn(props.className, styles.card)}>
      <StyledLabel>Shipping details</StyledLabel>

      <StyledDividerLine />

      <StyledListTitleWrapper>
        <StyledListTitle>Courier details</StyledListTitle>
      </StyledListTitleWrapper>

      <StyledListDescriptionWrapper>
        <StyledListItemLabel>Courier</StyledListItemLabel>
        <StyledListItemValue>
          {shipment?.chosen_rate?.provider?.name}
        </StyledListItemValue>
      </StyledListDescriptionWrapper>

      <StyledListDescriptionWrapper>
        <StyledListItemLabel>Service</StyledListItemLabel>
        <StyledListItemValue>
          {shipment?.chosen_rate?.service_level?.name}
        </StyledListItemValue>
      </StyledListDescriptionWrapper>

      <StyledDividerLine />

      {addressesSections.map((addressSection) => (
        <>
          <StyledListTitleWrapper>
            <StyledListTitle>{addressSection.title}</StyledListTitle>

            <BaseIcon
              className={styles.pencilIcon}
              name={Name.Pencil}
              onClick={() => props.onPrevStep(addressSection.returnName)}
              isButton
            />
          </StyledListTitleWrapper>

          {shipment?.[addressSection.fieldKey] && (
            <StyledListDescriptionWrapper>
              <StyledListItemLabel>Contact</StyledListItemLabel>
              <StyledListItemValue>
                {getContactLabel(shipment[addressSection.fieldKey])}
              </StyledListItemValue>
            </StyledListDescriptionWrapper>
          )}

          {shipment?.[addressSection.fieldKey] && (
            <StyledListDescriptionWrapper>
              <StyledListItemLabel>Address</StyledListItemLabel>
              <StyledListItemValue>
                {getAddressLabel(shipment[addressSection.fieldKey])}
              </StyledListItemValue>
            </StyledListDescriptionWrapper>
          )}

          {addressSection.fieldKey === "address_from" &&
            shipment?.pickup_details?.instructions && (
              <StyledListDescriptionWrapper>
                <StyledListItemLabel>Courier instruction</StyledListItemLabel>
                <StyledListItemValue>
                  {shipment?.pickup_details?.instructions}
                </StyledListItemValue>
              </StyledListDescriptionWrapper>
            )}

          <StyledDividerLine />
        </>
      ))}

      <StyledListTitleWrapper>
        <StyledListTitle>Parcel details</StyledListTitle>

        <BaseIcon
          className={styles.pencilIcon}
          name={Name.Pencil}
          onClick={() => props.onPrevStep(parcelEditingCard)}
          isButton
        />
      </StyledListTitleWrapper>

      <StyledListDescriptionWrapper>
        <StyledListItemLabel>Type and quantity</StyledListItemLabel>
        <StyledListItemValue>
          {shipment?.parcels?.length}{" "}
          {
            parcelTypes[
              shipment?.is_customs_declarable
                ? shipment?.is_pallet
                  ? 2
                  : 1
                : 0
            ].value
          }
          {shipment?.parcels?.length > 1 ? "s" : ""}
        </StyledListItemValue>
      </StyledListDescriptionWrapper>

      <StyledListDescriptionWrapper>
        <StyledListItemLabel>Total weight</StyledListItemLabel>
        <StyledListItemValue>
          {getTotalPackagesWeight(shipment?.parcels)} {getWeightDimension()}
        </StyledListItemValue>
      </StyledListDescriptionWrapper>

      {!(isDomesticShipping || !shipment?.is_customs_declarable) && (
        <>
          <StyledDividerLine />

          <StyledListTitleWrapper>
            <StyledListTitle>Customs details</StyledListTitle>

            <BaseIcon
              className={styles.pencilIcon}
              name={Name.Pencil}
              onClick={() => props.onPrevStep("packageDetailsCard")}
              isButton
            />

            {!isMobileDevice && prohibitedItemsLink && (
              <MessageProhibitedItems
                className={styles.messageProhibitedItemsOrderDataCard}
                prohibitedItemsLink={prohibitedItemsLink}
              />
            )}
          </StyledListTitleWrapper>

          {shipment?.customs_declaration?.items.map(
            (item: any, index: number) => (
              <StyledListDescriptionWrapper>
                <StyledListItemLabel>{`Item #${index + 1}`}</StyledListItemLabel>
                <StyledListItemValue>
                  {item.description}&emsp;
                  {item.quantity}&nbsp;
                  <span style={{ color: "#a8b6d2" }}>pcs</span>&emsp;
                  {item.item_weight}&nbsp;
                  <span style={{ color: "#a8b6d2" }}>
                    {getWeightDimension()}
                  </span>
                  &emsp;
                  {item.item_value}
                  <span style={{ color: "#a8b6d2" }}>
                    {" "}
                    {shipment?.customs_declaration?.currency}
                  </span>
                </StyledListItemValue>
              </StyledListDescriptionWrapper>
            ),
          )}

          <StyledListDescriptionWrapper>
            <StyledListItemLabel>Total weight</StyledListItemLabel>
            <StyledListItemValue>
              {shipment?.customs_declaration?.items
                .reduce((totalWeigh: number, item: any) => {
                  totalWeigh += item.quantity * item.item_weight;
                  return totalWeigh;
                }, 0)
                .toFixed(
                  props.roundingRankOfItemWeightInParcelInPackageDetails,
                )}

              <span style={{ color: "#a8b6d2" }}> {getWeightDimension()}</span>
            </StyledListItemValue>
          </StyledListDescriptionWrapper>

          <StyledListDescriptionWrapper>
            <StyledListItemLabel>Total value</StyledListItemLabel>
            <StyledListItemValue>
              {shipment?.customs_declaration?.items
                .reduce((acc: number, item: any) => {
                  acc += item.quantity * item.item_value;
                  return acc;
                }, 0)
                .toFixed(2)}

              <span style={{ color: "#a8b6d2" }}>
                {" "}
                {shipment?.customs_declaration?.currency}
              </span>
            </StyledListItemValue>
          </StyledListDescriptionWrapper>

          {isMobileDevice && prohibitedItemsLink && (
            <MessageProhibitedItems prohibitedItemsLink={prohibitedItemsLink} />
          )}
        </>
      )}

      <StyledDividerLine />

      <StyledListTitleWrapper>
        <StyledListTitle>Pickup date details</StyledListTitle>

        <BaseIcon
          className={styles.pencilIcon}
          name={Name.Pencil}
          onClick={() => {
            props.onPrevStep("pickupDateCard");
            dispatch(getShipment(shipment?.object_id));
          }}
          isButton
        />
      </StyledListTitleWrapper>

      <StyledListDescriptionWrapper>
        <StyledListItemLabel>Date</StyledListItemLabel>
        <StyledListItemValue>
          {format(
            new Date(shipment?.pickup_details?.ready_time ?? null),
            "yyyy-MM-dd",
          )}
        </StyledListItemValue>
      </StyledListDescriptionWrapper>

      <StyledListDescriptionWrapper>
        <StyledListItemLabel>Time frame</StyledListItemLabel>
        <StyledListItemValue>
          {`${
            isDate(shipment?.pickup_details?.ready_time)
              ? format(
                  new Date(shipment?.pickup_details?.ready_time ?? null),
                  "HH:mm",
                )
              : shipment?.pickup_details?.ready_time
                  ?.split("T")?.[1]
                  ?.substr(0, 5)
          }  - ${
            isDate(shipment?.pickup_details?.close_time)
              ? format(
                  new Date(shipment?.pickup_details?.close_time ?? null),
                  "HH:mm",
                )
              : shipment?.pickup_details?.close_time
                  ?.split("T")?.[1]
                  ?.substr(0, 5)
          }`}
        </StyledListItemValue>
      </StyledListDescriptionWrapper>

      {!!shipment?.additional_payments?.additional_charges?.charges_total && (
        <>
          <StyledDividerLine />

          <StyledListTitleWrapper>
            <StyledListTitle>
              Additional charges (for reference only)
            </StyledListTitle>
          </StyledListTitleWrapper>

          <StyledListDescriptionWrapper>
            <StyledListItemValue>
              {shipment?.additional_payments?.currency}{" "}
              {shipment.additional_payments.additional_charges.charges_total?.toFixed(
                2,
              )}
            </StyledListItemValue>

            <div className={styles.TotalChargesMessage}>
              <BaseIcon name={Name.Info} size={20} />
              <div className={styles.TotalChargesMessageText}>
                The amount that includes taxes, duties, and fees associated with
                the delivery to the destination country. Please note that this
                value is approximate and may vary depending on the requirements
                and policies of the destination country. For reference only.
              </div>
            </div>
          </StyledListDescriptionWrapper>
        </>
      )}
    </BaseCard>
  );
};

export default OrderDataCard;
