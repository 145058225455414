import { put } from "redux-saga/effects";
import { setFormErrors } from "../../data/actions";

export function* checkFormErrors({
  response,
  reject,
  setFormErrorAction,
  withoutFormErrors,
}: any) {
  const responseError = response.data?.error;

  // console.log("=checkFormErrors=", responseError);

  const actionError = setFormErrorAction || setFormErrors;

  if (responseError) {
    if (!withoutFormErrors) {
      yield put(actionError(responseError));
    }

    if (reject) {
      reject(responseError);
    }

    throw responseError;
  }
}
