import { useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { Container } from "@mui/material";
import { selectRtl } from "../../data/selectors";

import styles from "./TheFooter.scss";
import { FOOTER_LINKS } from "../../constants/FooterLinks";
import { BaseLink } from "../BaseLink";
import { Localization } from "../localization/Localization";

const TheFooterWrapper = styled("footer")(
  () => `
  background: #fff;
  border-top: 1px solid #E5E9F3;
  padding-top: 12px;
  padding-bottom: 13px;
  position: absolute;
  z-index: 1200;
  width: 100%;
 `,
);

const FooterContent = styled("div")(
  () => `
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
 `,
);

const LeftColumn = styled("div")(
  () => `
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
 `,
);

const RightColumn = styled("div")(
  () => `
  display: flex;
  position: relative;

  & > div:nth-of-type(2) {
    margin: 0 24px;
    padding: 0 24px;
    position: relative;

    @media (max-width: 991px) {
      padding-right: 0;
    }

   &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0px;
    display: block;
    width: 1px;
    height: 20px;
    background: rgba(29, 69, 150, 0.4);
   }

   &::after {
    content: '';
    position: absolute;
    top: 7px;
    right: 0px;
    display: block;
    width: 1px;
    height: 20px;
    background: rgba(29, 69, 150, 0.4);

    @media (max-width: 991px) {
      display: none;
    }
   }
  }
 `,
);

const Text = styled("div")(
  () => `
  line-height: 150%;
  font-size: 14px;
  color: #A8B6D2;
  transition: all 0.2s ease-in;

  &:hover {
    color: #284492;
  }
 `,
);

// const currencyOptions = [
//   {
//     value: 'usd',
//     label: '$USD'
//   },
//   {
//     value: 'ils',
//     label: '₪ILS'
//   }
// ]

const TheFooter = () => {
  const location = useLocation();
  let rtl = useSelector(selectRtl);
  // const [currencyValue, setCurrencyValue] = useState('usd')

  if (process.env.REACT_APP_IS_MOBILE_APP === "true") {
    if (!window.location.hash.startsWith("#/he")) {
      rtl = false;
    }
  } else if (
    window.location.pathname !== "/" &&
    !window.location.pathname.startsWith("/he")
  ) {
    rtl = false;
  }

  // console.log("=locale=", rtl);

  // console.log("=location=", location.pathname, location.pathname.length, location.pathname.startsWith('/he'));
  const isShowLanguage =
    (location.pathname.length === 3 && location.pathname.startsWith("/he")) ||
    (location.pathname.length === 3 && location.pathname.startsWith("/tr")) ||
    (location.pathname.length === 4 && location.pathname.startsWith("/he/")) ||
    (location.pathname.length === 4 && location.pathname.startsWith("/tr/")) ||
    location.pathname.length === 1;
  // console.log("=isShowLanguage=", isShowLanguage);

  return (
    <TheFooterWrapper>
      <Container>
        <FooterContent>
          <LeftColumn>
            {FOOTER_LINKS.map((link, index) => (
              <div dir={link.noRtl ? "ltr" : "rtl"}>
                <BaseLink to={link.to} key={index}>
                  <Text>{link.label[String(rtl)]}</Text>
                </BaseLink>
              </div>
            ))}
          </LeftColumn>

          <RightColumn>
            {/* <BaseSelect
              className={styles.select}
              value={currencyValue}
              onChange={value => setCurrencyValue(value)}
              options={currencyOptions}
            />
            */}

            {isShowLanguage && <Localization />}

            {/* <BaseSelect
              value="FAQ & Help Centre"
              onClick={() => handleOpenFooter()}
              withCustomEvent
            /> */}
          </RightColumn>
        </FooterContent>
      </Container>
    </TheFooterWrapper>
  );
};

export default TheFooter;
