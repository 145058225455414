import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../components/BaseIcon";
import { StyledTooltip } from "../../../../../components/TooltipBase.styled";

export const CommercialInvoiceTooltipPackageDetails = () => {
  return (
    <StyledTooltip title="If you have a commercial invoice it is best to upload it. Very important that the invoice shows the details of all items (as detailed as possible, do not use short descriptions like “samples” as it will not pass customs). If you have more than one page, or have in addition a Packing List, please merge the files into one PDF file. Do not upload pictures of the product, only a printed invoice.">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={24} />
      </IconButton>
    </StyledTooltip>
  );
};
