import React, { useEffect, useState } from "react";
// import { CSSTransition } from 'react-transition-group'
// import { SizeHint } from '../../../SizeHint'
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { selectRtl } from "../../../../../../data/selectors";

import {
  StyledContainer,
  StyledInputGroupWrapper,
  StyledParcelSizesWrapper,
  TextInCard,
  StyledDeleteIcon,
} from "../Form.styled";
import {
  StyledCitySelectionFieldsSection,
  StyledSwapCityBtn,
} from "./DesktopForm.styled";
import stylesDesktopForm from "./DesktopForm.scss";
import formStyles from "../../styles/Form.scss";
import stylesFormHome from "../../formHome.scss";
import { BaseCard } from "../../../../../../components/BaseCard";
import { CounterButton } from "../../../../../home/components/Form/components/CounterButton";
import {
  getParcelTypeOptions,
  SEARCH_FROM_INPUT_PLACEHOLDER,
  SEARCH_FROM_INPUT_PLACEHOLDER_RTL,
  SEARCH_TO_INPUT_PLACEHOLDER,
  SEARCH_TO_INPUT_PLACEHOLDER_RTL,
  SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC,
  SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC_RTL,
  SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC,
  SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC_RTL,
} from "../../../../data/constants";
import { HF } from "../../../../../../components";
import { BrandPlates } from "../../../../../../components/BrandPlates";
import { AddNewMobileButton } from "../AddNewMobileButton";
import { maximumNumberOfItemsInParcel } from "../../Form";
import { BaseSearchInput } from "../../../../../../components/BaseSearchInput/BaseSearchInput";
import { ArrayComponent } from "../ArrayComponent";
import { BaseButton } from "../../../../../../components/BaseButton";
import { getDisabledSubmitBtn } from "../../services/getDisabledSubmitBtn";
import { MetricSystemBtns } from "../MetricSystemBtns";
import { TopHomePage } from "../../TopHomePage";
import { BaseIcon, Name } from "../../../../../../components/BaseIcon";
import { isObject } from "../../../../../../services/isObject";
import { useIsResponsive } from "../../../../../../services/breakpoint/useIsResponsive";
import { CountrySelect } from "../../../../../../components/CountrySelect";
import { selectIsDomesticShipping } from "../../../../../../data/selectors";
import { domesticCountriesCodes } from "../../../../../../constants";
import { EnvelopeSizes } from "../../../../data/constants";
import { setPackageValues } from "../../services/setPackageValues";
// import shipmentTypeEventInfobip from "../../../../../../plugins/infobip/shipmentTypeEventInfobip";
// import addressFromEventInfobip from "../../../../../../plugins/infobip/addressFromEventInfobip";
// import addressToEventInfobip from "../../../../../../plugins/infobip/addressToEventInfobip";

const DesktopForm: React.FC<any> = (props) => {
  const methods = useFormContext();
  const rtl = useSelector(selectRtl);

  useEffect(() => {
    disabledSubmitBtn();
  }, [methods]);

  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);

  const disabledSubmitBtn = (): void => {
    setIsDisabledSubmitBtn(
      !getDisabledSubmitBtn({
        addressFromInputError: props.addressFromInputError,
        addressToInputError: props.addressToInputError,
        methods,
      })
    );
  };

  const { isMobileDevice } = useIsResponsive();

  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  return (
    <StyledContainer>
      {/* Закомментировано, в связи с ненадобностью на данный момент, не удалять пока */}
      {/* <CSSTransition
        in={!!props.count}
        timeout={300}
        classNames={{
          enter: formStyles.TransitionEnter,
          enterActive: formStyles.TransitionEnterActive,
          exit: formStyles.TransitionExit,
          exitActive: formStyles.TransitionExitActive
        }}
        unmountOnExit
        mountOnEnter
      >
        <SizeHint className={formStyles.sizeHint} />
      </CSSTransition> */}

      <TopHomePage
        onChangeDomesticShipping={
          props.onSwitchingBetweenInternationalAndDomestic
        }
      />

      <div className={stylesFormHome.inputSection}>
        <StyledInputGroupWrapper>
          <BaseCard
            className={`${formStyles.card} ${stylesFormHome.shadowToCitySelectionField} ${stylesDesktopForm["package-select-wrapper"]}`}
            padding={"3px 18px"}
          >
            <TextInCard>אני רוצה לשלוח</TextInCard>

            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <CounterButton
                className={formStyles.counterBtn}
                value={props.count}
                onSub={props.remove}
                onAdd={props.onChangeCounterAdd}
              />

              <HF.Select
                name="parcelType"
                options={getParcelTypeOptions()}
                className={formStyles.select}
                defaultValue={methods.getValues("parcelType")}
                renderSelectedComponentWithIcon
                onChange={(payload: any) => {
                  // console.log("=onChange=", payload, methods.getValues('parcelType'));

                  methods
                    .getValues("packages")
                    .forEach((item: any, index: number) => {
                      methods.setValue(
                        `packages[${index}].envelopeSize`,
                        EnvelopeSizes.custom
                      );
                    });

                  // process.env.REACT_APP_INFOBIP &&
                  //   shipmentTypeEventInfobip(payload);
                }}
              />
            </div>
          </BaseCard>

          {isDomesticShipping && (
            <BaseCard
              className={`${formStyles.card} ${stylesFormHome.shadowToCitySelectionField} ${stylesDesktopForm["country-select-wrapper"]}`}
              padding="0"
            >
              <CountrySelect
                className={formStyles.CountrySelect}
                countryCode={methods.watch("domesticCountryCode")}
                onSelectedCountry={props.onChangeCountry}
                allowedCountriesCodes={domesticCountriesCodes}
              />
            </BaseCard>
          )}

          <StyledCitySelectionFieldsSection>
            <BaseCard
              className={
                formStyles.card +
                ` ${stylesFormHome.shadowToCitySelectionField}` +
                (props.addressFromInputError
                  ? ` ${stylesFormHome.shadowToCitySelectionFieldError}`
                  : "")
              }
              padding="0"
            >
              <BaseSearchInput
                className={formStyles.searchAddress}
                address={methods.getValues("locationFrom")}
                searchOptions={props.searchOptions}
                label={
                  isDomesticShipping
                    ? rtl
                      ? SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC_RTL
                      : SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC
                    : rtl
                      ? SEARCH_FROM_INPUT_PLACEHOLDER_RTL
                      : SEARCH_FROM_INPUT_PLACEHOLDER
                }
                positionStatic
                withoutBorder
                selectionRequired
                showFullAddress
                error={props.addressFromInputError}
                disabled={props.isAddressFieldsDisabled}
                onSelect={(selectedAddress: any) => {
                  methods.setValue("locationFrom", selectedAddress);
                  props.resetErrorFrom();
                  // process.env.REACT_APP_INFOBIP &&
                  //   addressFromEventInfobip(selectedAddress);
                }}
                // suffix={
                //   <StyledDeleteIcon
                //     isVisible={methods.getValues('locationFrom')}
                //     value={methods.getValues('locationFrom')}
                //     onClick={() => {
                //       methods.setValue('locationFrom', null)
                //     }}
                //   >
                //     <BaseIcon
                //       name={Name.CloseInCircle}
                //       size={16.25}
                //     />
                //   </StyledDeleteIcon>
                // }
              />
            </BaseCard>

            {!isMobileDevice && (
              <StyledSwapCityBtn
                disabled={
                  !(
                    isObject(methods.getValues("locationFrom")) &&
                    isObject(methods.getValues("locationTo"))
                  )
                }
                onClick={() => {
                  const locationFromCache = methods.getValues("locationFrom");
                  methods.setValue(
                    "locationFrom",
                    methods.getValues("locationTo")
                  );
                  methods.setValue("locationTo", locationFromCache);
                }}
                type="button"
              >
                <BaseIcon name={Name.Swap} />
              </StyledSwapCityBtn>
            )}

            <BaseCard
              className={
                formStyles.card +
                ` ${stylesFormHome.shadowToCitySelectionField}` +
                (props.addressToInputError
                  ? ` ${stylesFormHome.shadowToCitySelectionFieldError}`
                  : "")
              }
              padding="0"
            >
              <BaseSearchInput
                className={formStyles.searchAddress}
                address={methods.getValues("locationTo")}
                searchOptions={props.searchOptions}
                label={
                  isDomesticShipping
                    ? rtl
                      ? SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC_RTL
                      : SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC
                    : rtl
                      ? SEARCH_TO_INPUT_PLACEHOLDER_RTL
                      : SEARCH_TO_INPUT_PLACEHOLDER
                }
                positionStatic
                withoutBorder
                showFullAddress
                error={props.addressToInputError}
                selectionRequired
                disabled={props.isAddressFieldsDisabled}
                onSelect={(selectedAddress: any) => {
                  // console.log("==", selectedAddress);
                  methods.setValue("locationTo", selectedAddress);
                  props.resetErrorTo();
                  // process.env.REACT_APP_INFOBIP &&
                  //   addressToEventInfobip(selectedAddress);
                }}
              />
            </BaseCard>
          </StyledCitySelectionFieldsSection>
        </StyledInputGroupWrapper>

        {!!props.count && (
          <div style={{ marginTop: "28px" }}>
            <MetricSystemBtns
              selected={methods.getValues("unit_of_measurement")}
              onClick={(value: boolean) => {
                methods.setValue("unit_of_measurement", value);
                // console.log(methods.getValues('packages'));

                methods
                  .getValues("packages")
                  .forEach((item: any, index: number) => {
                    // console.log("==", `${value}`, item.envelopeSize);
                    // console.log("==", setPackageValues, setPackageValues[`${value}`]);
                    // console.log("==", setPackageValues[`${value}`][item.envelopeSize]);

                    if (
                      item.envelopeSize === EnvelopeSizes.small ||
                      item.envelopeSize === EnvelopeSizes.large
                    ) {
                      const _sizes =
                        setPackageValues[`${value}`][item.envelopeSize];

                      Object.keys(_sizes).forEach((parcelSizeTypeName: any) => {
                        methods.setValue(
                          `packages[${index}].${parcelSizeTypeName}`,
                          _sizes[parcelSizeTypeName]
                        );
                      });
                    }
                  });
              }}
            />
          </div>
        )}

        <StyledParcelSizesWrapper>
          <ArrayComponent
            fields={props.fields}
            parcelType={methods.getValues("parcelType")}
            remove={props.remove}
            getFieldState={methods.getFieldState}
            register={methods.register}
            onChange={({ fieldName, value }: any) => {
              methods.setValue(fieldName, value);
              // disabledSubmitBtn()
            }}
            unitSystem={methods.watch("unit_of_measurement")}
          />
        </StyledParcelSizesWrapper>

        {methods.getValues("packages").length > 4 &&
          methods.getValues("packages").length <
            maximumNumberOfItemsInParcel && (
            <AddNewMobileButton
              parcelType={methods.getValues("parcelType")}
              onClick={props.onChangeCounterAdd}
            />
          )}

        <BaseButton
          className={formStyles.nextBtn}
          type="submit"
          disabled={isDisabledSubmitBtn}
          loading={props.loading}
        >
          קבלו הצעות
        </BaseButton>
      </div>

      <BrandPlates />
    </StyledContainer>
  );
};

export default DesktopForm;
