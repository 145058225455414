import * as React from "react";

export const EyeClosed: React.FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.47 2.47a.75.75 0 0 1 1.06 0l18 18a.75.75 0 1 1-1.06 1.06l-7.13-7.129A2.75 2.75 0 0 1 9.6 10.66L2.469 3.53a.75.75 0 0 1 0-1.06Zm8.292 9.353a1.247 1.247 0 0 0 .353 1.062 1.25 1.25 0 0 0 1.063.353l-1.416-1.415Z"
      fill="#284492"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 6a.75.75 0 0 1 .75-.75c4.42 0 7.328 3.49 9.583 6.278a.75.75 0 0 1 .05.873c-.345.546-.722 1.07-1.129 1.572a.75.75 0 0 1-1.164-.946c.257-.316.501-.644.732-.98C17.828 9.305 15.42 6.75 12 6.75a.75.75 0 0 1-.75-.75ZM7.976 7.13a.75.75 0 0 1-.184 1.045C6.35 9.186 5.11 10.602 3.93 12.047c.871 1.273 2.017 2.516 3.326 3.468C8.72 16.579 10.345 17.25 12 17.25c1.38 0 2.738-.466 4.004-1.24a.75.75 0 0 1 .782 1.28c-1.438.88-3.065 1.46-4.786 1.46-2.063 0-3.992-.834-5.627-2.022-1.636-1.189-3.019-2.763-4.007-4.328a.75.75 0 0 1 .051-.872C3.69 9.956 5.151 8.195 6.932 6.947a.75.75 0 0 1 1.044.184Z"
      fill="#284492"
    />
  </svg>
);
