import { BaseIcon, Name } from "../../../../components/BaseIcon";
import {
  StyledRoot,
  StyledSelectedText,
  StyledDocumentsListItemIcon,
} from "./DownloadCarrier.sluled";
import ArrowDownIcon from "../../../../assets/icons/arrow-down.svg";
import brandColors from "../../../../css/brandColors.scss";

export const DownloadCarrier = (props: any) => {
  const { fileName, url } = props;

  return (
    <StyledRoot
      isUrl={!!url}
      {...props}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <BaseIcon
        name={Name.PDF}
        color={url ? brandColors.mainBlue : brandColors.lightBlueColor}
        size={52}
      />

      <StyledSelectedText>{fileName}</StyledSelectedText>

      {url && (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${brandColors.mainBlue}`,
            fontWeight: "600",
            marginLeft: "auto",
          }}
        >
          Download
          <StyledDocumentsListItemIcon src={ArrowDownIcon} alt="" />
        </span>
      )}
    </StyledRoot>
  );
};
