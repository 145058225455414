import { Checkbox, FormControlLabel } from "@mui/material";

import { styled } from "@mui/system";
// import brandColors from '../../css/brandColors.scss'

const StyledFormControlLabel = styled(FormControlLabel)`
  color: #0000008f;

  span {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 3px 9px;
`;

const BaseCheckbox: React.FC<any> = (props) => {
  return <StyledFormControlLabel control={<StyledCheckbox />} {...props} />;
};

export default BaseCheckbox;
