import React, { useState } from "react";
import Lottie from "lottie-react";
import { SwitchBtnDomestic } from "./SwitchBtnDomestic";
import startHomepage from "../../../../assets/lottie/start-homepage.json";
import subsequentHomepage from "../../../../assets/lottie/subsequent-homepage.json";
import styles from "./formHome.scss";

import cup from "../../../../assets//home-page/cup.png";
import ChipsByHand from "../../../../assets//home-page/chips-by-hand.png";

import "./styles/styles.css";

interface TopHomePageProps {
  onChangeDomesticShipping(): void;
}

export const TopHomePage = ({ onChangeDomesticShipping }: TopHomePageProps) => {
  const [lottieProps, setLottieProps] = useState({
    loop: false,
    animationData: startHomepage,
  });

  return (
    <div className={styles["top-home-page"]}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <h1 className={styles.topTitle}>
            Merhaba! Bize Bakın! - Biz Küreseliz!
          </h1>

          <div className={styles.subTitle}>
            Fiyatları Karşılaştırın ve Hızlıca Global Gönderim Yapın
          </div>

          <div className={styles.topSubTitle}>
            <div className={styles.topItem}>
              <img className={styles.topImg} src={cup} />

              <div className={styles.topItemText}>
                <div>Kargoları, paletleri ve</div>
                <div>belgeleri gönderin</div>
              </div>
            </div>

            <div className={styles.topItem}>
              <img className={styles.topImg} src={ChipsByHand} />

              <div className={styles.topItemText}>
                <div>Tamamen otomatik ve yapay</div>
                <div>zeka destekli platform</div>
              </div>
            </div>
          </div>
        </div>

        <Lottie
          className={styles.topLottie}
          {...lottieProps}
          onComplete={() => {
            setLottieProps({
              loop: true,
              animationData: subsequentHomepage,
            });
          }}
        />
      </div>

      <SwitchBtnDomestic onChangeDomesticShipping={onChangeDomesticShipping} />
    </div>
  );
};
