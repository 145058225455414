import React from "react";

import { CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/system";

import brandColors from "../../../../css/brandColors.scss";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../../../data/selectors";
import { Breakpoints, media } from "../../../../css/breakpoints";

type StyledRootProps = {
  withoutBorder?: boolean;
};

const StyledRoot = styled("div")<StyledRootProps>(
  ({ withoutBorder }) => `
   padding-top: 20px;
   padding-bottom: 20px;
   border-bottom: ${
     withoutBorder ? "none" : `1px solid ${brandColors.lightestBlue}`
   };
   position: relative;
`,
);

const StyledLabel = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${brandColors.lightBlueColor};

  @media ${media(Breakpoints.Mobile)} {
    margin-bottom: 12px;
  }
`;

const StyledRow = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 32px;
  gap: 12px;
`;

const StyledButton = styled("button")`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${brandColors.whiteColor};
  padding: 6px 10px;
  background: ${brandColors.lightBlueColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 65px;

  @media ${media(Breakpoints.Mobile)} {
    position: absolute;
    right: 24px;
    top: 18px;
  }
`;

const StyledSpinner = styled(CircularProgress)<{ isLoading?: boolean }>`
  color: ${({ isLoading }) => (isLoading ? brandColors.mainBlue : "#FFFFFF")};
  width: ${({ isLoading }) =>
    isLoading ? "22px !important" : "12px !important"};
  height: ${({ isLoading }) =>
    isLoading ? "22px !important" : "12px !important"};
`;

type Props = {
  label: string;
  withoutBorder?: boolean;
  spinnerOnLoading?: boolean;
  inputField: React.ReactNode;
  withActionBtn?: boolean;
  onConfirm?(): void;
  isConfirmBtnDisabled?: boolean;
};

const InfoRow: React.FC<Props> = (props) => {
  const loading = useSelector(selectIsLoading);

  return (
    <StyledRoot withoutBorder={props.withoutBorder}>
      <Grid container columnSpacing={{ xs: 2 }}>
        <Grid item xs={12} md={3}>
          <StyledLabel>{props.label}</StyledLabel>
        </Grid>

        <Grid item xs={12} md={9}>
          <StyledRow>
            {props.spinnerOnLoading && loading ? (
              <StyledSpinner isLoading />
            ) : (
              <>
                {props.inputField}
                {props.withActionBtn && (
                  <StyledButton
                    onClick={props.onConfirm}
                    disabled={props.isConfirmBtnDisabled || loading}
                  >
                    {loading ? <StyledSpinner /> : "Confirm"}
                  </StyledButton>
                )}
              </>
            )}
          </StyledRow>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default InfoRow;
