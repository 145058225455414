import { styled } from "@mui/system";
import { Breakpoints, media } from "../../../../../css/breakpoints";
import { BaseIcon, Name } from "../../../../../components/BaseIcon";
import { maximumNumberOfItemsInParcel } from "../Form";

const StyledCounterButton = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eef0f6;
  border-radius: 8px;
  height: 40px;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    height: 35px;
  }
`;

const StyledPlusIcon = styled(BaseIcon)<{ disabled: boolean }>`
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  stroke: #7f90b1;
  fill: #7f90b1;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const StyledIconButton = styled("button")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin: 7px 5px;
  transition: all 0.2s ease-in;

  &:hover {
    background: #fff;
    border-radius: 50%;
  }

  @media ${media(Breakpoints.Mobile)} {
    display: none !important;
  }
`;

const StyledCount = styled("div")`
  font-weight: 600;
  line-height: 25px;
  padding: 0 4px;

  @media ${media(Breakpoints.Mobile)} {
    font-size: 14px;
    padding: 6px 32px;
  }
`;

export const CounterButton = ({ className, value, onSub, onAdd }: any) => {
  return (
    <StyledCounterButton className={className}>
      <StyledIconButton
        type="button"
        onClick={() => onSub(value - 1)}
        disabled={value < 2}
      >
        <StyledPlusIcon name={Name.Minus} disabled={value < 2} />
      </StyledIconButton>

      <StyledCount>{value}</StyledCount>

      <StyledIconButton
        disabled={value >= maximumNumberOfItemsInParcel}
        type="button"
        onClick={onAdd}
      >
        <StyledPlusIcon
          name={Name.Plus}
          disabled={value >= maximumNumberOfItemsInParcel}
        />
      </StyledIconButton>
    </StyledCounterButton>
  );
};
