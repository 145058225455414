import { parcelTypes } from "../../data/constants";

export const submitToGoogleAnalyticsViewItemList = (shipment: any) => {
  // console.log(shipment);

  const perChunk = 10;

  const result = shipment.rates.reduce(
    (resultArray: any, item: any, index: number) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    [],
  );

  // console.log(result);

  result.forEach((element: any, chunkIndex: number) => {
    const outgoingData: any = {
      event: "view_item_list",
      ecommerce: {
        items: element.map((item: any, index: number) => [
          {
            item_category: !shipment.is_customs_declarable
              ? "Letters"
              : shipment.is_pallet
                ? "Pallets"
                : "Parcels",
            item_id: `${item.provider.name}_${item.service_level.name}`
              .toLocaleLowerCase()
              .replace(/ /g, "_"),
            item_name: item.service_level.name,
            index: chunkIndex * perChunk + index,
            item_brand: item.provider.name,
            item_list_name:
              `Shipping from ${shipment.address_from.city_name} to ${shipment.address_to.city_name}` +
              ", " +
              shipment?.parcels?.length +
              " " +
              parcelTypes[
                shipment?.is_customs_declarable
                  ? shipment?.is_pallet
                    ? 2
                    : 1
                  : 0
              ].label +
              (shipment?.parcels?.length > 1 ? "s" : "") +
              ", " +
              (
                shipment?.parcels?.reduce((totalWeigh: number, item: any) => {
                  totalWeigh += item.weight;
                  return totalWeigh;
                }, 0) || 0
              ).toFixed(2) +
              " " +
              (shipment?.unit_of_measurement === "metric" ? "kg" : "lb"),
            item_variant: item.service_level.name,
            price: item.amount,
          },
        ]),
      },
    };
    // console.log(outgoingData);

    dataLayer.push(outgoingData);
  });
};
