import braakpoints from "./breakpoints.scss";

export enum Breakpoints {
  /** 320 - 600 */
  Mobile = "mobile",
  /** 601 - 1248 */
  Tablet = "tablet",
  /** 1249 - 1920 */
  Desktop = "desktop",
  /** 1921 + */
  Desktop2K = "desktop2k",
}

const getNextMinBreakpointValue = (value: string): number => +value + 1;

export const breakpoints = {
  mobile: { min: braakpoints.xs, max: braakpoints.md },
  tablet: {
    min: getNextMinBreakpointValue(braakpoints.md),
    max: braakpoints.lg,
  },
  desktop: {
    min: getNextMinBreakpointValue(braakpoints.lg),
    max: braakpoints.xl,
  },
  desktop2k: { min: getNextMinBreakpointValue(braakpoints.xl), max: "" },
};

export function media(bp: Breakpoints, bpMax?: Breakpoints): string {
  const minWidthValue = `${breakpoints[bp].min}px`;
  const maxWidthInPixels = breakpoints[bpMax || bp].max;
  const maxWidthValue = maxWidthInPixels ? `${maxWidthInPixels}px` : "";

  return `screen and (min-width: ${minWidthValue})${
    maxWidthValue && ` and (max-width: ${maxWidthValue})`
  }`;
}
