import * as React from "react";

export const WhiteRhombusInRedCircle: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="#FF5C5C" />
    <path fill="#fff" d="M2.929 10 10 2.929l7.071 7.07L10 17.072z" />
  </svg>
);
