import { put, call, takeLatest } from "redux-saga/effects";

import {
  hideLoader,
  setFormErrors,
  setUser,
  showLoader,
} from "../../../../data/actions";
import { getErrorMessage } from "../../../../services/getErrorMessage";
import { Notify } from "../../../../services/notifications";
import ProfileRequests from "../../api";
import * as actions from "../actions";

function* updateProfile(action: any): any {
  try {
    yield put(showLoader());

    const response: any = yield call(
      ProfileRequests.updateProfile,
      action.payload,
    );

    const responseError = response?.error;

    if (responseError) {
      yield put(setFormErrors(responseError));

      throw responseError;
    }

    yield put(actions.updateUser(response));

    Notify({ message: "Profile updated!", type: "success" });
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* verifyProfile(action: any): any {
  try {
    yield put(showLoader());

    const response = yield call(ProfileRequests.verifyProfile, action.payload);

    const responseError = response?.error;

    if (responseError) {
      if (responseError?.details) {
        yield put(setFormErrors(responseError));
      }

      throw responseError;
    }

    Notify({
      message: "We successfully sent you confirmation code!",
      type: "success",
    });
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* confirmVerifyProfile(action: any): any {
  try {
    yield put(actions.showLoader());

    const response: any = yield call(
      ProfileRequests.confirmVerifyProfile,
      action.payload.confirmation_code,
    );

    const responseError = response?.error;

    if (responseError) {
      yield put(setFormErrors(responseError));

      throw responseError;
    }

    const updatedField =
      action.payload.type === "email"
        ? "is_email_verified"
        : "is_phone_verified";

    yield put(actions.updateUser({ [updatedField]: response.is_verified }));

    Notify({ message: "Success verification!", type: "success" });
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(actions.hideLoader());
  }
}

function* updatePassword(action: any): any {
  try {
    yield put(showLoader());

    const response: any = yield call(
      ProfileRequests.updatePassword,
      action.payload,
    );

    const responseError = response?.error;

    if (responseError) {
      yield put(setFormErrors(responseError));

      throw responseError;
    }

    Notify({ message: "Your password successfully changed!", type: "success" });
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

export function* profileSaga() {
  yield takeLatest(actions.updateProfile, updateProfile);
  yield takeLatest(actions.verifyProfile, verifyProfile);
  yield takeLatest(actions.confirmVerifyProfile, confirmVerifyProfile);
  yield takeLatest(actions.updatePassword, updatePassword);
}
