import { styled } from "@mui/system";

export const StyledTitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

export const StyledTitle = styled("div")`
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #284492;
`;

export const StyledTitleIcon = styled("img")`
  margin-right: 12px;
  width: 32px;
`;

export const StyledContentWrapper = styled("form")`
  margin-left: 40px;
`;
