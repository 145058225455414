import { styled } from "@mui/system";
import brandColors from "../../../../css/brandColors.scss";

export const StyledRoot = styled("a")(
  ({ isUrl }: any) => `
  border: 1px dashed;
  border-color: ${isUrl ? "transparent" : "#A8B6D2"};
  border-radius: 8px;
  width: 100%;
  background: ${isUrl ? "#F4F8FF" : "none"};
  color: ${isUrl ? brandColors.mainBlue : brandColors.lightBlueColor};
  cursor: ${isUrl ? "pointer" : "default"};
  display: flex;
  mxs-height: 71px;
  align-items: center;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none
`,
);

export const StyledSelectedText = styled("div")(
  () => `
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`,
);

export const StyledDocumentsListItemIcon = styled("img")`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;
