import React from "react";

import { styled } from "@mui/system";

import OopsImage from "../../../../../../../../assets/images/oops.svg";

import styles from "./ErrorModalContent.scss";
import { BaseButton } from "../../../../../../../../components/BaseButton";

const StyledRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledErrorImage = styled("img")`
  margin-bottom: 16px;
  min-height: 80px;
`;

const StyledTitle = styled("div")`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #284492;
  margin-bottom: 5px;
`;

const StyledDescription = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #284492;
`;

const ErrorModalContent: React.FC<any> = (props) => {
  return (
    <StyledRoot>
      <StyledErrorImage src={OopsImage} alt="" />

      <StyledTitle>Oops</StyledTitle>

      <StyledDescription>
        {props.children ?? (
          <>
            Something went wrong, try again or contact&ensp;
            <a
              href={process.env.REACT_APP_LINK_SHIPPER_DESK_SUPPORT}
              target="_blank"
              rel="noreferrer"
            >
              support
            </a>
          </>
        )}
      </StyledDescription>

      <BaseButton className={styles.submitBtn} onClick={props.onClose}>
        Continue
      </BaseButton>
    </StyledRoot>
  );
};

export default ErrorModalContent;
