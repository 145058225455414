export const handleAllowedGetBestPricePopup = async () => {
  try {
    const pathBase =
      process.env.REACT_APP_IS_LOCAL_DEVELOPMENT === "true"
        ? process.env.PUBLIC_URL
        : process.env.REACT_APP_EMAIL_SENDER_ENDPOINT;
    // console.log("=fetch pathBase=", pathBase);

    const res = await fetch(pathBase + "/get-best-price-popup.json", {
      // ! Обязательно на стороне сервера убрать CORS. Это сделано в файле .htaccess
      cache: "no-cache",
    });
    // console.log("=fetch res=", res);

    const data = await res.json();

    // console.log('=fetch data JSON=', data, data?.allowed)

    if (data) {
      return data?.allowed === "true";
    } else {
      return false;
    }
  } catch (error) {
    console.log("=Request failed=", error);
  }
};
