import { put, call, takeLatest } from "redux-saga/effects";
import { hideLoader, showLoader } from "../../../../data/actions";
import { checkFormErrors } from "../../../../services/formErrors/checkFormErrors";
import { getErrorMessage } from "../../../../services/getErrorMessage";
import { Notify } from "../../../../services/notifications";
import CheckoutRequests from "../../api";
import * as actions from "../actions";
import { ShipmentFormNames } from "../constants";

// function* convertFormNameFields({ newFormName, errorDetails }: any) {
//   for (let oldKey in errorDetails) {
//     const fieldName = oldKey.split('.')[1]
//     errorDetails[`${newFormName}.${fieldName}`] = errorDetails[oldKey]
//     delete errorDetails[oldKey]
//   }

//   console.log('errorDetails', errorDetails)
// }

function* saveAddressForm(action: any): any {
  yield put(showLoader());

  try {
    const addressForm = action.payload.addressForm;

    if (addressForm === ShipmentFormNames.BillingAddress) {
      addressForm.address.phone = addressForm.address.phone?.includes("+")
        ? addressForm.address.phone
        : "+" + addressForm.address.phone;
      delete addressForm.address.countrySelect;

      addressForm.address.has_skipped_validation =
        action.payload.skipValidation;
    }

    const response: any = yield call(CheckoutRequests.saveAddressForm, {
      addressForm,
      shipmentId: action.payload.shipmentId,
      skipValidation: action.payload.skipValidation,
    });

    if (response.data?.error) {
      const newFormName = action.payload.addressForm.address_type;

      for (let oldKey in response.data.error.details) {
        const fieldName = oldKey.split(".")[1];
        response.data.error.details[`${newFormName}.${fieldName}`] =
          response.data.error.details[oldKey];
        delete response.data.error.details[oldKey];
      }

      yield checkFormErrors({
        response,
        reject: action.payload.reject,
        setFormErrorAction: action.payload.setFormErrorAction,
      });

      if (addressForm.address_type === ShipmentFormNames.BillingAddress) {
        yield put(actions.setIsShowPaymentBtn(false));
      }
    } else {
      if (addressForm.address_type === ShipmentFormNames.BillingAddress) {
        yield put(actions.setIsShowPaymentBtn(true));
      }
      action.payload.resolve();
    }
  } catch (error: any) {
    // Notify({ message: getErrorMessage(error), type: 'error' })
  } finally {
    yield put(hideLoader());
  }
}

function* makeTransaction(action: any): any {
  try {
    yield put(showLoader());

    const response: any = yield call(
      CheckoutRequests.makeTransaction,
      action.payload,
    );

    // console.log("=makeTransaction=", response);

    if (response.data?.error?.details) {
      yield checkFormErrors({
        response,
        reject: action.payload.reject,
        setFormErrorAction: action.payload.setFormErrorAction,
      });
    } else {
      action.payload.resolve(response);
    }
  } catch (error: any) {
    // console.log("=makeTransaction error=", error);
    // Notify({ message: getErrorMessage(error), type: 'error' })
  } finally {
    yield put(hideLoader());
  }
}

function* sendServices(action: any): any {
  try {
    const response: any = yield call(
      CheckoutRequests.sendServices,
      action.payload,
    );

    yield checkFormErrors({
      response,
      withoutFormErrors: true,
    });

    yield put(
      actions.updateShipment({
        additional_payments: response.data.data,
      }),
    );
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    action.payload.resolve();
  }
}

function* savePickupDetailsForm(action: any): any {
  try {
    yield put(showLoader());

    const response: any = yield call(
      CheckoutRequests.savePickupDetailsForm,
      action.payload,
    );

    if (response.data?.error?.details) {
      yield checkFormErrors({
        response,
        reject: action.payload.reject,
        withoutFormErrors: true,
      });
    } else {
      action.payload.resolve();
    }
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

export function* checkoutSaga() {
  yield takeLatest(actions.setServices, sendServices);
  yield takeLatest(actions.saveAddressForm, saveAddressForm);
  yield takeLatest(actions.savePickupDetailsForm, savePickupDetailsForm);
  yield takeLatest(actions.makeTransaction, makeTransaction);
}
