import { Navigate, useLocation } from "react-router-dom";

import { getStore } from "../../integrationWithVue/handleBrowserStore";

type Props = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();

  if (getStore("token")) {
    return children;
  }

  return (
    <Navigate to={`/auth?type=sign-in`} state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
