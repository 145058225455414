import { useEffect, useState, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { selectRtl } from "../../data/selectors";

import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import { BaseInput } from "../BaseInput";
import { SearchResultsMenu } from "./SearchResultsMenu";
import { BaseCard } from "../BaseCard";

import styles from "./BaseSearchInput.scss";
import { ErrorMessage } from "./ErrorMessage";
import {
  StyledRoot,
  StyledRootSelectionRequired,
  StyledBaseInput,
} from "./BaseSearchInput.styled";
import "../../css/animations.css";
import { defaultCountryNameHongKong } from "../../constants";
import { normalizeUnicodeString } from "./normalizeUnicodeString";

const addNameToString = (string: string, name: any): string => {
  string.length && (string += ", ");
  string += name;

  return string;
};

const generateAddressString = (
  payload: any,
  _isAddressSearchType: boolean,
  _showFullAddress: boolean
) => {
  // console.log("=generateAddressString=", payload);

  if (!payload) {
    return "";
  }

  let _address = "";

  if (_isAddressSearchType) {
    if (!payload.street_1) return "";

    _address = addNameToString(_address, payload.street_1);

    if (_showFullAddress) {
      payload.city_name &&
        (_address = addNameToString(_address, payload.city_name));
    }
  } else {
    if (!payload.city_name) {
      if (payload.fullAddress) {
        return payload.fullAddress;
      }

      return "";
    }
    _address = addNameToString(_address, payload.city_name);
  }

  if (_showFullAddress) {
    payload.state && (_address = addNameToString(_address, payload.state));
    payload.country && (_address = addNameToString(_address, payload.country));
  }

  // console.log("=generateAddressString RETURN=", _address);
  return _address;
};

export const BaseSearchInput = ({
  prefixToSearchString,
  searchOptions,
  withoutBorder,
  placeholder,
  rightIcon,
  positionStatic,
  className,
  autocomplete,
  selectionRequired,
  error,
  handleError,
  suffix,
  dropdownUp,
  disabled,
  showFullAddress,
  address,
  minlength,
  maxlength,
  pattern,
  title,
  showAddressAsIs,
  ...otherProps
}: any) => {
  const rtl = useSelector(selectRtl);
  const [innerAddress, setInnerAddress] = useState("");
  const [selectedHelper, setSelectedHelper] = useState(false); // ! без этого параметра при старте страницы попап с уже заполненным адресом показывается сам по себе, то есть без фокуса на инпут
  const baseInputRef = useRef<any>();
  const [showSearchResultBlock, setShowSearchResultBlock] = useState(false);

  const defaultSearchOptions = {
    types: ["address"],
  };
  const innerSearchOptions = searchOptions || defaultSearchOptions;
  const isAddressSearchType = innerSearchOptions.types?.includes("address");

  useEffect(() => {
    // console.log("=useEffect=", showAddressAsIs, otherProps.value);

    setInnerAddress(
      showAddressAsIs
        ? otherProps.value
        : generateAddressString(address, isAddressSearchType, showFullAddress)
    );
  }, [
    isAddressSearchType,
    showFullAddress,
    address,
    otherProps.value,
    showAddressAsIs,
  ]);

  useEffect(() => {
    // console.log("=useEffect=", innerAddress, innerAddress?.length >= 3 && selectedHelper);

    if (innerAddress?.length >= 3 && selectedHelper) {
      setShowSearchResultBlock(true);
    }
  }, [innerAddress]);

  const handleSelect = async (value: string) => {
    // console.log("=handleSelect=", error);
    baseInputRef.current.handleBlur();

    setShowSearchResultBlock(false);

    try {
      const result: any = await geocodeByAddress(value);
      // console.log("=result=", result, value);

      let splitValue = value.split(/,/);
      if (splitValue.length === 1) {
        splitValue = value.split(/\s-\s/);
      }
      // console.log(
      //   "=result=",
      //   result[0].geometry,
      //   result[0].geometry.location.lat(),
      //   result[0].geometry.location.lng()
      // );
      const transformResult: any = {
        postal_code: "",
        fullAddress: result[0].formatted_address,
        lat: String(result[0].geometry.location.lat()),
        lng: String(result[0].geometry.location.lng()),
      };

      if (isAddressSearchType) {
        transformResult.street_1 = splitValue[0].trimEnd();
        transformResult.city_name = splitValue?.[1]?.trimStart();
      } else {
        transformResult.city_name = splitValue?.[1]?.trimStart();
      }

      result[0].address_components.forEach((el: any) => {
        // if (isAddressSearchType) {
        // if (el.types.includes('route')) {
        //   const addressParts = transformResult.description.split(',')

        //   const findAddress = addressParts.find((addressPart: string): boolean =>
        //     addressPart.includes(el.long_name)
        //   )

        //   if (findAddress) {
        //     transformResult.address = findAddress
        //   } else {
        //     transformResult.address = el.long_name
        //   }
        // }
        // } else {
        // if (el.types.includes('postal_code')) {
        //   transformResult.postal_code = el.short_name
        // } else
        if (el.types.includes("country")) {
          transformResult.country_code = el.short_name;
          transformResult.country = el.long_name;
        } else if (el.types.includes("locality")) {
          transformResult.city_name = el.long_name;
        } else if (el.types.includes('"postal_town"')) {
          transformResult.city_name = el.long_name;
        } else if (el.types.includes("administrative_area_level_1")) {
          transformResult.state = el.short_name;
        }
        // }

        if (el.types.includes("postal_code")) {
          transformResult.postal_code = el.short_name;
        }
      });

      // if (isAddressSearchType && !transformResult?.address) {
      //   transformResult.address = result[0].formatted_address.split(',')[0]
      // }

      if (
        !(
          transformResult?.country_code === "US" ||
          transformResult?.country_code === "AU" ||
          transformResult?.country_code === "CA"
        )
      ) {
        delete transformResult.state;
      }

      // console.log("=transformResult?.country_code=", transformResult?.country_code);

      if (
        transformResult?.country_code === "HK" &&
        !transformResult?.city_name
      ) {
        transformResult.city_name = "Hong Kong";
        transformResult.country = defaultCountryNameHongKong;
      }

      // const transformResultNormalize = Object.keys(transformResult)
      //   .reduce((acc: any, fieldKeyName) => {
      //     acc[fieldKeyName] = normalizeUnicodeString(transformResult[fieldKeyName])

      //     return acc
      //   }, {})

      // console.log('==transformResultNormalize==', transformResult)
      // otherProps.onSelect(transformResultNormalize)

      // console.log('==transformResult==', transformResult)
      otherProps.onSelect(transformResult);
      setInnerAddress(
        showAddressAsIs
          ? otherProps.value
          : generateAddressString(address, isAddressSearchType, showFullAddress)
      );
    } catch (err) {
      console.error(err);

      otherProps.onSelect({ fullAddress: value });
    }
  };

  const handleChange = (value: string) => {
    // console.log("=handleChange=", value);
    setInnerAddress(value);
  };

  const handleBlur = (e: any) => {
    // console.log("=handleBlur=", address);
    setShowSearchResultBlock(false);
    setSelectedHelper(false); // ! Нужно, иначе при смене props.address, к примеру смене с международной на внутреннюю доставку, отображается произвольно дробдаун

    otherProps.onBlur && otherProps.onBlur(e.target.value);
  };

  const handleFocus = (e: any, value: any): void => {
    // console.log("=handleFocus=", innerAddress, value, address, otherProps.value);
    setSelectedHelper(true);

    if (address && !value?.length && innerAddress) {
      setShowSearchResultBlock(false);
    } else {
      setShowSearchResultBlock(true);
    }
  };

  return (
    <>
      {selectionRequired ? (
        <StyledRootSelectionRequired>
          <BaseCard
            className={cn(styles.card, otherProps.className)}
            padding={"3px 18px"}
            style={{
              borderBottom: error ? `2px solid #ff5c5c` : "",
              height: "100%",
            }}
          >
            <StyledRoot className={className} positionStatic={positionStatic}>
              <PlacesAutocomplete
                value={innerAddress}
                onChange={handleChange}
                onSelect={handleSelect}
                debounce={300}
                searchOptions={innerSearchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }: any) => (
                  <>
                    <StyledBaseInput
                      {...getInputProps({
                        placeholder: placeholder || "Search...",
                      })}
                      withoutBorder={withoutBorder}
                      rightIcon={rightIcon}
                      ref={baseInputRef}
                      onFocus={(e: any) => handleFocus(e, suggestions)}
                      value={innerAddress}
                      label={otherProps.label}
                      variant={otherProps.variant}
                      // onFocus={handleFocus}
                      onBlur={handleBlur}
                      suffix={suffix}
                      withoutErrors
                      autocomplete={autocomplete}
                      disabled={disabled}
                      minlength={minlength}
                      maxlength={maxlength}
                      pattern={pattern}
                      title={title}
                      rtl={rtl}
                    />

                    {(showSearchResultBlock ||
                      (loading && innerAddress?.length >= 3)) && (
                      <SearchResultsMenu
                        getSuggestionItemProps={getSuggestionItemProps}
                        suggestions={suggestions}
                        loading={loading}
                        dropdownUp={dropdownUp}
                        searchString={innerAddress}
                      />
                    )}
                  </>
                )}
              </PlacesAutocomplete>
            </StyledRoot>
          </BaseCard>

          {error && (
            <CSSTransition in={!!error} timeout={500} classNames="fade-up">
              <ErrorMessage
                error={error}
                isAddressSearchType={isAddressSearchType}
              />
            </CSSTransition>
          )}
        </StyledRootSelectionRequired>
      ) : (
        <StyledRoot className={className} positionStatic={positionStatic}>
          <PlacesAutocomplete
            value={
              innerAddress +
              (prefixToSearchString ? `, ${prefixToSearchString}` : "")
            }
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={innerSearchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }: any) => (
              <>
                <BaseInput
                  {...getInputProps({
                    placeholder: placeholder || "Search...",
                  })}
                  withoutBorder={withoutBorder}
                  rightIcon={rightIcon}
                  ref={baseInputRef}
                  onFocus={(e: any) => handleFocus(e, suggestions)}
                  value={innerAddress}
                  label={otherProps.label}
                  required={otherProps.required}
                  // onFocus={handleFocus}
                  onBlur={handleBlur}
                  suffix={suffix}
                  withoutErrors
                  autocomplete={autocomplete}
                  disabled={disabled}
                  minlength={minlength}
                  maxlength={maxlength}
                  pattern={pattern}
                  title={title}
                />

                {(showSearchResultBlock ||
                  (loading && innerAddress?.length >= 3)) && (
                  <SearchResultsMenu
                    getSuggestionItemProps={getSuggestionItemProps}
                    suggestions={suggestions}
                    loading={loading}
                    dropdownUp={dropdownUp}
                    searchString={innerAddress}
                  />
                )}
              </>
            )}
          </PlacesAutocomplete>

          {error && (
            <CSSTransition in={!!error} timeout={500} classNames="fade-up">
              <ErrorMessage
                error={error}
                isAddressSearchType={isAddressSearchType}
              />
            </CSSTransition>
          )}
        </StyledRoot>
      )}
    </>
  );
};
