import * as React from "react";

export const Document: React.FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.325 5A.371.371 0 0 0 6 5.359v13.538c0 .188.17.359.36.359h10.297c.188 0 .36-.17.36-.359v-10.5c0-.123-.07-.213-.163-.308l-2.97-2.938c-.119-.104-.214-.148-.347-.151H6.326Zm.393.718h6.504v2.68c0 .188.171.358.36.358h2.716v9.783h-9.58V5.718Zm7.223.498 1.84 1.822h-1.84V6.216Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.338 7.942a.36.36 0 1 0 0 .716h3.614a.36.36 0 0 0 .367-.358.36.36 0 0 0-.367-.358H8.338ZM8.35 9.905a.359.359 0 1 0 0 .718h6.313a.36.36 0 1 0 0-.718H8.35ZM8.35 11.87a.36.36 0 1 0 0 .717h6.313a.36.36 0 1 0 0-.718H8.35ZM8.35 15.804a.36.36 0 1 0 0 .717h6.313a.36.36 0 1 0 0-.717H8.35ZM8.35 13.833a.36.36 0 1 0 0 .718h6.313a.36.36 0 1 0 0-.718H8.35Z"
      fill="currentColor"
    />
  </svg>
);
