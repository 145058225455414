import OopsImage from "../../../../../assets/images/oops.svg";

import { BaseButton } from "../../../../../components/BaseButton";
import {
  StyledRoot,
  StyledErrorImage,
  StyledTitle,
  StyledDescription,
  StyledControl,
  StyledBtnNotSave,
} from "./PackageDetailsClosingModal.styled";

interface PackageDetailsClosingModalProps {
  onClose(): void;
  onReset(): void;
}

export const PackageDetailsClosingModal = ({
  onClose,
  onReset,
}: PackageDetailsClosingModalProps) => {
  return (
    <StyledRoot>
      <StyledErrorImage src={OopsImage} alt="" />

      <StyledTitle>Customs details form data not saved</StyledTitle>

      <StyledDescription>
        What do you want to do with this data?
      </StyledDescription>

      <StyledControl>
        <BaseButton onClick={onClose}>
          Continue
          {/* entering data */}
        </BaseButton>

        <StyledBtnNotSave type="button" onClick={onReset}>
          Do not save
        </StyledBtnNotSave>
      </StyledControl>
    </StyledRoot>
  );
};
