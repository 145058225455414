import { styled } from "@mui/system";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import styles from "./Accordion.scss";
import { BaseCard } from "../../../../components/BaseCard";
import { BaseIcon, Name } from "../../../../components/BaseIcon";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, expanded }) => ({
  background: "none !important",
  paddingBottom: expanded ? "31px" : "0",

  "& > .Mui-disabled": {
    opacity: "1 !important",
  },
}));

export const AccordionSummary = styled((props: any) => (
  <MuiAccordionSummary
    expandIcon={
      !props.disabled &&
      !props.expanded && (
        <BaseIcon className={styles.pencilIcon} name={Name.Pencil} isButton />
      )
    }
    {...props}
  />
))(({ theme, expanded }) => ({
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  padding: 0,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "10px",
    overflow: "hidden",
    fontWeight: expanded ? "700" : "600",
    lineHeight: "130%",
    color: expanded ? "#000000" : "#000000B2",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

type StyledAccordionPreviewTextProps = {
  expanded?: boolean;
};

export const StyledAccordionPreviewText = styled(
  "span",
)<StyledAccordionPreviewTextProps>(({ expanded }) => {
  // console.log("=StyledAccordionPreviewText=", expanded);

  return `
      color: ${expanded ? "#000000" : "#000000B2"};
      font-weight: ${expanded ? "700" : "600"};
      margin-left: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
});

export const AccordionSummaryText = styled("span")`
  display: flex;
  align-items: center;
  margin-right: auto;
  overflow: hidden;
`;

export const AccordionCard = styled(BaseCard)`
  padding: 9px 40px !important;

  @media (max-width: 545px) {
    padding: 9px 20px !important;
  }

  @media (max-width: 400px) {
    padding: 9px 10px !important;
  }
`;
