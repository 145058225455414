import { Grid } from "@mui/material";
import styled from "styled-components";

import ManWithPhone from "../../../../assets/images/ManWithPhone.svg";
import EarthBackgroundImage from "../../../../assets/images/EarthBackground.svg";
import BrandArrow from "../../../../assets/icons/brand-arrow-blue.svg";

const StyledCardWrapper = styled("div")(
  () => `
  background: url(${EarthBackgroundImage}) no-repeat center center/cover;
  border-radius: 50px;
  overflow: hidden;
  `,
);

const StyledCard = styled("div")(
  () => `
  padding: 60px;
`,
);

const StyledTitleWrapper = styled("div")(
  () => `
  display: flex;
  align-items: center;
`,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-transform: uppercase;
  color: #284492;
`,
);

const StyledTitleIcon = styled("img")(
  () => `
  width: 24px;
  margin-right: 10px;
`,
);

const StyledText = styled("div")(
  () => `
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #284492;
  margin-top: 12px;
  z-index: 22;
`,
);

const StyledImage = styled("img")(
  () => `
  margin-left: auto;
  display: block;
  z-index: 2;
`,
);

const OurContacts = () => {
  return (
    <StyledCardWrapper>
      <StyledCard>
        <div style={{ zIndex: 2, position: "relative" }}>
          <Grid container columnSpacing={{ xs: 2 }}>
            <Grid marginBottom="16px" item xs={12} md={7}>
              {/* <BaseIcon name={Name.DropdownArrow} /> */}
              <StyledTitleWrapper>
                <StyledTitleIcon src={BrandArrow} alt="" />
                <StyledTitle>Our contact</StyledTitle>
              </StyledTitleWrapper>

              <Grid container columnSpacing={{ xs: 2 }}>
                <Grid marginBottom="10px" item xs={12} md={6}>
                  <StyledText>Shipper Global Inc</StyledText>
                  <StyledText>14361 Commerce Way, Suite 205</StyledText>
                  <StyledText>Miami Lakes, FL 33016</StyledText>
                </Grid>

                <Grid item xs={12} md={6}>
                  <StyledText>Shipper Global HQ</StyledText>
                  <StyledText>HaShikma St 2, Azor</StyledText>
                  <StyledText>Israel, 5804542</StyledText>
                </Grid>
              </Grid>

              <Grid marginTop="10px" container columnSpacing={{ xs: 2 }}>
                <Grid marginTop="20px" item xs={12} md={6}>
                  <StyledTitleWrapper>
                    <StyledTitleIcon src={BrandArrow} alt="" />
                    <StyledTitle>Phone</StyledTitle>
                  </StyledTitleWrapper>

                  <StyledText>Tel: +972-79-6079475</StyledText>
                </Grid>
                <Grid marginTop="20px" item xs={12} md={6}>
                  <StyledTitleWrapper>
                    <StyledTitleIcon src={BrandArrow} alt="" />
                    <StyledTitle>WhatsApp</StyledTitle>
                  </StyledTitleWrapper>

                  <StyledText>Tel: +972-50-2722213</StyledText>
                </Grid>
                <Grid marginTop="20px" item xs={12} md={6}>
                  <StyledTitleWrapper>
                    <StyledTitleIcon src={BrandArrow} alt="" />
                    <StyledTitle>Email</StyledTitle>
                  </StyledTitleWrapper>

                  <StyledText>support@shipper-global.com</StyledText>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={5}>
              <StyledImage src={ManWithPhone} alt="" />
            </Grid>
          </Grid>
        </div>
      </StyledCard>
    </StyledCardWrapper>
  );
};

export default OurContacts;
